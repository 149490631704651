import { IconXLSXsmall, IconXMLsmall } from "../../../Images/iconosEsencial";

export function SwitchIcon({ type, estado }) {
  if (estado) {
    switch (true) {
      case /image/.test(type):
        return <IconXLSXsmall />;
      case type === "text/xml":
        return <IconXMLsmall />;
      default:
        return <IconXLSXsmall />;
    }
  } else {
    return <IconXLSXsmall />;
  }
}
