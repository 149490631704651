import React, { useEffect } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { styled } from "@mui/material";
import "./appbar.css";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Link, useLocation } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#4D5D68",
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4D5D68",

    fontSize: "14px",

    fontFamily: "Interstate",

    padding: "8px 12px 8px 12px",
  },
}));

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? 240 : 64,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": {
      paddingTop: "16px",
      width: 240,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      width: 64,
      paddingTop: "16px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
  }),
}));

export const DrawerLeft = ({ open, handleDrawerClose, handleDrawerOpen }) => {
  const theme = useTheme();
  let location = useLocation();

  useEffect(() => {
    // console.log(window.location.pathname);
  }, []);

  return (
    <Box sx={{ width: 320 }} aria-label="mailbox folders">
      <CustomDrawer
        variant="permanent"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: open ? 320 : 65,
            background: "#FFFFFF",
            color: "",
            fontFamily: "Interstate",
            fontSize: "16px",
            borderRight: "none",
            top: "65px",
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box display="flex" justifyContent={open ? "flex-end" : "center"}>
          <div className="titleContenedor">
            {open ? (
              <Typography className="drawerTitle">Notificaciones</Typography>
            ) : null}
          </div>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ArrowBackRoundedIcon />
              ) : (
                <ArrowForwardRoundedIcon />
              )}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <ArrowForwardRoundedIcon />
            </IconButton>
          )}
        </Box>

        <List className="listDrawer">
          {/* <BootstrapTooltip title="Inicio" arrow placement="right">
            <Link className="linkMenu" to="/Inicio">
              <ListItem className="cerrarsesion" button>
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill=""
                  >
                    <path
                      d="M19.8832 7.70202L17.5 5.75529V2.73149C17.5 2.66932 17.4753 2.6097 17.4314 2.56574C17.3874 2.52178 17.3278 2.49708 17.2656 2.49708H16.4843C16.4221 2.49708 16.3625 2.52178 16.3185 2.56574C16.2746 2.6097 16.2499 2.66932 16.2499 2.73149V4.73681L10.7879 0.278449C10.5645 0.0982654 10.2861 0 9.99912 0C9.71211 0 9.43376 0.0982654 9.21035 0.278449L0.115096 7.70202C0.0509452 7.7543 0.0101478 7.82989 0.00165221 7.91221C-0.00684336 7.99453 0.0176565 8.07685 0.0697781 8.14113L0.464358 8.62596C0.516431 8.68983 0.591622 8.73055 0.673567 8.73925C0.755512 8.74796 0.83758 8.72395 0.901912 8.67245L2.4982 7.36838V16.8617C2.4982 17.0275 2.56406 17.1865 2.68128 17.3037C2.79851 17.421 2.9575 17.4868 3.12328 17.4868H8.12389C8.28967 17.4868 8.44866 17.421 8.56589 17.3037C8.68311 17.1865 8.74897 17.0275 8.74897 16.8617V11.8611L11.2493 11.8728V16.8766C11.2493 17.0424 11.3151 17.2014 11.4324 17.3186C11.5496 17.4358 11.7086 17.5017 11.8744 17.5017L16.875 17.4888C17.0408 17.4888 17.1997 17.4229 17.317 17.3057C17.4342 17.1885 17.5 17.0295 17.5 16.8637V7.36838L19.0963 8.67245C19.1606 8.72469 19.2431 8.74927 19.3255 8.74077C19.4079 8.73227 19.4836 8.6914 19.5358 8.62713L19.9304 8.14231C19.9563 8.11032 19.9756 8.07355 19.9872 8.03409C19.9989 7.99462 20.0026 7.95325 19.9982 7.91235C19.9938 7.87144 19.9814 7.8318 19.9617 7.79571C19.9419 7.75962 19.9152 7.72778 19.8832 7.70202ZM16.2456 16.2417H16.2495L12.499 16.2535V11.2462C12.4985 11.0809 12.4328 10.9225 12.3161 10.8055C12.1994 10.6884 12.0412 10.6222 11.8759 10.6211L8.12546 10.6106C8.04321 10.6103 7.96171 10.6263 7.88565 10.6576C7.80959 10.6889 7.74045 10.7349 7.6822 10.793C7.62395 10.851 7.57773 10.92 7.54619 10.996C7.51466 11.072 7.49843 11.1534 7.49843 11.2357V16.2417H3.74836V6.34834L9.99912 1.24732L16.2499 6.34834L16.2456 16.2417Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Inicio" />
              </ListItem>
            </Link>
          </BootstrapTooltip> */}
          <BootstrapTooltip
            title="Empresas y Convenios"
            arrow
            placement="right"
          >
            <Link className="linkMenu" to="/empresasConvenios">
              <ListItem
                className="cerrarsesion"
                selected={
                  location.pathname == "/empresasConvenios" ? true : false
                }
                button
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18 19"
                    fill=""
                  >
                    <path
                      d="M17.75 8C17.75 7.58579 17.4142 7.25 17 7.25C16.5858 7.25 16.25 7.58579 16.25 8H17.75ZM1.75 8C1.75 7.58579 1.41421 7.25 1 7.25C0.585786 7.25 0.25 7.58579 0.25 8H1.75ZM11.25 4C11.25 4.41421 11.5858 4.75 12 4.75C12.4142 4.75 12.75 4.41421 12.75 4H11.25ZM5.25 4C5.25 4.41421 5.58579 4.75 6 4.75C6.41421 4.75 6.75 4.41421 6.75 4H5.25ZM9.75 10C9.75 9.58579 9.41421 9.25 9 9.25C8.58579 9.25 8.25 9.58579 8.25 10H9.75ZM8.25 14C8.25 14.4142 8.58579 14.75 9 14.75C9.41421 14.75 9.75 14.4142 9.75 14H8.25ZM3 4.75H15V3.25H3V4.75ZM16.25 6V10H17.75V6H16.25ZM15 11.25H3V12.75H15V11.25ZM1.75 10V6H0.25V10H1.75ZM3 11.25C2.30964 11.25 1.75 10.6904 1.75 10H0.25C0.25 11.5188 1.48122 12.75 3 12.75V11.25ZM16.25 10C16.25 10.6904 15.6904 11.25 15 11.25V12.75C16.5188 12.75 17.75 11.5188 17.75 10H16.25ZM15 4.75C15.6904 4.75 16.25 5.30964 16.25 6H17.75C17.75 4.48122 16.5188 3.25 15 3.25V4.75ZM3 3.25C1.48122 3.25 0.25 4.48122 0.25 6H1.75C1.75 5.30964 2.30964 4.75 3 4.75V3.25ZM16.25 8V16H17.75V8H16.25ZM15 17.25H3V18.75H15V17.25ZM1.75 16V8H0.25V16H1.75ZM3 17.25C2.30964 17.25 1.75 16.6904 1.75 16H0.25C0.25 17.5188 1.48122 18.75 3 18.75V17.25ZM16.25 16C16.25 16.6904 15.6904 17.25 15 17.25V18.75C16.5188 18.75 17.75 17.5188 17.75 16H16.25ZM12.75 4V3H11.25V4H12.75ZM10 0.25H8V1.75H10V0.25ZM5.25 3V4H6.75V3H5.25ZM8 0.25C6.48122 0.25 5.25 1.48122 5.25 3H6.75C6.75 2.30964 7.30964 1.75 8 1.75V0.25ZM12.75 3C12.75 1.48122 11.5188 0.25 10 0.25V1.75C10.6904 1.75 11.25 2.30964 11.25 3H12.75ZM8.25 10V14H9.75V10H8.25Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Empresas y Convenios" />
              </ListItem>
            </Link>
          </BootstrapTooltip>
          <BootstrapTooltip title="Gestión de funes" arrow placement="right">
            <Link className="linkMenu" to="/">
              <ListItem
                className="cerrarsesion"
                selected={location.pathname == "/" ? true : false}
                button
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill=""
                  >
                    <path
                      d="M15.4144 1.75812L14.2422 0.585938C13.8516 0.195312 13.3397 0 12.8278 0C12.316 0 11.8041 0.195312 11.4138 0.585625L9.08597 2.91344L8.00003 3.99938L0.401594 11.5975L0.00471869 15.1669C-0.0452813 15.6163 0.308781 16 0.748469 16C0.776281 16 0.804406 15.9984 0.832531 15.9953L4.39941 15.6012L12.0007 8L13.0863 6.91437L15.4141 4.58656C16.1953 3.80531 16.1953 2.53906 15.4144 1.75812ZM3.71097 14.1684L1.59909 14.4016L1.83441 12.2863L9.06066 5.06L10.03 4.09062L11.9094 5.97L10.94 6.93937L3.71097 14.1684ZM14.3535 3.52562L12.9697 4.90938L11.0907 3.03L12.4744 1.64625C12.6019 1.51875 12.7507 1.49969 12.8278 1.49969C12.905 1.49969 13.0541 1.51875 13.1813 1.64625L14.3535 2.81844C14.5485 3.01375 14.5485 3.33094 14.3535 3.52562Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Gestión de funes"></ListItemText>
              </ListItem>
            </Link>
          </BootstrapTooltip>
          <BootstrapTooltip title="Renotificaciones" arrow placement="right">
            <Link className="linkMenu" to="/renotificaciones">
              <ListItem
                className="cerrarsesion"
                selected={
                  location.pathname == "/renotificaciones" ? true : false
                }
                button
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill=""
                  >
                    <path
                      d="M9 18.5636C6.50625 18.5636 4.38281 17.6871 2.62969 15.9339C0.876563 14.1808 0 12.0574 0 9.56363C0 7.06988 0.876563 4.94644 2.62969 3.19332C4.38281 1.44019 6.50625 0.563629 9 0.563629C10.5938 0.563629 11.9906 0.887067 13.1906 1.53394C14.3906 2.18082 15.4312 3.06675 16.3125 4.19175V0.563629H18V7.70738H10.8562V6.01988H15.5813C14.8688 4.89488 13.9594 3.9855 12.8531 3.29175C11.7469 2.598 10.4625 2.25113 9 2.25113C6.95625 2.25113 5.22656 2.95894 3.81094 4.37457C2.39531 5.79019 1.6875 7.51988 1.6875 9.56363C1.6875 11.6074 2.39531 13.3371 3.81094 14.7527C5.22656 16.1683 6.95625 16.8761 9 16.8761C10.5562 16.8761 11.9812 16.4308 13.275 15.5402C14.5688 14.6496 15.4688 13.473 15.975 12.0105H17.7188C17.175 13.9793 16.0969 15.5636 14.4844 16.7636C12.8719 17.9636 11.0437 18.5636 9 18.5636Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Renotificación de funes"></ListItemText>
              </ListItem>
            </Link>
          </BootstrapTooltip>
          <BootstrapTooltip
            title="Generador de remesas"
            arrow
            placement="right"
          >
            <Link className="linkMenu" to="/remesa">
              <ListItem
                className="cerrarsesion"
                selected={location.pathname == "/remesa" ? true : false}
                button
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill=""
                  >
                    <path
                      d="M18.125 2.5H10.625L8.49102 0.366016C8.37492 0.249949 8.2371 0.157886 8.08543 0.0950846C7.93375 0.0322833 7.77119 -2.64851e-05 7.60703 1.62896e-08H1.875C1.37772 1.62896e-08 0.900805 0.197544 0.549175 0.549175C0.197544 0.900806 0 1.37772 0 1.875L0 13.125C0 13.6223 0.197544 14.0992 0.549175 14.4508C0.900805 14.8025 1.37772 15 1.875 15H18.125C18.6223 15 19.0992 14.8025 19.4508 14.4508C19.8025 14.0992 20 13.6223 20 13.125V4.375C20 3.87772 19.8025 3.40081 19.4508 3.04917C19.0992 2.69754 18.6223 2.5 18.125 2.5ZM18.75 13.125C18.75 13.2908 18.6842 13.4497 18.5669 13.5669C18.4497 13.6842 18.2908 13.75 18.125 13.75H1.875C1.70924 13.75 1.55027 13.6842 1.43306 13.5669C1.31585 13.4497 1.25 13.2908 1.25 13.125V1.875C1.25 1.70924 1.31585 1.55027 1.43306 1.43306C1.55027 1.31585 1.70924 1.25 1.875 1.25H7.60703L9.74102 3.38398C9.85711 3.50005 9.99493 3.59211 10.1466 3.65492C10.2983 3.71772 10.4608 3.75003 10.625 3.75H18.125C18.2908 3.75 18.4497 3.81585 18.5669 3.93306C18.6842 4.05027 18.75 4.20924 18.75 4.375V13.125ZM13.2617 8.125H10.6055V5.46875C10.6055 5.34443 10.5561 5.2252 10.4682 5.13729C10.3803 5.04939 10.261 5 10.1367 5H9.82422C9.6999 5 9.58067 5.04939 9.49276 5.13729C9.40485 5.2252 9.35547 5.34443 9.35547 5.46875V8.125H6.69922C6.5749 8.125 6.45567 8.17439 6.36776 8.26229C6.27985 8.3502 6.23047 8.46943 6.23047 8.59375V8.90625C6.23047 9.03057 6.27985 9.1498 6.36776 9.23771C6.45567 9.32561 6.5749 9.375 6.69922 9.375H9.35547V12.0312C9.35547 12.1556 9.40485 12.2748 9.49276 12.3627C9.58067 12.4506 9.6999 12.5 9.82422 12.5H10.1367C10.261 12.5 10.3803 12.4506 10.4682 12.3627C10.5561 12.2748 10.6055 12.1556 10.6055 12.0312V9.375H13.2617C13.386 9.375 13.5053 9.32561 13.5932 9.23771C13.6811 9.1498 13.7305 9.03057 13.7305 8.90625V8.59375C13.7305 8.46943 13.6811 8.3502 13.5932 8.26229C13.5053 8.17439 13.386 8.125 13.2617 8.125Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Generador de remesas"></ListItemText>
              </ListItem>
            </Link>
          </BootstrapTooltip>
          <BootstrapTooltip title="Historial de Funes" arrow placement="right">
            <Link className="linkMenu" to="/historico">
              <ListItem
                className="cerrarsesion"
                selected={location.pathname == "/historico" ? true : false}
                button
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="17"
                    viewBox="0 0 19 17"
                    fill=""
                  >
                    <path
                      d="M7.5 8.59443C7.5 8.26613 7.76613 8 8.09443 8H10.6556C10.9839 8 11.25 8.26613 11.25 8.59443V8.90557C11.25 9.23387 10.9839 9.5 10.6556 9.5H8.09443C7.76613 9.5 7.5 9.23387 7.5 8.90557V8.59443Z"
                      fill=""
                    />
                    <path
                      // fill-rule="evenodd"
                      fillRule="evenodd"
                      // clip-rule="evenodd"
                      clipRule="evenodd"
                      d="M0 1.09443C0 0.766134 0.266134 0.5 0.594427 0.5H18.1556C18.4839 0.5 18.75 0.766134 18.75 1.09443V5.15557C18.75 5.48387 18.4839 5.75 18.1556 5.75H17.25V15.6556C17.25 15.9839 16.9839 16.25 16.6556 16.25H2.09443C1.76613 16.25 1.5 15.9839 1.5 15.6556V5.75H0.594427C0.266134 5.75 0 5.48387 0 5.15557V1.09443ZM17.25 4.25V2H1.5V4.25H17.25ZM3 14.75H15.75V5.75H3V14.75Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Historial de Funes"></ListItemText>
              </ListItem>
            </Link>
          </BootstrapTooltip>
          <BootstrapTooltip title="Reporte de Funes" arrow placement="right">
            <Link className="linkMenu" to="/reportefunes">
              <ListItem
                className="cerrarsesion"
                selected={location.pathname == "/reportefunes" ? true : false}
                button
              >
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="22"
                    viewBox="0 0 17 22"
                    fill=""
                  >
                    <path
                      d="M15.6687 4.51875L12.2282 1.07821C11.8591 0.709139 11.3588 0.5 10.838 0.5H2.46837C1.38166 0.504101 0.5 1.38576 0.5 2.47247V19.5316C0.5 20.6183 1.38166 21.5 2.46837 21.5H14.2786C15.3653 21.5 16.2469 20.6183 16.2469 19.5316V5.91301C16.2469 5.39221 16.0378 4.88782 15.6687 4.51875ZM14.7419 5.44962C14.8281 5.53574 14.8855 5.63826 14.9142 5.75308H10.998V1.83685C11.1128 1.86555 11.2153 1.92296 11.3014 2.00908L14.7419 5.44962ZM14.2786 20.1878H2.46837C2.1075 20.1878 1.81224 19.8925 1.81224 19.5316V2.47247C1.81224 2.1116 2.1075 1.81634 2.46837 1.81634H9.68571V6.08114C9.68571 6.62654 10.1245 7.06532 10.6699 7.06532H14.9347V19.5316C14.9347 19.8925 14.6394 20.1878 14.2786 20.1878ZM12.3102 10.1819V10.51C12.3102 10.7806 12.0888 11.0021 11.8181 11.0021H4.92882C4.65817 11.0021 4.43673 10.7806 4.43673 10.51V10.1819C4.43673 9.91125 4.65817 9.68981 4.92882 9.68981H11.8181C12.0888 9.68981 12.3102 9.91125 12.3102 10.1819ZM12.3102 12.8064V13.1344C12.3102 13.4051 12.0888 13.6265 11.8181 13.6265H4.92882C4.65817 13.6265 4.43673 13.4051 4.43673 13.1344V12.8064C4.43673 12.5357 4.65817 12.3143 4.92882 12.3143H11.8181C12.0888 12.3143 12.3102 12.5357 12.3102 12.8064ZM12.3102 15.4309V15.7589C12.3102 16.0296 12.0888 16.251 11.8181 16.251H4.92882C4.65817 16.251 4.43673 16.0296 4.43673 15.7589V15.4309C4.43673 15.1602 4.65817 14.9388 4.92882 14.9388H11.8181C12.0888 14.9388 12.3102 15.1602 12.3102 15.4309Z"
                      fill=""
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Reporte de Funes"></ListItemText>
              </ListItem>
            </Link>
          </BootstrapTooltip>
          <BootstrapTooltip title="" arrow placement="right">
            <ListItem className="cerrarsesion disabledMenu" button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.75 15.4439C10.75 15.0297 10.4142 14.6939 9.99998 14.6939C9.58576 14.6939 9.24998 15.0297 9.24998 15.4439H10.75ZM9.24998 15.4548C9.24998 15.869 9.58576 16.2048 9.99998 16.2048C10.4142 16.2048 10.75 15.869 10.75 15.4548H9.24998ZM9.24998 12.182C9.24998 12.5963 9.58576 12.932 9.99998 12.932C10.4142 12.932 10.75 12.5963 10.75 12.182H9.24998ZM6.00312 6.73056C5.89527 7.13049 6.13204 7.54212 6.53197 7.64997C6.9319 7.75782 7.34353 7.52105 7.45138 7.12112L6.00312 6.73056ZM9.24998 15.4439V15.4548H10.75V15.4439H9.24998ZM12.5227 7.71923C12.5227 8.41224 12.0825 8.77592 11.2138 9.36815C10.4694 9.87573 9.24998 10.6331 9.24998 12.182H10.75C10.75 11.5492 11.1669 11.2156 12.0588 10.6075C12.8266 10.084 14.0227 9.30721 14.0227 7.71923H12.5227ZM9.99998 5.29568C11.4397 5.29568 12.5227 6.39806 12.5227 7.71923H14.0227C14.0227 5.53499 12.2331 3.79568 9.99998 3.79568V5.29568ZM7.45138 7.12112C7.72539 6.10504 8.76259 5.29568 9.99998 5.29568V3.79568C8.13863 3.79568 6.46735 5.00909 6.00312 6.73056L7.45138 7.12112ZM5.63634 2.02295H14.3636V0.522949H5.63634V2.02295ZM17.9772 5.63659V14.3639H19.4772V5.63659H17.9772ZM14.3636 17.9775H5.63634V19.4775H14.3636V17.9775ZM2.02271 14.3639V5.63659H0.522705V14.3639H2.02271ZM5.63634 17.9775C3.64059 17.9775 2.02271 16.3596 2.02271 14.3639H0.522705C0.522705 17.188 2.81216 19.4775 5.63634 19.4775V17.9775ZM17.9772 14.3639C17.9772 16.3596 16.3594 17.9775 14.3636 17.9775V19.4775C17.1878 19.4775 19.4772 17.188 19.4772 14.3639H17.9772ZM14.3636 2.02295C16.3594 2.02295 17.9772 3.64083 17.9772 5.63659H19.4772C19.4772 2.8124 17.1878 0.522949 14.3636 0.522949V2.02295ZM5.63634 0.522949C2.81216 0.522949 0.522705 2.8124 0.522705 5.63659H2.02271C2.02271 3.64083 3.64059 2.02295 5.63634 2.02295V0.522949Z"
                    fill="#BCC2C6"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText primary="Ayuda" sx={{ color: "#BCC2C6" }} />
              <Link to="/remesa"></Link>
            </ListItem>
          </BootstrapTooltip>
        </List>
      </CustomDrawer>
    </Box>
  );
};
