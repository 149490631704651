import React, { useState } from "react";
import EmpresasConvenios from "./EmpresasConvenio";
import HistorialConvenios from "./HistorialConvenios";
import SearchEmpresas from "./SearchEmpresas";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../Material/Tabs/TabsMaterial.scss";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const IndexEmpresas = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <h3 className="tituloPag">Empresas y Convenios</h3>
      <Grid
        sx={{ padding: "5px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Empresas y Convenios
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          padding: "0 0 26px",
          // width: "640px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "#D2D6D9" }}>
            <TabList
              onChange={handleChange}
              aria-label="tabs"
              TabIndicatorProps={{ style: { background: "#00837A" } }}
            >
              <Tab label="Empresas y Convenios" value="1" className="tabFont" />
              <Tab
                label="Historial de Rendición de Convenios"
                value="2"
                className="tabFont"
              />
            </TabList>
          </Box>
          <TabPanel value="1" className="tabPaneldiv">
            <SearchEmpresas />
            <EmpresasConvenios />
          </TabPanel>
          <TabPanel value="2" className="tabPaneldiv">
            <HistorialConvenios />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default IndexEmpresas;
