import { useState, useContext, useEffect } from "react";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import NotifContext from "./../Context/ContextNotif";
import { useHistory } from "react-router-dom";
import "./EmpresasConvenio.scss";
import "./../Material/material.scss";
import "../Historial/HistoricoFunes.scss";
import { OutlinedButton } from "./../Botones";
import { StyledTableCell, StyledTableRow } from "./../Material/Table/table";
import {
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ExportIcon } from "../../Images/iconosEsencial";
import { Container } from "react-bootstrap";
import { EMPRESAS_CONVENIO, HISTORIAL_CONVENIO } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import ModalDrag from "../DragDrop/components/ModalDrag";
import SkeletonHistorial from "../Historial/SkeletonHistorial";
import Paginator from "../Paginator/Paginator";
import ResultadoTablas from "../ResultadoTablas/ResultadoTablas";
import moment from "moment";

const EmpresasConvenios = () => {
  const {
    dataFiltroEmpresas,
    setRutEmpresa,
    uploadComplete,
    setUploadComplete,
  } = useContext(RemesaContext);
  const { loader } = useContext(NotifContext);
  const [row, setRow] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [getData, { data, loading, error }] = useLazyQuery(EMPRESAS_CONVENIO, {
    variables: {
      offset: currentPage * row - row,
      limit: row,
      rut: null,
      operador: null,
      razonSocial: null,
      countEmpleadoresRut2: null,
      countEmpleadoresOperador2: null,
      countEmpleadoresRazonSocial2: null,
    },
  });
  const [getDataFecha, { data: dataFecha }] = useLazyQuery(HISTORIAL_CONVENIO);
  const [dateUpdate, setDateUpdate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accion, setAccion] = useState({
    rut: null,
  });

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  const aplicaFiltro = async () => {
    setCurrentPage(1);
    await getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        rut: dataFiltroEmpresas?.filter?.rutEmpresa
          ? dataFiltroEmpresas?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        operador: parseInt(dataFiltroEmpresas?.filter?.operador) ||  null,
        razonSocial: dataFiltroEmpresas?.filter?.empresa || null,
        countEmpleadoresRut2: dataFiltroEmpresas?.filter?.rutEmpresa
          ? dataFiltroEmpresas?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        countEmpleadoresOperador2: parseInt(
          dataFiltroEmpresas?.filter?.operador
        ) || null,
        countEmpleadoresRazonSocial2:
          dataFiltroEmpresas?.filter?.empresa || null,
      },
    });
  };

  const buscarDatos = () => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        rut: dataFiltroEmpresas?.filter?.rutEmpresa
          ? dataFiltroEmpresas?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        operador: parseInt(dataFiltroEmpresas?.filter?.operador) ||  null,
        razonSocial: dataFiltroEmpresas?.filter?.empresa || null,
        countEmpleadoresRut2: dataFiltroEmpresas?.filter?.rutEmpresa
          ? dataFiltroEmpresas?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        countEmpleadoresOperador2: parseInt(
          dataFiltroEmpresas?.filter?.operador
        ) || null,
        countEmpleadoresRazonSocial2:
          dataFiltroEmpresas?.filter?.empresa || null,
      },
    });
  };

  const handleClickMenu = (event, rut: number) => {
    setAnchorEl(event.currentTarget);
    setAccion({ ...accion, rut });
  };

  const handleClick = (rutEmpresa: number) => {
    let rut: number = rutEmpresa;
    setRutEmpresa(rut);
    setAnchorEl(null);
    routeChange(rut);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const routeChange = (rut) => {
    setRutEmpresa(rut);
    let path = `editarEmpresas`;
    history.push(path);
  };

  useEffect(() => {
    getData();
    getDataFecha();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    buscarDatos();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    buscarDatos();
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    aplicaFiltro();
    // eslint-disable-next-line
  }, [dataFiltroEmpresas]);

  useEffect(() => {
    if (dataFecha?.getHistorial !== null) {
      setDateUpdate(
        moment(new Date(dataFecha?.getHistorial[0].createdAt)).format(
          "DD/MM/yyyy"
        )
      );
    }
    // eslint-disable-next-line
  }, [dataFecha]);

  useEffect(() => {
    setUploadComplete(false);
    buscarDatos();
    // eslint-disable-next-line
  }, [uploadComplete]);

  if (loading)
    return (
      <Container className="content2">
        <SkeletonHistorial></SkeletonHistorial>
      </Container>
    );

  return (
    <>
      <Container className="content2" data-testid="containerEC">
        <div className="containerTable">
          {error || data?.getEmpleadores === null ? (
            <div className="remesa-box tabla sin-remesa">
              Error cargando data.
            </div>
          ) : (
            <>
              {data?.getEmpleadores.length === 0 && dataFiltroEmpresas !== 0 ? (
                <ResultadoTablas
                  titulo="No encontramos coincidencias"
                  subtitulo="No hay empresas con las características que seleccionaste."
                  icono="icon-info"
                  boton={false}
                />
              ) : (
                <TableContainer component={Paper}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      sx={{ width: "40%", float: "left", marginBottom: "15px" }}
                    >
                      <p className="labelFormTitle2">Listado de Empresas</p>
                      <Grid item xs={12}>
                        <p className="labelForm3">
                          Última actualización {dateUpdate}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="end"
                      alignItems="center"
                      sx={{ width: "54%", float: "right", margin: "0 4px 0" }}
                    >
                      <OutlinedButton sx={{ marginBottom: "15px" }}>
                        <ExportIcon />
                        Exportar
                      </OutlinedButton>
                      <ModalDrag
                        textButton={"Rendir convenio"}
                        modalTitle={"Actualizar convenio"}
                        type={"convenio"}
                        rowRemesa={null}
                        canalRemesa={null}
                        disabled={loader}
                        setShowModal={setShowModal}
                      />
                    </Grid>
                  </Grid>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          sx={{ minWidth: "650px" }}
                        >
                          Nombre
                        </StyledTableCell>
                        <StyledTableCell align="left">RUT</StyledTableCell>
                        <StyledTableCell align="left">Operador</StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ maxWidth: "100px" }}
                        >
                          Acciones
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.getEmpleadores.map((e) => (
                        <StyledTableRow key={e.rut}>
                          <TableCell>{e.razonSocial}</TableCell>
                          <TableCell>{e.rut + "-" + e.dv}</TableCell>
                          <TableCell>{e.operador}</TableCell>
                          <TableCell align="center">
                            <MoreVertIcon
                              onClick={(event) => handleClickMenu(event, e.rut)}
                              id={e.rut}
                              sx={{ cursor: "pointer" }}
                              data-testid="btnMenu"
                            />

                            <Menu
                              className="menu-box"
                              sx={{
                                boxShadow: "1px 2px 7px 0px #e3e3e3!important",
                              }}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem
                                onClick={() => handleClick(accion.rut)}
                                data-testid="btnUpdate"
                              >
                                Actualizar empleador
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Paginator
                    data-testid="selectNumberTableRows"
                    component="div"
                    rowsPerPageOptions={[10, 20, 50]}
                    count={parseInt(data?.countEmpleadores) || 0}
                    rowsPerPage={row}
                    page={currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ mt: "32px", mb: "10px" }}
                    labelRowsPerPage={"Mostrar por página"}
                    labelDisplayedRows={(e) => {
                      return (
                        "" +
                        currentPage +
                        " de " +
                        Math.ceil((data?.countEmpleadores || 0) / row)
                      );
                    }}
                  />
                </TableContainer>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default EmpresasConvenios;
