import React, { useState, useContext, useEffect } from "react";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import { Grid, MenuItem, Select } from "@mui/material";
import PrimaryButton, { SecondaryButton } from "./../Botones";
import FormControl from "@mui/material/FormControl";
import ThemeTextField from "./../textField";
import {
  CustomInputSelect,
  menuItemStyle,
  menuProps,
} from "./../select/styledSelect";
import { useFormik } from "formik";
import {
  checkRut,
  prettifyRut,
  formatRut,
  removeSeparators,
} from "react-rut-formatter";
import * as yup from "yup";

const validationSchema = yup.object({
  rutEmpresa: yup.string().nullable(),
});

const SearchEmpresas = () => {
  const { dataFiltroEmpresas, setDataFiltroEmpresas } =
    useContext(RemesaContext);
  const [operador, setOperador] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [rutEmpresa, setRutEmpresa] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [cleanDisabled, setCleanDisabled] = useState(true);

  const formik = useFormik({
    initialValues: {
      rutEmpresa: rutEmpresa,
    },
    validationSchema: validationSchema,
    validate(values) {
      const errors: { rutEmpresa?: string } = {};
      if (values.rutEmpresa && !checkRut(values.rutEmpresa)) {
        errors.rutEmpresa = "RUT inválido";
      } else if (checkRut(values.rutEmpresa) && !rutLength(values.rutEmpresa)) {
        errors.rutEmpresa = "RUT inválido";
      }
      return errors;
    },
    onSubmit: (values) => {
      formatRut(values.rutEmpresa);
    },
  });

  const rutLength = (val: string) => {
    if (
      removeSeparators(val).length >= 8 &&
      removeSeparators(val).length <= 10
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterEmpresas = () => {
    let filter = {
      operador: operador,
      empresa: empresa,
      rutEmpresa: rutEmpresa,
    };
    setDataFiltroEmpresas({ ...dataFiltroEmpresas, filter });
  };

  const limpiar = () => {
    setOperador(null);
    setEmpresa(null);
    setRutEmpresa(null);
    let filter = {
      operador: null,
      empresa: null,
      rutEmpresa: null,
    };
    setDataFiltroEmpresas({ ...dataFiltroEmpresas, filter });
    formik.setTouched({}, false);
    formik.values.rutEmpresa = "";
  };

  useEffect(() => {
    let errorValidation = [];
    if (Boolean(formik.errors.rutEmpresa)) {
      errorValidation.push(formik.errors.rutEmpresa);
    }
    // eslint-disable-next-line
  }, [formik.errors]);

  useEffect(() => {
    if (operador === null && !empresa && !rutEmpresa) {
      setCleanDisabled(true);
    } else {
      setCleanDisabled(false);
    }
    if (operador !== null || empresa) {
      if (rutEmpresa == null || rutEmpresa == "" || checkRut(rutEmpresa))
        setIsDisabled(false);
    } else if (!checkRut(rutEmpresa)) {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [operador, empresa, rutEmpresa]);

  useEffect(() => {
    if (checkRut(rutEmpresa) && rutLength(rutEmpresa)) {
      setIsDisabled(false);
    } else if (rutEmpresa !== "" && rutEmpresa !== null) {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [rutEmpresa]);

  return (
    <>
      <div className="card-box">
        <Grid
          sx={{ padding: "5px 15px 5px 8px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter"
        >
          <Grid item xs={12}>
            <p className="tituloSecPag1">Filtros de búsqueda</p>
          </Grid>

          <Grid item sx={{ width: "19%" }}>
            <p className="labelForm">Operador</p>
            <FormControl sx={{ width: "98%", margin: "0 1% 0 0" }}>
              <Select
                sx={{ width: "100%" }}
                input={<CustomInputSelect />}
                MenuProps={menuProps}
                value={operador === null ? 10 : operador}
                onChange={(e) => setOperador(parseInt(e.target.value))}
                inputProps={{ "data-testid": "Operador" }}
              >
                <MenuItem value="10" sx={menuItemStyle} hidden>
                  Selecciona un Operador
                </MenuItem>
                <MenuItem value="0" sx={menuItemStyle}>
                  Sin asignar
                </MenuItem>
                <MenuItem value="1" sx={menuItemStyle}>
                  Previred
                </MenuItem>
                <MenuItem value="2" sx={menuItemStyle}>
                  X-Data
                </MenuItem>
                <MenuItem value="3" sx={menuItemStyle}>
                  Manual
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ width: "19%" }}>
            <p className="labelForm">Razón social</p>
            <FormControl sx={{ width: "98%", margin: "0 1% 0 0" }}>
              <ThemeTextField
                fullWidth
                placeholder="Ej: Lenovo"
                value={empresa === null ? "" : empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                inputProps={{ "data-testid": "RazonSocial" }}
              />
            </FormControl>
          </Grid>
          <Grid item sx={{ width: "19%" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formatted = prettifyRut(rutEmpresa);
                setRutEmpresa(formatted);
                formik.handleSubmit();
              }}
              onBlur={() => {
                const formatted = prettifyRut(rutEmpresa);
                setRutEmpresa(formatted);
                formik.handleSubmit();
              }}
            >
              <p className="labelForm">Rut Empresa</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  id="rutEmpresa"
                  name="rutEmpresa"
                  fullWidth
                  placeholder="Ej: 12345678-9"
                  error={
                    formik.touched.rutEmpresa &&
                    Boolean(formik.errors.rutEmpresa)
                  }
                  helperText={
                    formik.touched.rutEmpresa && formik.errors.rutEmpresa
                  }
                  value={rutEmpresa || ""}
                  onChange={(e) => {
                    setRutEmpresa(e.target.value);
                    formik.handleChange(e);
                  }}
                  data-testid="RutEmpresa"
                />
              </FormControl>
            </form>
          </Grid>
          <Grid item sx={{ width: "43%" }}>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              sx={{ margin: "0", padding: "32px 10px 0" }}
            >
              <SecondaryButton
                sx={{ marginRight: "19px" }}
                onClick={() => limpiar()}
                data-testid="cleanFilters"
                disabled={cleanDisabled}
              >
                Limpiar Filtros
              </SecondaryButton>
              <PrimaryButton
                sx={{ marginRight: "0" }}
                onClick={() => filterEmpresas()}
                data-testid="btnSearch"
                disabled={isDisabled}
              >
                Buscar Empresas
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SearchEmpresas;
