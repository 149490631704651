import React, { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import moment from "moment";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import ExportToExcel, { ExportToExcelManual } from "../../utils/ExportToExcel";
import ExportToXml from "../../utils/ExportToXml";
import EstadoDropdown from "./EstadoDropdown";
import DatepickerComponent from "../datepicker/DatepickerComponent";
import {
  DOWNLOAD_REMESA_XDATA,
  DOWNLOAD_REMESA_MANUAL,
  DOWNLOAD_REMESA_PREVIRED,
  PAGINADOR_REMESA,
} from "../../graphql/queries";
import "./ModalUpload.scss";
import "./Remesa.scss";
import SkeletonRemesa from "./SkeletonRemesa";
import "../Material/material.scss";
import { MediumButton } from "../Botones";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import {
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paginator from "../Paginator/Paginator";
import ModalDrag from "../DragDrop/components/ModalDrag";

export const TableRemesas = () => {
  const client = useApolloClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRow] = useState(20);
  const history = useHistory();
  const {
    setDataRemesa,
    setFechaRemesa,
    setRemesaCerrada,
    remesa,
    setRemesa,
    estadoRemesaContext,
    uploadComplete,
    setUploadComplete,
    modalCarga,
    setModalCarga,
  } = useContext(RemesaContext);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const today = new Date();
  const [rowRemesa, setRowRemesa] = useState(0);
  const [canalRemesa, setCanalRemesa] = useState("Electronica");
  const [getData, { data, loading, error }] = useLazyQuery(PAGINADOR_REMESA, {
    variables: {
      offset: currentPage * row - row,
      limit: 20,
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [accion, setAccion] = useState({
    remesa: null,
    estado: null,
    canal: null,
  });

  const handleClickMenu = (
    event,
    remesa: number,
    estado: string,
    canal: string
  ) => {
    setAnchorEl(event.currentTarget);
    setAccion({ ...accion, remesa, estado, canal });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const routeChange = (number, fecha, estado) => {
    let remesaID: number = parseInt(number);
    let remesaFecha: string = moment(new Date(fecha)).format("DD/MM/yyyy");
    setDataRemesa(remesaID);
    setFechaRemesa(remesaFecha);
    if (estado === "Cerrada") {
      setRemesaCerrada(true);
    } else {
      setRemesaCerrada(false);
    }
    let path = `detalle`;
    history.push(path);
  };

  const functionExcel = async (idRemesa: number, nombre: string) => {
    const { data: dataExcel } = await client.query({
      query: DOWNLOAD_REMESA_XDATA,
      variables: {
        idRemesa: idRemesa,
      },
    });
    if (dataExcel?.getXData !== null) {
      ExportToExcel(dataExcel, nombre);
    }
  };

  const functionExcelManual = async (idRemesa: number, nombre: string) => {
    const { data: dataManual } = await client.query({
      query: DOWNLOAD_REMESA_MANUAL,
      variables: {
        idRemesa: idRemesa,
      },
    });
    if (dataManual?.getManual !== null) {
      ExportToExcelManual(dataManual, nombre);
    }
  };

  const functionXml = async (idRemesa: number, nombre: string) => {
    const { data: dataXml } = await client.query({
      query: DOWNLOAD_REMESA_PREVIRED,
      variables: {
        idRemesa: idRemesa,
      },
    });
    if (dataXml?.getPreviRed !== null) {
      ExportToXml(dataXml, nombre);
    }
  };

  const handleClick = (id_remesa: number, canal_notificacion: string) => {
    setRowRemesa(id_remesa);
    setCanalRemesa(canal_notificacion);
    setModalCarga(true);
    setAnchorEl(null);
  };

  const downloadRemesa = (canal: string, folioRemesa: number) => {
    if (canal === "Previred") {
      let fechaRemesa = moment(new Date(today)).format("yyyyMM");
      let nombreXml = "CFI0108" + fechaRemesa + "001";
      functionXml(folioRemesa, nombreXml);
    } else if (canal === "X-Data") {
      let fechaRemesa = moment(new Date(today)).format("yyyyMMDD");
      let nombreExcel = fechaRemesa + "-XData-" + folioRemesa;
      functionExcel(folioRemesa, nombreExcel);
    } else if (canal === "Manual") {
      let fechaRemesa = moment(new Date(today)).format("yyyyMMDD");
      let nombreExcel = fechaRemesa + "-Manual-" + folioRemesa;
      functionExcelManual(folioRemesa, nombreExcel);
    }
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (estadoRemesaContext !== "0") {
      getData({
        variables: {
          offset: currentPage * row - row,
          limit: row,
        },
      });
    }
    // eslint-disable-next-line
  }, [estadoRemesaContext]);

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
      },
    });
    setRemesa(false);
    // eslint-disable-next-line
  }, [remesa]);

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
      },
    });
    setUploadComplete(false);
    // eslint-disable-next-line
  }, [uploadComplete]);

  useEffect(() => {
    if (modalCarga === false) {
      setRowRemesa(0);
      setCanalRemesa("Electronica");
    }
    // eslint-disable-next-line
  }, [modalCarga]);

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
      },
    });
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
      },
    });
    // eslint-disable-next-line
  }, [row]);

  if (loading)
    return (
      <Container className="content2">
        <SkeletonRemesa />
      </Container>
    );

  return (
    <Container className="content2">
      <div className="sin-remesa-contenido hide-box">
        <p className="icon">
          <span>Bulb</span>
        </p>
        <h3>Aún no generas tu primera remesa</h3>
        <p>
          Selecciona el canal y el estado de los funes que vas a agrupar en el
          selector para empezar
        </p>
      </div>
      <div className="card-box">
        {error || data?.getRemesaPaginator === null ? (
          <div className="remesa-box tabla sin-remesa">
            Error cargando data.
          </div>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className="labelForm2">Remesas generadas</p>
                <ModalDrag
                  textButton={"Rendición electrónica"}
                  modalTitle={
                    rowRemesa !== 0
                      ? "Actualización de remesa " + rowRemesa
                      : "Actualización de remesa"
                  }
                  type={"remesa"}
                  rowRemesa={rowRemesa}
                  canalRemesa={canalRemesa}
                  disabled={false}
                  setShowModal={setShowModal}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="headerTableIzquierda">
                      N° Remesa
                    </StyledTableCell>
                    <StyledTableCell>Estado</StyledTableCell>
                    <StyledTableCell>Fecha creación</StyledTableCell>
                    <StyledTableCell>Fecha de carga</StyledTableCell>
                    <StyledTableCell>Total funes</StyledTableCell>
                    <StyledTableCell>Operador</StyledTableCell>
                    <StyledTableCell>Región</StyledTableCell>
                    <StyledTableCell className="headerTableAcciones">
                      Detalles
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className="headerTableAcciones2"
                      sx={{ maxWidth: "158px" }}
                    >
                      Acciones
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.getRemesaPaginator.map((e) => (
                    <StyledTableRow key={e.remesa_id} data-testid="tableItem">
                      <StyledTableCell className="headerTableIzquierda">
                        {e.remesa_id}
                      </StyledTableCell>
                      <TableCell sx={{ minWidth: "140px" }}>
                        {e.estado_remesa !== "Por Enviar" ? (
                          <EstadoDropdown
                            id={e.remesa_id}
                            estadoRemesa={e.estado_remesa}
                            showToast={showToast}
                            setShowToast={setShowToast}
                            data-testid="dropdownChangeEstado"
                          />
                        ) : (
                          e.estado_remesa
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(new Date(e.fecha_creacion)).format(
                          "DD/MM/yyyy"
                        )}
                      </TableCell>
                      {e.fecha_envio !== null ? (
                        <TableCell>
                          {moment(new Date(e.fecha_envio)).format("DD/MM/yyyy")}
                        </TableCell>
                      ) : (
                        <>
                          <TableCell>
                            <DatepickerComponent
                              id={e.remesa_id}
                              estado={e.estado_remesa}
                              fecha={e.fecha_creacion}
                              showToast={showToast}
                              setShowToast={setShowToast}
                            />
                          </TableCell>
                        </>
                      )}
                      <TableCell>{e.cantida_funes}</TableCell>
                      <TableCell>{e.canal_notificacion}</TableCell>
                      {e.canal_notificacion === "Manual" ? (
                        <TableCell>
                          {e.region_id === 13 ? "RM" : "Otras regiones"}
                        </TableCell>
                      ) : (
                        <TableCell>-</TableCell>
                      )}
                      <TableCell className="headerTableAcciones">
                        <MediumButton
                          sx={{ marginRight: "4px" }}
                          onClick={() => {
                            routeChange(
                              e.remesa_id,
                              e.fecha_creacion,
                              e.estado_remesa
                            );
                          }}
                          data-testid="buttonSeeDetails"
                        >
                          Ver detalles
                        </MediumButton>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="headerTableAcciones2"
                      >
                        <MoreVertIcon
                          onClick={(event) =>
                            handleClickMenu(
                              event,
                              e.remesa_id,
                              e.estado_remesa,
                              e.canal_notificacion
                            )
                          }
                          id={e.remesa_id}
                          sx={{ cursor: "pointer" }}
                          data-testid="buttonMore"
                        />

                        <Menu
                          className="menu-box"
                          sx={{ boxShadow: "0px 2px 10px #C9CFE3!important" }}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          {accion.canal === "Manual" && (
                            <MenuItem
                              onClick={() =>
                                handleClick(accion.remesa, accion.canal)
                              }
                              disabled={accion.estado !== "Enviada"}
                            >
                              Actualizar Remesa
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              downloadRemesa(accion.canal, accion.remesa);
                            }}
                            data-testid="buttonDownload"
                          >
                            Descargar Remesa
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <Paginator
                data-testid="selectNumberTableRows"
                component="div"
                rowsPerPageOptions={[10, 20, 50]}
                count={parseInt(data?.countRemesas) || 0}
                rowsPerPage={row}
                page={currentPage - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ mt: "32px", mb: "10px" }}
                labelRowsPerPage={"Mostrar por página"}
                labelDisplayedRows={(e) => {
                  return (
                    "" +
                    currentPage +
                    " de " +
                    Math.ceil((data?.countRemesas || 0) / row)
                  );
                }}
              />
            </TableContainer>
          </>
        )}
      </div>
    </Container>
  );
};

export default TableRemesas;
