/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "../Material/material.scss";
import {} from "../select/CustomSelect";
import { Grid } from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

registerLocale("es", es);

type Props = {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
};

export const DaterangeComponent: FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const today = new Date();

  return (
    <>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={startDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          renderInput={(params) => (
            <>
              <TextField {...params} />
            </>
          )}
        />
        <DatePicker
          value={endDate}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
          renderInput={(params) => (
            <>
              <TextField {...params} />
            </>
          )}
        />
      </LocalizationProvider> */}
      <Grid
        sx={{ padding: "5px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Grid item xs={6}>
          <p className="labelForm">Desde</p>
          <DatePicker
            locale="es"
            selected={startDate}
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date("04/01/2022")}
            className="calendario-remesa"
          />
        </Grid>
        <Grid item xs={6}>
          <p className="labelForm">Hasta</p>
          <DatePicker
            locale="es"
            selected={endDate}
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={today}
            className="calendario-remesa"
            data-testid="datepickerB"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DaterangeComponent;
