/* istanbul ignore file */
import React, { FC, useEffect, useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import {
  ARCHIVO_CREATE_FUNES,
  ARCHIVO_UPDATE_FUNES_XDATA,
  UPDATE_CONVENIO,
} from "../../../graphql/mutations";
import DataContext from "./../../Context/ContextSearch";
import RemesaContext from "../../Context/ContextDetalleRemesa";
import NotifContext from "../../Context/ContextNotif";
import ToastMaterial from "../../toast/ToastMaterial";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import { callMsGraph } from "../../auth/graph";
import { DragAndDrop } from "../DragAndDrop";
import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../Botones";
import { ActualizarIcon } from "../../../Images/iconosEsencial";
import { ModalMaterial } from "../../Material/Modales/ModalMaterial2";

type ModalDragProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  textButton: string;
  modalTitle: string;
  type: string;
  rowRemesa: number;
  canalRemesa: string;
  disabled: boolean;
};

export const ModalDrag: FC<ModalDragProps> = ({
  textButton,
  modalTitle,
  type,
  rowRemesa,
  canalRemesa,
  disabled,
  setShowModal,
}) => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [archivo, setArchivo] = useState("");
  const { setUploadComplete } = useContext(DataContext);
  const { setLoader, setFilename } = useContext(NotifContext);
  const {
    setUploadComplete: setUpdateTable,
    modalCarga,
    setModalCarga,
  } = useContext(RemesaContext);
  const [uploadFun, { data, loading }] = useMutation(ARCHIVO_CREATE_FUNES, {
    // onCompleted: () => {
    // },
    errorPolicy: "all",
  });

  const [updateConvenio, { data: dataConvenio, loading: loadingConvenio }] =
    useMutation(UPDATE_CONVENIO, {
      onCompleted: (dataConvenio) => {
        if (dataConvenio?.uploadConvenio.code === "01") {
          setOpen(false);
          setShowToast(true);
          setUpdateTable(true);
        } else if (dataConvenio?.uploadConvenio.code === "02") {
          setOpen(false);
          setShowToast(true);
        }
      },
      onError: () => {
        setLoader(true);
        setOpen(false);
        setUpdateTable(true);
      },
      errorPolicy: "all",
    });

  const [updateRemesa, { data: dataRemesa, loading: loadingRemesa }] =
    useMutation(ARCHIVO_UPDATE_FUNES_XDATA, {
      onCompleted: () => {
        setShowToast(true);
        setOpen(false);
        setUpdateTable(true);
        setModalCarga(false);
      },
      errorPolicy: "all",
    });

  const handleOpen = () => {
    setOpen(true);
    RequestProfileData();
  };

  const handleClose = () => {
    setOpen(false);
    setClear(true);
    if (type === "fun") {
      setUploadComplete(true);
    }
    if (type === "remesa") {
      setModalCarga(false);
    }
  };

  function RequestProfileData() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }

  const uploadFile = () => {
    if (type === "fun") {
      uploadFun({
        variables: {
          file: archivo[0]["file"],
        },
      });
    }
    if (type === "remesa") {
      updateRemesa({
        variables: {
          file: archivo[0]["file"],
          rowRemesa: rowRemesa,
          canalRemesa: canalRemesa,
          usuario: graphData.displayName,
          type: archivo[0]["file"].name.split(".").pop(),
        },
      });
    }
    if (type === "convenio") {
      setFilename(archivo[0]["file"].name);
      updateConvenio({
        variables: {
          file: archivo[0]["file"],
          usuario: graphData.displayName,
          archivo: archivo[0]["file"].name,
          extension: archivo[0]["file"].name.split(".").pop(),
        },
      });
    }
  };

  useEffect(() => {
    if (archivo.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [archivo]);

  useEffect(() => {
    setOpen(false);
    // eslint-disable-next-line
  }, [data?.uploadFile?.code]);

  useEffect(() => {
    if (modalCarga === true) {
      handleOpen();
    }
    // eslint-disable-next-line
  }, [modalCarga]);

  useEffect(() => {
    if (loading || loadingRemesa || loadingConvenio) {
      setOpen(true);
    }
    // eslint-disable-next-line
  }, [loading, loadingRemesa, loadingConvenio]);

  if (loading || loadingRemesa || loadingConvenio) {
    return (
      <>
        <SecondaryButton
          variant="text"
          // color="default"
          onClick={() => {
            setOpen(true);
            RequestProfileData();
          }}
          sx={{ margin: "0 10px 10px" }}
        >
          <ActualizarIcon />
          {textButton}
        </SecondaryButton>
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <DialogContent sx={{ padding: "20px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                data-testid="dialogModal"
                sx={{
                  m: 1,
                  textAlign: "center",
                  padding: "0",
                  height: "350px",
                }}
              >
                <CircularProgress className="spinnerGreen"></CircularProgress>
                <Typography className="contentDialogLoading">
                  Cargando documento
                </Typography>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <SecondaryButton
        variant="text"
        // color="default"
        disabled={disabled}
        onClick={handleOpen}
        sx={{ margin: "0 20px 10px" }}
      >
        <ActualizarIcon />
        {textButton}
      </SecondaryButton>
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "20px 0" }}
        >
          <DialogTitle className="tituloModal" sx={{ padding: "10px 0" }}>
            {modalTitle}
          </DialogTitle>
          <DialogContent sx={{ padding: "20px" }}>
            <Grid
              container
              spacing={2}
              direction="row"
              sx={{ padding: "0 20px" }}
            >
              <Typography sx={{ padding: "19px 18px 0px 16px" }}>
                Cargar documento
              </Typography>

              <Grid item xs={12}>
                <DragAndDrop
                  files={archivo}
                  setFiles={setArchivo}
                  clear={clear}
                  setClear={setClear}
                  maxFiles={1}
                />
              </Grid>
            </Grid>

            <DialogActions sx={{ padding: "20px 0 0 0" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <PrimaryButton disabled={isDisabled} onClick={uploadFile}>
                  Continuar
                </PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>
      {data?.uploadFile?.code && (
        <ModalMaterial
          code={data?.uploadFile?.code}
          message={data?.uploadFile?.message}
          dataError={data?.uploadFile?.dataError}
          setShowModal={setShowModal}
        />
      )}
      {dataRemesa?.updateXData?.code && (
        <ToastMaterial
          message={dataRemesa?.updateXData?.message}
          showToast={showToast}
          className={
            dataRemesa?.updateXData?.code === "01" ? "success" : "error"
          }
          setShowToast={setShowToast}
        />
      )}
    </>
  );
};

export default ModalDrag;
