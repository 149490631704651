import { useEffect, useRef, useState } from "react";
import { Grid, Autocomplete } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import PrimaryButton, { SecondaryButton } from "../Botones";
import ThemeTextField from "../textField";

const tipoNotif = [
  { id: 0, text: "Sin movimientos en el mes informado" },
  { id: 1, text: "Suscripción del Contrato" },
  { id: 2, text: "Término de Contrato" },
  { id: 3, text: "Situaciones especiales" },
  { id: 4, text: "Cambio de Empleador" },
  { id: 5, text: "Cambio de Anualidad" },
  { id: 6, text: "Variación de precio por cambio de tramo de edad" },
  { id: 7, text: "Modificación de Carga" },
  { id: 8, text: "Modificación de Cotización Pactada" },
  { id: 9, text: "Cambio de plan" },
];

const SearchRenot = ({ dataFiltro, setDataFiltro }) => {
  const [tipoNot, setTipoNot] = useState<any>([]);
  const [folio, setFolio] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [cleanDisabled, setCleanDisabled] = useState(true);
  const autoC = useRef(null);

  const handleTipoNot = (newValue) => {
    if (newValue !== null) {
      let idTipo = newValue
        .map((a) => a.id)
        .sort((a, b) => a - b)
        .join();
      setTipoNot(idTipo);
    }
  };

  const clearTipoNot = () => {
    let clearTipo = autoC.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (clearTipo !== undefined) {
      clearTipo.click();
    }
  };

  const filtroRenot = () => {
    let filtro = {
      folio: folio,
      tipo: tipoNot.length > 0 ? tipoNot : null,
    };
    setDataFiltro({ ...dataFiltro, filtro });
  };

  const handleLimpiar = () => {
    setFolio(null);
    setTipoNot("");
    let filtro = {
      folio: null,
      tipo: null,
    };
    setDataFiltro({ ...dataFiltro, filtro });
    clearTipoNot();
  };

  useEffect(() => {
    if (!folio && tipoNot.length === 0) {
      setCleanDisabled(true);
    } else {
      setCleanDisabled(false);
    }
    if (folio || tipoNot.length !== 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [folio, tipoNot]);

  return (
    <div className="card-box">
      <Grid
        sx={{ padding: "5px 15px 5px 8px", margin: "0", width: "100%" }}
        container
        spacing={2}
        data-testid="selectFilter"
      >
        <Grid item xs={6}>
          <p className="labelForm">Nro de folio</p>
          <FormControl sx={{ width: "99%", margin: "0 1% 0 0" }}>
            <ThemeTextField
              fullWidth
              placeholder="Ej: 000001001"
              value={folio === null ? "" : folio}
              onChange={(e) =>
                setFolio((v) => (e.target.validity.valid ? e.target.value : v))
              }
              inputProps={{
                maxLength: 9,
                pattern: "[0-9]*",
                "data-testid": "FolioFun",
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <p className="labelForm">Tipo de Notificación</p>
          <FormControl sx={{ width: "99%", margin: "0 1% 0 0" }}>
            <Autocomplete
              ref={autoC}
              value={tipoNot.id}
              onChange={(event, newValue) => {
                handleTipoNot(newValue);
              }}
              multiple
              limitTags={3}
              clearText="Borrar"
              noOptionsText="No hay opciones."
              options={tipoNotif}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.id.toString()}
              renderOption={(props, option) => (
                <li {...props}>{option.id + " - " + option.text}</li>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <ThemeTextField {...params} label="" placeholder="Selecciona" />
              )}
              data-testid="autocomplete"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            sx={{ margin: "0", padding: "7px 0px 0" }}
          >
            <SecondaryButton
              sx={{ marginRight: "19px" }}
              onClick={() => handleLimpiar()}
              data-testid="cleanFilters"
              disabled={cleanDisabled}
            >
              Limpiar Filtros
            </SecondaryButton>
            <PrimaryButton
              sx={{ marginRight: "9px" }}
              onClick={() => filtroRenot()}
              data-testid="btnSearch"
              disabled={isDisabled}
            >
              Buscar Funes
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchRenot;
