import React, { useState } from "react";

const Context = React.createContext<{
  dataRemesa?: any;
  setDataRemesa?: React.Dispatch<any>;
  estadoRemesaContext?: any;
  setEstadoRemesaContext?: React.Dispatch<any>;
  fechaRemesa?: any;
  setFechaRemesa?: React.Dispatch<any>;
  remesaCerrada?: any;
  setRemesaCerrada?: React.Dispatch<any>;
  remesa?: any;
  setRemesa?: React.Dispatch<any>;
  dataFiltro?: any;
  setDataFiltro?: React.Dispatch<any>;
  uploadComplete?: any;
  setUploadComplete?: React.Dispatch<any>;
  modalCarga?: any;
  setModalCarga?: React.Dispatch<any>;
  dataFiltroEmpresas?: any;
  setDataFiltroEmpresas?: React.Dispatch<any>;
  rutEmpresa?: any;
  setRutEmpresa?: React.Dispatch<any>;
}>({});

export function RemesaContextProvider({ children }) {
  const [dataRemesa, setDataRemesa] = useState([]);
  const [estadoRemesaContext, setEstadoRemesaContext] = useState("0");
  const [fechaRemesa, setFechaRemesa] = useState([]);
  const [remesaCerrada, setRemesaCerrada] = useState([false]);
  const [remesa, setRemesa] = useState([false]);
  const [dataFiltro, setDataFiltro] = useState([]);
  const [uploadComplete, setUploadComplete] = useState([false]);
  const [modalCarga, setModalCarga] = useState([false]);
  const [dataFiltroEmpresas, setDataFiltroEmpresas] = useState([]);
  const [rutEmpresa, setRutEmpresa] = useState(["0"]);
  return (
    <Context.Provider
      value={{
        dataRemesa,
        setDataRemesa,
        estadoRemesaContext,
        setEstadoRemesaContext,
        fechaRemesa,
        setFechaRemesa,
        remesaCerrada,
        setRemesaCerrada,
        remesa,
        setRemesa,
        dataFiltro,
        setDataFiltro,
        uploadComplete,
        setUploadComplete,
        modalCarga,
        setModalCarga,
        dataFiltroEmpresas,
        setDataFiltroEmpresas,
        rutEmpresa,
        setRutEmpresa,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
