/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TableTooltip from "../Table/TableTooltip";
import { useLazyQuery } from "@apollo/client";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import moment from "moment";
import { HISTORICO_FUNES, PDF_FUN } from "../../graphql/queries";
import SkeletonHistorial from "./SkeletonHistorial";
import TooltipTipoFun from "../Table/TooltipTipoFun";
import ResultadoTablas from "../ResultadoTablas/ResultadoTablas";
import TagEstado from "../TagEstado/TagEstado";
import { maxTextLength, toTitleCase } from "../../utils/StringUtils";
import "../Material/material.scss";
import "./HistoricoFunes.scss";
import {} from "../select/CustomSelect";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import {
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Paginator from "../Paginator/Paginator";
import ModalHistorial from "./ModalHistorial";

const HistoricoFunes = () => {
  const [row, setRow] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const { dataFiltro } = useContext(RemesaContext);

  const [folio, setFolio] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [accion, setAccion] = useState({
    folioFun: null,
    estado: null,
  });

  const [getDataFiltro, { data, loading, error }] = useLazyQuery(
    HISTORICO_FUNES,
    {
      variables: {
        offset: currentPage * row - row,
        limit: row,
        historico: 1,
        countHistorico: 1,
        tipoNotificacion: null,
        fkCanalNotificacion: null,
        estadoFun: null,
        tipoRechazo: null,
        razonSocial: null,
        rutAfiliado: null,
        folioFun: null,
        rutEmpresa: null,
        anioSubcripcion: null,
        mesSubcripcion: null,
        data: "Historico",
        countRutEmpresa: null,
        countFolioFun: null,
        countRutAfiliado: null,
        countFkCanalNotificacion: null,
        countTipoNotificacion: null,
        countEstadoFun: null,
        countTipoRechazo: null,
        countAnioSubcripcion: null,
        countMesSubcripcion: null,
      },
    }
  );

  const [getDataPdf] = useLazyQuery(PDF_FUN);

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  const handleClick = async (folio: number) => {
    const { data: dataPdf } = await getDataPdf({
      variables: {
        folio: folio,
      },
    });
    window.open(dataPdf?.getUrlPdf?.url, "_blank");
  };

  const handleClickMenu = (event, folioFun: number, estado: string) => {
    setAnchorEl(event.currentTarget);
    setAccion({ ...accion, folioFun, estado });
  };

  const handleClickModal = (folioFun: number) => {
    setFolio(folioFun);
    setShowModal(true);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const aplicaFiltro = async () => {
    setCurrentPage(1);
    await getDataFiltro({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        historico: 1,
        countHistorico: 1,
        tipoNotificacion: dataFiltro?.filter?.tipo || null,
        fkCanalNotificacion: dataFiltro?.filter?.operador || null,
        estadoFun: dataFiltro?.filter?.estado || null,
        tipoRechazo: dataFiltro?.filter?.rechazo || null,
        razonSocial: dataFiltro?.filter?.empresa || null,
        rutAfiliado: dataFiltro?.filter?.rutAfiliado
          ? dataFiltro?.filter?.rutAfiliado.replaceAll(".", "")
          : null,
        folioFun: parseInt(dataFiltro?.filter?.folioFun) || null,
        rutEmpresa: dataFiltro?.filter?.rutEmpresa
          ? dataFiltro?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        anioSubcripcion: dataFiltro?.filter?.anio || null,
        mesSubcripcion: dataFiltro?.filter?.mes || null,
        data: "Historico",
        countRutEmpresa: dataFiltro?.filter?.rutEmpresa
          ? dataFiltro?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        countFolioFun: parseInt(dataFiltro?.filter?.folioFun) || null,
        countRutAfiliado: dataFiltro?.filter?.rutAfiliado
          ? dataFiltro?.filter?.rutAfiliado.replaceAll(".", "")
          : null,
        countFkCanalNotificacion: dataFiltro?.filter?.operador || null,
        countTipoNotificacion: dataFiltro?.filter?.tipo || null,
        countEstadoFun: dataFiltro?.filter?.estado || null,
        countTipoRechazo: dataFiltro?.filter?.rechazo || null,
        countAnioSubcripcion: dataFiltro?.filter?.anio || null,
        countMesSubcripcion: dataFiltro?.filter?.mes || null,
      },
    });
  };

  const buscarDatos = () => {
    getDataFiltro({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        historico: 1,
        countHistorico: 1,
        tipoNotificacion: dataFiltro?.filter?.tipo || null,
        fkCanalNotificacion: dataFiltro?.filter?.operador || null,
        estadoFun: dataFiltro?.filter?.estado || null,
        tipoRechazo: dataFiltro?.filter?.rechazo || null,
        razonSocial: dataFiltro?.filter?.empresa || null,
        rutAfiliado: dataFiltro?.filter?.rutAfiliado
          ? dataFiltro?.filter?.rutAfiliado.replaceAll(".", "")
          : null,
        folioFun: parseInt(dataFiltro?.filter?.folioFun) || null,
        rutEmpresa: dataFiltro?.filter?.rutEmpresa
          ? dataFiltro?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        anioSubcripcion: dataFiltro?.filter?.anio || null,
        mesSubcripcion: dataFiltro?.filter?.mes || null,
        data: "Historico",
        countRutEmpresa: dataFiltro?.filter?.rutEmpresa
          ? dataFiltro?.filter?.rutEmpresa.replaceAll(".", "")
          : null,
        countFolioFun: parseInt(dataFiltro?.filter?.folioFun) || null,
        countRutAfiliado: dataFiltro?.filter?.rutAfiliado
          ? dataFiltro?.filter?.rutAfiliado.replaceAll(".", "")
          : null,
        countFkCanalNotificacion: dataFiltro?.filter?.operador || null,
        countTipoNotificacion: dataFiltro?.filter?.tipo || null,
        countEstadoFun: dataFiltro?.filter?.estado || null,
        countTipoRechazo: dataFiltro?.filter?.rechazo || null,
        countAnioSubcripcion: dataFiltro?.filter?.anio || null,
        countMesSubcripcion: dataFiltro?.filter?.mes || null,
      },
    });
  };

  useEffect(() => {
    getDataFiltro();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    buscarDatos();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    buscarDatos();
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    aplicaFiltro();
    // eslint-disable-next-line
  }, [dataFiltro]);

  if (loading)
    return (
      <Container className="content2">
        <SkeletonHistorial />
      </Container>
    );

  return (
    <div className="card-box">
      {error || data?.getFunes === null ? (
        <div className="remesa-box tabla sin-remesa">Error cargando data.</div>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="estadosDivGroup">
                <p className="labelForm2">Historial de funes</p>
                {data?.countFunes !== null && (
                  <div className="estadosDiv">
                    <TagEstado
                      data={data?.countFunes}
                      text={" en total:"}
                      estilo={"estado total"}
                    ></TagEstado>
                    <TagEstado
                      data={data?.getTags[0]}
                      text={"Aceptados"}
                      estilo={"estado aceptado"}
                    ></TagEstado>
                    <TagEstado
                      data={data?.getTags[1]}
                      text={"Rechazados"}
                      estilo={"estado rechazado"}
                    ></TagEstado>
                    <TagEstado
                      data={data?.getTags[2]}
                      text={"Por enviar"}
                      estilo={"estado porenviar"}
                    ></TagEstado>
                    <TagEstado
                      data={data?.getTags[3]}
                      text={"Enviados"}
                      estilo={"estado enviado"}
                    ></TagEstado>
                    <TagEstado
                      data={data?.getTags[4]}
                      text={"Sin remesa asignada"}
                      estilo={"estado sincanal"}
                    ></TagEstado>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          {data?.getFunes.length === 0 && dataFiltro.length !== 0 ? (
            <ResultadoTablas
              titulo="No encontramos coincidencias"
              subtitulo="No hay funes en el historial con las características que seleccionaste."
              icono="icon-info"
              boton={false}
            />
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="headerTableIzquierdaSmall">
                        Folio
                      </StyledTableCell>
                      <StyledTableCell>Estado</StyledTableCell>
                      <StyledTableCell>Fecha Últ. Not</StyledTableCell>
                      <StyledTableCell>Fecha de carga</StyledTableCell>
                      <StyledTableCell>RUT Afiliado</StyledTableCell>
                      <StyledTableCell>RUT Empresa</StyledTableCell>
                      <StyledTableCell>
                        Tipo Notificación <TooltipTipoFun />
                      </StyledTableCell>
                      <StyledTableCell>Operador</StyledTableCell>
                      <StyledTableCell>Región</StyledTableCell>
                      <StyledTableCell>Motivo rechazo</StyledTableCell>
                      <StyledTableCell
                        sx={{ maxWidth: "100px" }}
                        align="center"
                        className="headerTableAcciones3"
                      >
                        Acciones
                      </StyledTableCell>
                      {/* <StyledTableCell className="headerTableAccionesSmall">
                        Ver
                      </StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.getFunes.map((e) => (
                      <StyledTableRow key={e.folioFun}>
                        <TableCell className="headerTableIzquierdaSmall">
                          {e.folioFun}
                        </TableCell>
                        <TableCell>
                          {e.estado_fun === null ? (
                            ""
                          ) : (
                            <>
                              {e.estado_fun === "Por Enviar" && (
                                <p className="estado porenviar">
                                  {e.estado_fun}
                                </p>
                              )}
                              {e.estado_fun === "Enviado" && (
                                <p className="estado enviado">{e.estado_fun}</p>
                              )}
                              {e.estado_fun === "Rechazado" && (
                                <p className="estado rechazado">
                                  {e.estado_fun}
                                </p>
                              )}
                              {e.estado_fun === "Aceptado" && (
                                <p className="estado aceptado">
                                  {e.estado_fun}
                                </p>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {e.fecha_envio === null
                            ? " "
                            : moment(e.fecha_envio).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {e.fecha_contratacion === null
                            ? " "
                            : e.fecha_contratacion
                                .split("-")
                                .reverse()
                                .join("/")}
                        </TableCell>
                        <TableCell className="WhiteNowrap celdaName">
                          {e.afiliado_rut}
                          <div className="Nametd">
                            {e.afiliado_nombre_completo?.length > 40 ? (
                              <TableTooltip
                                rut={maxTextLength(
                                  toTitleCase(e.afiliado_nombre_completo),
                                  37
                                )}
                                tooltip={toTitleCase(
                                  e.afiliado_nombre_completo
                                )}
                              />
                            ) : (
                              toTitleCase(e.afiliado_nombre_completo)
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="WhiteNowrap celdaName Nametd">
                          {e.empresa_rut}
                          <div className="Nametd">
                            {e.empresa_razon_social?.length > 30 ? (
                              <TableTooltip
                                rut={maxTextLength(
                                  toTitleCase(e.empresa_razon_social),
                                  27
                                )}
                                tooltip={toTitleCase(e.empresa_razon_social)}
                              />
                            ) : (
                              toTitleCase(e.empresa_razon_social)
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{e.tipoNotificacion}</TableCell>
                        <TableCell>{e.canal_notificacion}</TableCell>
                        <TableCell>{e.region}</TableCell>
                        <TableCell>
                          {e.motivo_rechazo === null
                            ? " "
                            : toTitleCase(e.motivo_rechazo)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="headerTableAcciones3"
                        >
                          <MoreVertIcon
                            onClick={(event) =>
                              handleClickMenu(event, e.folioFun, e.estado_fun)
                            }
                            id={e.folioFun}
                            sx={{ cursor: "pointer" }}
                            data-testid="btnMenu"
                          />

                          <Menu
                            className="menu-box"
                            sx={{
                              boxShadow: "1px 2px 7px 0px #e3e3e3!important",
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            <MenuItem
                              onClick={() => handleClickModal(accion.folioFun)}
                              data-testid="btnUpdate"
                              sx={{ padding: "20px" }}
                              disabled={accion.estado == null}
                            >
                              Ver historial FUN
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleClick(accion.folioFun)}
                              data-testid="btnPDF"
                            >
                              Ver PDF
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginator
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={parseInt(data?.countFunes) || 0}
                rowsPerPage={row}
                page={currentPage - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ mt: "32px", mb: "10px" }}
                labelRowsPerPage={"Mostrar por página"}
                labelDisplayedRows={(e) => {
                  return (
                    "" +
                    currentPage +
                    " de " +
                    Math.ceil((data?.countFunes || 0) / row)
                  );
                }}
              />
              <ModalHistorial
                folioFun={folio}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HistoricoFunes;
