import React, { FC, useEffect, useState } from "react";

type Props = {
  data: any;
  text: string;
  estilo: string;
};

const TagEstado: FC<Props> = ({ data, text, estilo }) => {
  const [contenido, setContenido] = useState(null);

  useEffect(() => {
    setContenido(data + " " + text);
    // eslint-disable-next-line
  }, [data, text]);

  return (
    <>
      <span className={estilo}>{contenido}</span>
    </>
  );
};

export default TagEstado;
