/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import { useEffect, useState, useContext } from "react";
import { Container, Spinner, Button, Modal, Form } from "react-bootstrap";
import TableTooltip from "./TableTooltip";
import TooltipTipoFun from "./TooltipTipoFun";
import CanalSelectDropdown from "../Table/CanalSelectDropdown";
import { useMutation, useLazyQuery } from "@apollo/client";
import DataContext from "../Context/ContextSearch";
import { UPDATE_CANAL_MULTIPLE } from "../../graphql/mutations";
import { FUNES_SIN_REMESA } from "../../graphql/queries";
import SkeletonHistorial from "../Historial/SkeletonHistorial";
import ModalDrag from "../DragDrop/components/ModalDrag";
import "./Table.scss";
import "../Material/material.scss";
import ResultadoTablas from "../ResultadoTablas/ResultadoTablas";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TagEstado from "../TagEstado/TagEstado";
import { maxTextLength, toTitleCase } from "../../utils/StringUtils";
import Paginator from "../Paginator/Paginator";
import ToastMaterial from "../toast/ToastMaterial";

const Tabla = () => {
  const [row, setRow] = useState(20);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [renderizado, setRenderizado] = useState(false);
  const [actualizarTabla, setActualizarTabla] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [canal, setCanal] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { dataFiltro, uploadComplete, setUploadComplete } =
    useContext(DataContext);
  const [getData, { data, loading, error }] = useLazyQuery(FUNES_SIN_REMESA, {
    variables: {
      offset: currentPage * row - row,
      limit: 20,
      folioFun: null,
      fkCanalNotificacion: null,
      tipoNotificacion: null,
      rutAfiliado: null,
      rutEmpresa: null,
      fechaFirma: null,
      remesaAsignada: 0,
      countRutEmpresa: null,
      countFolioFun: null,
      countRutAfiliado: null,
      countFechaFirma: null,
      countFkCanalNotificacion: null,
      countRemesaAsignada: 0,
      data: "Sin Canal",
    },
  });

  const [addCanal, { data: dataUpdate }] = useMutation(UPDATE_CANAL_MULTIPLE, {
    onCompleted: () => {
      setShowToast(true);
      setSpinner(false);
      setCanal(0);
      resetCheck();
      handleClose();
      setUpdateTable(true);
    },
  });

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  function handleChange2(e) {
    setCanal(e.target.value);
  }

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data?.getFunes.map((e) => e.folioFun.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheck = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(
        isCheck.filter(function (i) {
          return i !== id;
        })
      );
    }
  };

  const resetCheck = () => {
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const closeModal = () => {
    handleClose();
    setCanal(0);
  };

  const asignarCanalesMultiple = () => {
    setSpinner(true);
    let fkCanalNotificacion: number;
    if (canal == 1) {
      fkCanalNotificacion = 1;
    } else if (canal == 2) {
      fkCanalNotificacion = 2;
    } else if (canal == 3) {
      fkCanalNotificacion = 3;
    }
    let vec = [];
    for (let index = 0; index < isCheck.length; index++) {
      vec.push(parseInt(isCheck[index]));
    }
    addCanal({
      variables: {
        vectorId: vec,
        fkCanalNotificacion: fkCanalNotificacion,
      },
    });
  };

  const aplicaFiltro = async () => {
    setCurrentPage(1);
    await getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        remesaAsignada: 0,
        countRemesaAsignada: 0,
        folioFun: dataFiltro?.filtro?.folioFun
          ? parseInt(dataFiltro?.filtro?.folioFun)
          : null,
        fkCanalNotificacion: dataFiltro?.filtro?.operador
          ? parseInt(dataFiltro?.filtro?.operador)
          : null,
        tipoNotificacion: dataFiltro?.filtro?.tipo
          ? dataFiltro?.filtro?.tipo
          : null,
        rutAfiliado: dataFiltro?.filtro?.rutAfiliado
          ? dataFiltro?.filtro?.rutAfiliado.replaceAll(".", "")
          : null,
        rutEmpresa: dataFiltro?.filtro?.rutEmpresa
          ? dataFiltro?.filtro?.rutEmpresa.replaceAll(".", "")
          : null,
        fechaFirma: dataFiltro?.filtro?.fecha
          ? dataFiltro?.filtro?.fecha
          : null,
        countRutEmpresa: dataFiltro?.filtro?.rutEmpresa
          ? dataFiltro?.filtro?.rutEmpresa.replaceAll(".", "")
          : null,
        countFolioFun: dataFiltro?.filtro?.folioFun
          ? parseInt(dataFiltro?.filtro?.folioFun)
          : null,
        countRutAfiliado: dataFiltro?.filtro?.rutAfiliado
          ? dataFiltro?.filtro?.rutAfiliado.replaceAll(".", "")
          : null,
        countFechaFirma: dataFiltro?.filtro?.fecha
          ? dataFiltro?.filtro?.fecha
          : null,
        countFkCanalNotificacion: dataFiltro?.filtro?.operador
          ? parseInt(dataFiltro?.filtro?.operador)
          : null,
        data: "Sin Canal",
      },
    });
  };

  const buscarDatos = () => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        remesaAsignada: 0,
        countRemesaAsignada: 0,
        folioFun: dataFiltro?.filtro?.folioFun
          ? parseInt(dataFiltro?.filtro?.folioFun)
          : null,
        fkCanalNotificacion: dataFiltro?.filtro?.operador
          ? parseInt(dataFiltro?.filtro?.operador)
          : null,
        tipoNotificacion: dataFiltro?.filtro?.tipo
          ? parseInt(dataFiltro?.filtro?.tipo)
          : null,
        rutAfiliado: dataFiltro?.filtro?.rutAfiliado
          ? dataFiltro?.filtro?.rutAfiliado.replaceAll(".", "")
          : null,
        rutEmpresa: dataFiltro?.filtro?.rutEmpresa
          ? dataFiltro?.filtro?.rutEmpresa.replaceAll(".", "")
          : null,
        fechaFirma: dataFiltro?.filtro?.fecha
          ? dataFiltro?.filtro?.fecha
          : null,
        countRutEmpresa: dataFiltro?.filtro?.rutEmpresa
          ? dataFiltro?.filtro?.rutEmpresa.replaceAll(".", "")
          : null,
        countFolioFun: dataFiltro?.filtro?.folioFun
          ? parseInt(dataFiltro?.filtro?.folioFun)
          : null,
        countRutAfiliado: dataFiltro?.filtro?.rutAfiliado
          ? dataFiltro?.filtro?.rutAfiliado.replaceAll(".", "")
          : null,
        countFechaFirma: dataFiltro?.filtro?.fecha
          ? dataFiltro?.filtro?.fecha
          : null,
        countFkCanalNotificacion: dataFiltro?.filtro?.operador
          ? parseInt(dataFiltro?.filtro?.operador)
          : null,
        data: "Sin Canal",
      },
    });
  };

  useEffect(() => {
    getData();
    setRenderizado(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    buscarDatos();
    setRenderizado(true);
    resetCheck();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    buscarDatos();
    setRenderizado(true);
    resetCheck();
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    setUpdateTable(false);
    setRenderizado(true);
    buscarDatos();
    // eslint-disable-next-line
  }, [updateTable]);

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        folioFun: null,
        fkCanalNotificacion: null,
        tipoNotificacion: null,
        rutAfiliado: null,
        rutEmpresa: null,
        fechaFirma: null,
        countRutEmpresa: null,
        countFolioFun: null,
        countRutAfiliado: null,
        countFechaFirma: null,
        countFkCanalNotificacion: null,
        remesaAsignada: 0,
        countRemesaAsignada: 0,
        data: "Sin Canal",
      },
    });
    setRenderizado(true);
    setUploadComplete(false);
    // eslint-disable-next-line
  }, [uploadComplete]);

  useEffect(() => {
    aplicaFiltro();
    setRenderizado(true);
    resetCheck();
    // eslint-disable-next-line
  }, [dataFiltro]);

  useEffect(() => {
    if (actualizarTabla) {
      getData();
      setRenderizado(true);
    }
    setActualizarTabla(false);
    // eslint-disable-next-line
  }, [actualizarTabla]);

  if (loading)
    return (
      <Container className="content2">
        <SkeletonHistorial></SkeletonHistorial>
      </Container>
    );

  return (
    <Container className="content2">
      <div className="containerTable">
        {error || data?.getFunes === null ? (
          <div className="remesa-box tabla sin-remesa">
            Error cargando data.
          </div>
        ) : (
          <>
            {data?.getFunes.length === 0 && dataFiltro.length === 0 ? (
              <ResultadoTablas
                titulo={"Empecemos por cargar un archivo"}
                subtitulo={
                  "No tienes datos que gestionar aún. Carga un documento excel desde tu computadora para comenzar."
                }
                icono={"icon-info"}
                boton={true}
              />
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="estadosDivGroup">
                      <p className="labelForm2">Funes por enviar</p>
                      {data?.countFunes !== 0 && (
                        <>
                          <div className="estadosDiv">
                            <TagEstado
                              data={data?.countFunes}
                              text={" en total:"}
                              estilo={"estado total"}
                            ></TagEstado>
                            <TagEstado
                              data={data?.getTags[0]}
                              text={"sin canal asignado"}
                              estilo={"estado sincanal"}
                            ></TagEstado>
                          </div>
                        </>
                      )}
                    </div>
                    <ModalDrag
                      textButton={"Cargar funes"}
                      modalTitle={"Gestión de Funes"}
                      type={"fun"}
                      rowRemesa={null}
                      canalRemesa={null}
                      disabled={false}
                      setShowModal={setShowModal}
                    />
                  </Grid>
                  {data?.getFunes.length === 0 && dataFiltro.length !== 0 ? (
                    <ResultadoTablas
                      titulo={"No encontramos coincidencias"}
                      subtitulo={
                        "No hay funes que gestionar con las características que seleccionaste."
                      }
                      icono={"icon-info"}
                      boton={false}
                    />
                  ) : (
                    <>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell sx={{ maxWidth: "10px" }}>
                              <Checkbox
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              />
                            </StyledTableCell>
                            <StyledTableCell>Funes</StyledTableCell>
                            <StyledTableCell>Fecha Firma Fun</StyledTableCell>
                            <StyledTableCell>Rut Afiliado</StyledTableCell>
                            <StyledTableCell>Rut Empresa</StyledTableCell>
                            <StyledTableCell>
                              Tipo Notificación <TooltipTipoFun />
                            </StyledTableCell>
                            <StyledTableCell>Operador</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.getFunes.map((e) => (
                            <StyledTableRow
                              key={e.folioFun}
                              data-testid="tableItem"
                            >
                              <TableCell>
                                <Checkbox
                                  id={e.folioFun.toString()}
                                  onChange={handleCheck}
                                  checked={isCheck.includes(
                                    e.folioFun.toString()
                                  )}
                                />
                              </TableCell>
                              <TableCell>{e.folioFun}</TableCell>
                              <TableCell>
                                {e.fecha_contratacion
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </TableCell>
                              <TableCell className="WhiteNowrap celdaName">
                                {e.afiliado_rut}
                                <div className="Nametd">
                                  {e.afiliado_nombre_completo?.length > 44 ? (
                                    <TableTooltip
                                      rut={maxTextLength(
                                        toTitleCase(e.afiliado_nombre_completo),
                                        41
                                      )}
                                      tooltip={toTitleCase(
                                        e.afiliado_nombre_completo
                                      )}
                                    />
                                  ) : (
                                    toTitleCase(e.afiliado_nombre_completo)
                                  )}
                                </div>
                              </TableCell>
                              <TableCell className="WhiteNowrap celdaName Nametd">
                                {e.empresa_rut}
                                <div className="Nametd">
                                  {e.empresa_razon_social?.length > 36 ? (
                                    <TableTooltip
                                      rut={maxTextLength(
                                        toTitleCase(e.empresa_razon_social),
                                        33
                                      )}
                                      tooltip={toTitleCase(
                                        e.empresa_razon_social
                                      )}
                                    />
                                  ) : (
                                    toTitleCase(e.empresa_razon_social)
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>{e.tipoNotificacion}</TableCell>
                              <TableCell>
                                <CanalSelectDropdown
                                  id={e.folioFun}
                                  canalDetector={e.canal_notificacion}
                                  renderizado={renderizado}
                                  setUpdateTable={setUpdateTable}
                                />
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Paginator
                        data-testid="selectNumberTableRows"
                        component="div"
                        rowsPerPageOptions={[10, 20, 50]}
                        count={parseInt(data?.countFunes) || 0}
                        rowsPerPage={row}
                        page={currentPage - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ mt: "32px", mb: "10px" }}
                        labelRowsPerPage={"Mostrar por página"}
                        labelDisplayedRows={(e) => {
                          return (
                            "" +
                            currentPage +
                            " de " +
                            Math.ceil((data?.countFunes || 0) / row)
                          );
                        }}
                      />
                    </>
                  )}
                </TableContainer>

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  className="modal-selecciona-canal-masivo"
                >
                  <Modal.Header>
                    <h4>Selecciona un canal</h4>
                  </Modal.Header>
                  <Modal.Body>
                    {spinner ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          animation="border"
                          variant="success"
                          role="status"
                          data-testid="loaderSpinner"
                        ></Spinner>
                        <p className="d-flex align-items-center">
                          Actualizando Datos
                        </p>
                      </div>
                    ) : (
                      <Form.Select
                        data-testid="selectChannel"
                        onChange={handleChange2}
                        value={canal}
                      >
                        <option defaultValue="0" hidden>
                          Seleccionar canal
                        </option>
                        <option value="1">Previred</option>
                        <option value="2">X-Data</option>
                        <option value="3">Manual</option>
                      </Form.Select>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={closeModal}
                      className="btn-cerrar"
                    >
                      Cerrar
                    </Button>
                    <Button
                      className="btn-funes"
                      onClick={asignarCanalesMultiple}
                      data-testid="btnAssignMultipleChannel"
                      disabled={canal == 0 ? true : false}
                    >
                      Asignar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </>
        )}
      </div>
      {isCheck.length > 1 ? (
        <div className=" btn-float ">
          <ul>
            <li>
              <p data-testid="selectedItems">Seleccionados: {isCheck.length}</p>
            </li>
            <li>
              <Button
                className="btn-asignar icon-edit"
                onClick={handleShow}
                data-testid="buttonAssignChannel"
              >
                Asignar Canal
              </Button>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
      <ToastMaterial
        message={dataUpdate?.updateChanelMulti?.message}
        showToast={showToast}
        className={
          dataUpdate?.updateChanelMulti?.code === "01" ? "success" : "error"
        }
        setShowToast={setShowToast}
      />
    </Container>
  );
};

export default Tabla;
