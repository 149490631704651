import { FC } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { IconCheckSnack, IconErrorSnack } from "../../Images/iconosEsencial";
import "../../components/toast/ToastMaterial.scss";
import { Snackbar } from "@mui/material";

type ToastProps = {
  message: string;
  showToast: boolean;
  className: string;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ToastMaterial: FC<ToastProps> = (props) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    props.setShowToast(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {props.className === "error" ? (
        <Snackbar
          open={props.showToast}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            severity="error"
            className="alertSnack"
            sx={{
              border: "1px solid #E57373",
              backgroundColor: "#FFEBEE",
            }}
            iconMapping={{
              error: <IconErrorSnack fontSize="inherit" />,
            }}
          >
            {props.message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={props.showToast}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            severity="success"
            className="alertSnack"
            sx={{
              border: "1px solid #7CB342",
              backgroundColor: "#F1F8E9",
            }}
            iconMapping={{
              success: <IconCheckSnack fontSize="inherit" />,
            }}
          >
            {props.message}
          </Alert>
        </Snackbar>
      )}
      {/* <Alert
        severity="info"
        className="alertSnack"
        sx={{
          border: "1px solid #978EE6",
          backgroundColor: "#EEECFB",
        }}
        iconMapping={{
          info: <IconInfoSnack fontSize="inherit" />,
        }}
        onClose={() => {}}
      >
        Texto
      </Alert> */}
    </Stack>
  );
};

export default ToastMaterial;
