import React, { useState } from "react";

const Context = React.createContext<{
  loader?: any;
  setLoader?: React.Dispatch<any>;
  filename?: any;
  setFilename?: React.Dispatch<any>;
}>({});

export function NotifContextProvider({ children }) {
  // const [loader, setLoader] = useState([false]);
  const [loader, setLoader] = useState(false);
  const [filename, setFilename] = useState([]);
  return (
    <Context.Provider value={{ loader, setLoader, filename, setFilename }}>
      {children}
    </Context.Provider>
  );
}
export default Context;
