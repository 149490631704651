import { gql } from "@apollo/client";

export const HISTORICO_FUNES = gql`
  query GetFunes(
    $offset: Int
    $limit: Int
    $tipoNotificacion: String
    $fkCanalNotificacion: Int
    $estadoFun: String
    $tipoRechazo: Int
    $razonSocial: String
    $rutAfiliado: String
    $folioFun: Int
    $rutEmpresa: String
    $anioSubcripcion: Int
    $mesSubcripcion: Int
    $historico: Int
    $countHistorico: Int
    $data: String!
    $countRutEmpresa: String
    $countFolioFun: Int
    $countRutAfiliado: String
    $countFkCanalNotificacion: Int
    $countTipoNotificacion: String
    $countEstadoFun: String
    $countTipoRechazo: Int
    $countAnioSubcripcion: Int
    $countMesSubcripcion: Int
  ) {
    getFunes(
      offset: $offset
      limit: $limit
      tipo_notificacion: $tipoNotificacion
      fkCanalNotificacion: $fkCanalNotificacion
      estado_fun: $estadoFun
      tipo_rechazo: $tipoRechazo
      razon_social: $razonSocial
      rutAfiliado: $rutAfiliado
      folioFun: $folioFun
      rutEmpresa: $rutEmpresa
      anio_subcripcion: $anioSubcripcion
      mes_subcripcion: $mesSubcripcion
      historico: $historico
    ) {
      folioFun
      periodo_renuneracion_anio
      periodo_remuneracion_mes
      tipoNotificacion
      fecha_contratacion
      afiliado_rut
      afiliado_nombre_completo
      afiliado_genero
      afiliado_comuna
      region
      empresa_razon_social
      empresa_rut
      canal_notificacion
      remesas
      estado_fun
      motivo_rechazo
      fecha_envio
    }
    countFunes(
      countHistorico: $countHistorico
      countRutEmpresa: $countRutEmpresa
      countFolioFun: $countFolioFun
      countRutAfiliado: $countRutAfiliado
      countFkCanalNotificacion: $countFkCanalNotificacion
      countTipo_notificacion: $countTipoNotificacion
      countEstado_fun: $countEstadoFun
      countTipo_rechazo: $countTipoRechazo
      countAnio_subcripcion: $countAnioSubcripcion
      countMes_subcripcion: $countMesSubcripcion
    )
    getTags(data: $data)
  }
`;

export const DOWNLOAD_REMESA_XDATA = gql`
  query GetXData($idRemesa: Int!) {
    getXData(id_remesa: $idRemesa) {
      folio
      fecha_emision
      nro_nomina
      origen
      rut_org
      digito_verificador
      rut_afiliado
      digito_verificador_afiliado
      nombre
      apell_pat
      apell_mat
      monto_total
      periodo_remuneracion
      detalle_monto_pesos
      detalle_monto_uf
      detalle_monto_porcentaje
      detalle_ges
      tipo_notificacion
      archivo
      url
      contrato_id
      valor_contrato
      tipo_relacion
      tipoFun
    }
  }
`;
export const DOWNLOAD_REMESA_MANUAL = gql`
  query GetManual($idRemesa: Int!) {
    getManual(id_remesa: $idRemesa) {
      idRemesa
      folio
      tipo_fun
      fecha_Firma
      rut_empresa
      digito_verificador
      razon_social
      region_empresa
      ciudad_empresa
      comuna_empresa
      direccion_empresa
      telefono_empresa
      fecha_remesa
      estado
      codigo
      descripcion_rechazo
      url
      fecha_notificacion
      usuario_notificado
    }
  }
`;

export const DOWNLOAD_REMESA_PREVIRED = gql`
  query GetPreviRed($idRemesa: Int!) {
    getPreviRed(id_remesa: $idRemesa) {
      folio
      tipo_notificacion
      rut_afiliado
      digito_verificador_afiliado
      apell_pat
      apell_mat
      nombre
      rut_org
      digito_verificador
      codTotalPagarA
      modCotTotalPagarA
      codTotalPagarB
      modCotTotalPagarB
      nroInscPens
      archivo
      url
    }
  }
`;

export const DETALLE_REMESA = gql`
  query GetFunes(
    $offset: Int
    $limit: Int
    $countIdRemesa: Int
    $idRemesa: Int
    $remesaAsignada: Int
    $data: String!
    $getTagsIdRemesa2: Int
  ) {
    getFunes(
      remesaAsignada: $remesaAsignada
      id_remesa: $idRemesa
      limit: $limit
      offset: $offset
    ) {
      folioFun
      periodo_renuneracion_anio
      periodo_remuneracion_mes
      tipoNotificacion
      fecha_contratacion
      afiliado_rut
      afiliado_nombre_completo
      afiliado_genero
      afiliado_comuna
      region
      empresa_razon_social
      empresa_rut
      canal_notificacion
      remesas
      estado_fun
    }
    countFunes(countId_remesa: $countIdRemesa)
    getTags(data: $data, idRemesa: $getTagsIdRemesa2)
  }
`;

export const PAGINADOR_REMESA = gql`
  query GetRemesaPaginator($offset: Int, $limit: Int) {
    getRemesaPaginator(offset: $offset, limit: $limit) {
      remesa_id
      fecha_creacion
      fecha_envio
      fecha_cierre
      estado_remesa
      canal_notificacion
      cantida_funes
      region_id
    }
    countRemesas
  }
`;

export const FUNES_SIN_REMESA = gql`
  query Query(
    $folioFun: Int
    $fkCanalNotificacion: Int
    $tipoNotificacion: String
    $rutAfiliado: String
    $rutEmpresa: String
    $fechaFirma: String
    $offset: Int
    $limit: Int
    $remesaAsignada: Int
    $countRutEmpresa: String
    $countFolioFun: Int
    $countRutAfiliado: String
    $countFechaFirma: String
    $countFkCanalNotificacion: Int
    $countRemesaAsignada: Int
    $data: String!
  ) {
    getFunes(
      folioFun: $folioFun
      fkCanalNotificacion: $fkCanalNotificacion
      tipo_notificacion: $tipoNotificacion
      rutAfiliado: $rutAfiliado
      rutEmpresa: $rutEmpresa
      fechaFirma: $fechaFirma
      offset: $offset
      limit: $limit
      remesaAsignada: $remesaAsignada
    ) {
      folioFun
      tipoNotificacion
      fecha_contratacion
      afiliado_rut
      afiliado_nombre_completo
      empresa_rut
      empresa_razon_social
      canal_notificacion
      remesas
    }
    countFunes(
      countRutEmpresa: $countRutEmpresa
      countFolioFun: $countFolioFun
      countRutAfiliado: $countRutAfiliado
      countFechaFirma: $countFechaFirma
      countFkCanalNotificacion: $countFkCanalNotificacion
      countRemesaAsignada: $countRemesaAsignada
    )
    getTags(data: $data)
  }
`;

export const PDF_FUN = gql`
  query GetUrlPdf($folio: Int!) {
    getUrlPdf(folio: $folio) {
      url
    }
  }
`;

export const REPORTE_FUNES = gql`
  query GetReporte(
    $fechaInicial: String
    $fechaFinal: String
    $limit: Int
    $offset: Int
  ) {
    getReporte(
      fechaInicial: $fechaInicial
      fechaFinal: $fechaFinal
      limit: $limit
      offset: $offset
    ) {
      Folio_FUN
      Numero_de_Contrato
      Rut_Cotizante
      Nombre_cotizante
      Sexo
      tipo_relacion
      Cotizacion_Pactada
      Rut_Empleador
      Nombre_empleador
      Comuna_Empleador
      Region_Empleador
      Direccion_Empleador
      Tipo_Notificacion
      Nombre_Operador
      Fecha_Notificacion
      Estado_Notificacion
      Motivo_de_rechazo
      Isapre_Origen
      Origen_Venta
      Rut_Agente_Venta
      Nombre_Agente_Venta
      numeroRemesa
    }
    countReporte(fechaInicial: $fechaInicial, fechaFinal: $fechaFinal)
  }
`;

export const EMPRESAS_CONVENIO = gql`
  query getEmpleadores(
    $rut: String
    $operador: Int
    $razonSocial: String
    $offset: Int
    $limit: Int
    $countEmpleadoresRut2: String
    $countEmpleadoresOperador2: Int
    $countEmpleadoresRazonSocial2: String
  ) {
    getEmpleadores(
      rut: $rut
      operador: $operador
      razonSocial: $razonSocial
      offset: $offset
      limit: $limit
    ) {
      rut
      dv
      razonSocial
      operador
    }
    countEmpleadores(
      rut: $countEmpleadoresRut2
      operador: $countEmpleadoresOperador2
      razonSocial: $countEmpleadoresRazonSocial2
    )
  }
`;

export const EMPRESAS_DETALLE = gql`
  query GetEmpleador($rut: Int!) {
    getEmpleador(rut: $rut) {
      rut
      dv
      razonSocial
      calle
      numero
      depto
      telefono
      correo
      comuna
      region
      ciudad
      operador
    }
  }
`;

export const EMPRESAS_COMUNA = gql`
  query GetComunas($comuna: String) {
    getComunas(comuna: $comuna) {
      comuna_id
      comuna
    }
  }
`;

export const EMPRESAS_COMUNA_PROVINCIA_REGION = gql`
  query GetComunaProvinciaRegion($comunaId: Int) {
    getComunaProvinciaRegion(comunaID: $comunaId) {
      comuna_id
      provincia
      region
      comuna
    }
  }
`;

export const MOTIVO_RECHAZO = gql`
  query GetMotivoRechazo($canalNotificacion: Int) {
    getMotivoRechazo(canal_notificacion: $canalNotificacion) {
      motivo_rechazo_id
      codigo_operador
      descripcion
    }
  }
`;

export const HISTORIAL_CONVENIO = gql`
  query GetHistorial($offset: Int, $limit: Int) {
    getHistorial(offset: $offset, limit: $limit) {
      id
      nombre
      responsable
      createdAt
    }
    countHistorial
  }
`;

export const TIMEOUT_LAMBDA = gql`
  query GetTimeOutLamda($nombreArchivo: String) {
    getTimeOutLamda(nombreArchivo: $nombreArchivo) {
      code
      message
    }
  }
`;

export const FUNES_RENOT = gql`
  query GetRenotificacion(
    $folio: Int
    $tipoNotificacion: String
    $offset: Int
    $limit: Int
    $countRenotificacionFolio2: Int
    $countRenotificacionTipoNotificacion2: String
  ) {
    getRenotificacion(
      folio: $folio
      tipo_notificacion: $tipoNotificacion
      offset: $offset
      limit: $limit
    ) {
      folioFun
      afiliado_rut
      afiliado_nombre_completo
      empresa_rut
      empresa_razon_social
      tipoNotificacion
      fecha_contratacion
      estado
    }
    countRenotificacion(
      folio: $countRenotificacionFolio2
      tipo_notificacion: $countRenotificacionTipoNotificacion2
    )
  }
`;

export const HISTORICO = gql`
  query GetHistorico(
    $offset: Int
    $limit: Int
    $tipoNotificacion: String
    $fkCanalNotificacion: Int
    $estadoFun: String
    $tipoRechazo: Int
    $razonSocial: String
    $rutAfiliado: String
    $folioFun: Int
    $rutEmpresa: String
    $anioSubcripcion: Int
    $mesSubcripcion: Int
    $countHistorico: Int
    $data: String!
    $countRutEmpresa: String
    $countFolioFun: Int
    $countRutAfiliado: String
    $countFkCanalNotificacion: Int
    $countTipoNotificacion: String
    $countEstadoFun: String
    $countTipoRechazo: Int
    $countAnioSubcripcion: Int
    $countMesSubcripcion: Int
  ) {
    getHistorico(
      offset: $offset
      limit: $limit
      tipo_notificacion: $tipoNotificacion
      fkCanalNotificacion: $fkCanalNotificacion
      estado_fun: $estadoFun
      tipo_rechazo: $tipoRechazo
      razon_social: $razonSocial
      rutAfiliado: $rutAfiliado
      folioFun: $folioFun
      rutEmpresa: $rutEmpresa
      anio_subcripcion: $anioSubcripcion
      mes_subcripcion: $mesSubcripcion
    ) {
      folioFun
      periodo_renuneracion_anio
      periodo_remuneracion_mes
      tipoNotificacion
      fecha_contratacion
      afiliado_rut
      afiliado_nombre_completo
      afiliado_genero
      afiliado_comuna
      region
      empresa_razon_social
      empresa_rut
      canal_notificacion
      remesas
      estado_fun
      motivo_rechazo
      fecha_envio
    }
    countFunes(
      countHistorico: $countHistorico
      countRutEmpresa: $countRutEmpresa
      countFolioFun: $countFolioFun
      countRutAfiliado: $countRutAfiliado
      countFkCanalNotificacion: $countFkCanalNotificacion
      countTipo_notificacion: $countTipoNotificacion
      countEstado_fun: $countEstadoFun
      countTipo_rechazo: $countTipoRechazo
      countAnio_subcripcion: $countAnioSubcripcion
      countMes_subcripcion: $countMesSubcripcion
    )
    getTags(data: $data)
  }
`;

export const HISTORIAL_FUN = gql`
  query GetDetalleHistorico($folio: Int!) {
    getDetalleHistorico(folio: $folio) {
      idRemesa
      estadoRemesa
      estadoFun
      motivoRechazo
      FechaUltimaActualizacion
    }
  }
`;
