import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/material";

const Paginator = styled(TablePagination)((props: { component: "div" }) => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Interstate",
  "& .MuiInputBase-input": {
    border: "1px solid #BCC2C6",
    borderRadius: "8px",
    padding: "4px 8px 4px 12px",
    width: "22px",
    height: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:focus": {
      borderRadius: "8px",
      backgroundColor: "white",
    },
  },
}));

export default Paginator;
