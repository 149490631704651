import { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_RENOT } from "../../graphql/mutations";
import { Form } from "react-bootstrap";
import ToastMaterial from "../toast/ToastMaterial";

type EstadoProps = {
  folio: number;
  estado: string;
};

export const SelectRenot: FC<EstadoProps> = ({ folio, estado }) => {
  const [estadoRenot, setEstadoRenot] = useState(estado);
  const [showToast, setShowToast] = useState(false);
  const [updateRenot, { data: dataUpdate }] = useMutation(UPDATE_RENOT, {
    onCompleted: () => {
      setEstadoRenot(estadoAux);
      setShowToast(true);
    },
  });
  let estadoAux: string;

  const handleChange = (e) => {
    estadoAux = e.target.value;
    updateRenot({
      variables: {
        folio: folio,
        opcion: parseInt(e.target.value),
      },
    });
  };

  useEffect(() => {
    setEstadoRenot(estado);
    // eslint-disable-next-line
  }, [estado]);

  return (
    <>
      <Form.Select
        value={estadoRenot || ""}
        data-testid="selectEstado"
        className="select-st"
        onChange={handleChange}
      >
        <option value="2">Habilitado</option>
        <option value="1">Deshabilitado</option>
      </Form.Select>
      <ToastMaterial
        message={dataUpdate?.updateRenotificar?.message}
        showToast={showToast}
        className={
          dataUpdate?.updateRenotificar?.code === "01" ? "success" : "error"
        }
        setShowToast={setShowToast}
      />
    </>
  );
};

export default SelectRenot;
