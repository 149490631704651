/* istanbul ignore file */
import moment from "moment";

const Heading = [
  [
    "FOLIO",
    "FECHA_EMISION",
    "NRO_NOMINA",
    "ORIGEN",
    "RUT_ORG",
    "DIGITO_VERIFICADOR",
    "RUT_AFILIADO",
    "DIGITO_VERIFICADOR_AFILIADO",
    "APELL_PAT",
    "APELL_MAT",
    "NOMBRE",
    "MONTO_TOTAL",
    "PERIODO_REMUNERACION",
    "DETALLE_MONTO_PESOS",
    "DETALLE_MONTO_UF",
    "DETALLE_MONTO_PORCENTAJE",
    "DETALLE_GES",
    "DETALLE_MONTO_OTROS_PESOS",
    "TIPO_FUN",
    "TIPO_NOTIFICACION",
    "ARCHIVO",
    "URL",
    "CONTRATO_ID",
    "VALOR_CONTRATO",
    "TIPO_RELACION",
  ],
];

const HeadingManual = [
  [
    "NRO_REMESA",
    "FOLIO_FUN",
    "TIPO_NOTIFICACION",
    "FECHA_FIRMA_FUN",
    "RUT_EMPLEADOR",
    "DV_DE_EMPLEADOR",
    "RAZON_SOCIAL",
    "REGION_EMPRESA",
    "CIUDAD_EMPRESA",
    "COMUNA_EMPRESA",
    "DIRECCION_EMPRESA",
    "TELEFONO_EMPRESA",
    "FECHA_DE_CREACION_DE_REMESA",
    "ESTADO",
    "CODIGO",
    "DESCRIPCION_RECHAZO",
    "URL",
    "FECHA_NOTIFICACION",
    "USUARIO_NOTIFICADO",
  ],
];

const HeadingReporte = [
  [
    "Folio_FUN",
    "Numero_de_Contrato",
    "Rut_Cotizante",
    "Nombre_cotizante",
    "Sexo",
    "tipo_relacion",
    "Cotizacion_Pactada",
    "Rut_Empleador",
    "Nombre_empleador",
    "Comuna_Empleador",
    "Region_Empleador",
    "Direccion_Empleador",
    "Tipo_Notificacion",
    "Nombre_Operador",
    "Fecha_Notificacion",
    "Estado_Notificacion",
    "Motivo_de_rechazo",
    "Isapre_Origen",
    "Origen_Venta",
    "Rut_Agente_Venta",
    "Nombre_Agente_Venta",
  ],
];

// eslint-disable-next-line import/no-anonymous-default-export
export function ExportToReporte(dataReporte, nombreReporte) {
  const funesReporte = dataReporte?.getReporte.map((e) => {
    let datos = {
      Folio_FUN: e.Folio_FUN,
      Numero_de_Contrato: e.Numero_de_Contrato,
      Rut_Cotizante: e.Rut_Cotizante,
      Nombre_cotizante: e.Nombre_cotizante,
      Sexo: e.Sexo,
      tipo_relacion: e.tipo_relacion,
      Cotizacion_Pactada: e.Cotizacion_Pactada,
      Rut_Empleador: e.Rut_Empleador,
      Nombre_empleador: e.Nombre_empleador,
      Comuna_Empleador: e.Comuna_Empleador,
      Region_Empleador: e.Region_Empleador,
      Direccion_Empleador: e.Direccion_Empleador,
      Tipo_Notificacion: e.Tipo_Notificacion,
      Nombre_Operador: e.Nombre_Operador,
      Fecha_Notificacion:
        e.Fecha_Notificacion === null
          ? ""
          : moment(e.Fecha_Notificacion).format("DD/MM/yyyy"),
      Estado_Notificacion: e.Estado_Notificacion,
      Motivo_de_rechazo: e.Motivo_de_rechazo,
      Isapre_Origen: e.Isapre_Origen,
      Origen_Venta: e.Origen_Venta,
      Rut_Agente_Venta: e.Rut_Agente_Venta,
      Nombre_Agente_Venta: e.Nombre_Agente_Venta,
    };
    return datos;
  });

  import("xlsx").then((XLSX) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, HeadingReporte);
    XLSX.utils.sheet_add_json(ws, funesReporte, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
    XLSX.writeFile(wb, nombreReporte + ".xlsx");
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export function ExportToExcelManual(dataManual, nombreManual) {
  const funesManual = dataManual?.getManual.map((e) => {
    let datos = {
      nro_remesa: e.idRemesa,
      folio: e.folio,
      tipo_notificacion: e.tipo_fun,
      fecha_firma: e.fecha_Firma.split("-").reverse().join("/"),
      rut_empleador: e.rut_empresa,
      dv_de_empleador: e.digito_verificador,
      razon_social: e.razon_social,
      region_empresa: e.region_empresa,
      ciudad_empresa: e.ciudad_empresa,
      comuna_empresa: e.comuna_empresa,
      direccion_empresa: e.direccion_empresa,
      telefono_empresa: e.telefono_empresa,
      fecha_remesa: moment(parseInt(e.fecha_remesa)).format("DD/MM/yyyy"),
      estado: "",
      codigo: "",
      descripcion_rechazo: "",
      URL: e.url,
      fecha_notificacion: "",
      usuario_notificado: "",
    };
    return datos;
  });

  import("xlsx").then((XLSX) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, HeadingManual);
    XLSX.utils.sheet_add_json(ws, funesManual, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
    XLSX.writeFile(wb, nombreManual + ".xlsx");
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (excelData, nombreExcel) => {
  const funes = excelData?.getXData.map((e) => {
    const fechaR = e.fecha_emision.split("-");
    if (fechaR[0].length === 2) {
      fechaR[0] = "20" + fechaR[0];
    }
    if (fechaR[1].length === 1) {
      fechaR[1] = "0" + fechaR[1];
    }
    if (fechaR[2].length === 1) {
      fechaR[2] = "0" + fechaR[2];
    }
    let datos = {
      folio: e.folio,
      fecha_emision: fechaR[0] + fechaR[1] + fechaR[2],
      nro_nomina: e.nro_nomina,
      origen: e.origen,
      rut_org: e.rut_org,
      digito_verificador: e.digito_verificador,
      rut_afiliado: e.rut_afiliado,
      digito_verificador_afiliado: e.digito_verificador_afiliado,
      apell_pat: e.apell_pat,
      apell_mat: e.apell_mat,
      nombre: e.nombre,
      monto_total: e.monto_total,
      periodo_remuneracion: e.periodo_remuneracion,
      detalle_monto_pesos: e.detalle_monto_pesos,
      detalle_monto_uf: e.detalle_monto_uf,
      detalle_monto_porcentaje: "",
      detalle_ges: e.detalle_ges,
      detalle_monto_otros_pesos: "",
      tipo: e.tipoFun,
      tipo_notificacion: e.tipo_notificacion.split(",").join(""),
      archivo: e.archivo,
      url: e.url,
      contrato_id: "",
      valor_contrato: "",
      tipo_relacion: e.tipo_relacion,
    };
    return datos;
  });
  import("xlsx").then((XLSX) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, funes, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
    XLSX.writeFile(wb, nombreExcel + ".xlsx");
  });
};
