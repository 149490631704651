/* istanbul ignore file */
import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import { DataContextProvider } from "./components/Context/ContextSearch";
import { RemesaContextProvider } from "./components/Context/ContextDetalleRemesa";
import { Container } from "react-bootstrap";
import Tabla from "./components/Table/Table";
import SearchFunes from "./components/SearchFunes";
import HeaderRemesa from "./components/Remesa/HeaderRemesa";
import Remesas from "./components/Remesa/Remesas";
import TableRemesas from "./components/Remesa/TableRemesas";
import HistoricoFunes from "./components/Historial/HistoricoFunes";
import SearchHistorico from "./components/Historial/SearchHistorico";
import { useIsAuthenticated } from "@azure/msal-react";
import ReporteFunes from "./components/ReporteFunes/ReporteFunes";
import AppBarAlemana from "./components/Drawer/appBar";
import { DrawerLeft } from "./components/Drawer/drawerLeft";
import { Main } from "./components/Drawer/content";
import Material from "./components/Material/material";
import Inicio from "./components/Inicio/Inicio";
import EditarEmpresas from "./components/EmpresasConvenios/EditarEmpresas";
import IndexEmpresas from "./components/EmpresasConvenios/IndexEmpresas";
import Loader from "./components/toast/Loader";
import { NotifContextProvider } from "./components/Context/ContextNotif";
import Renotificaciones from "./components/Renotificaciones/Renotificaciones";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container fluid className="body-panddin">
      <Router>
        <AppBarAlemana></AppBarAlemana>
        {isAuthenticated ? (
          <>
            <DrawerLeft
              open={open}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
            ></DrawerLeft>
            <Main open={open}>
              <NotifContextProvider>
                <Loader />
                <Switch>
                  <Route path="/inicio">
                    <Inicio />
                  </Route>
                  <Route exact path="/">
                    <DataContextProvider>
                      <SearchFunes />
                      <Tabla />
                    </DataContextProvider>
                  </Route>
                  <RemesaContextProvider>
                    <Route path="/remesa">
                      <HeaderRemesa />
                      <TableRemesas />
                    </Route>
                    <Route path="/detalle">
                      <Remesas />
                    </Route>
                    <Route path="/historico">
                      <SearchHistorico />
                      <HistoricoFunes />
                    </Route>
                    <Route path="/reportefunes">
                      <ReporteFunes />
                    </Route>
                    <Route path="/empresasConvenios">
                      <IndexEmpresas />
                    </Route>
                    <Route path="/editarEmpresas">
                      <EditarEmpresas />
                    </Route>
                    <Route path="/material">
                      <Material />
                    </Route>
                    <Route path="/renotificaciones">
                      <Renotificaciones/>
                    </Route>
                  </RemesaContextProvider>
                  <Route path="/*"></Route>
                </Switch>
              </NotifContextProvider>
            </Main>
          </>
        ) : (
          <>
            <Switch></Switch>
          </>
        )}
      </Router>
    </Container>
  );
}

export default App;
