import React, { useEffect } from "react";
import { FC, useState } from "react";
import { useMutation } from "@apollo/client";
import { Form, Col } from "react-bootstrap";
import { UPDATE_CANAL_NOTIFICACION } from "../../graphql/mutations";
import ToastMaterial from "../toast/ToastMaterial";

type CanalProps = {
  canalDetector: string;
  id: number;
  renderizado: boolean;
  setUpdateTable: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CanalSelectDropdown: FC<CanalProps> = ({
  id,
  canalDetector,
  renderizado,
  setUpdateTable,
}) => {
  const [canal, setCanal] = useState(canalDetector);
  const [showToast, setShowToast] = useState(false);
  const [renderizadoCanal, setRenderizadoCanal] = useState(renderizado);
  const [addCanal, { data: dataUpdate }] = useMutation(
    UPDATE_CANAL_NOTIFICACION,
    {
      onCompleted: () => {
        setShowToast(true);
        setUpdateTable(true);
      },
    }
  );

  function handleChange(e) {
    setRenderizadoCanal(false);
    setCanal(e.target.value);
  }

  useEffect(() => {
    setCanal(canalDetector);
    // eslint-disable-next-line
  }, [canalDetector]);

  useEffect(() => {
    if (canal !== canalDetector) {
      if (renderizadoCanal !== true) {
        let fkCanalNotificacion: number;
        if (canal === "Previred") {
          fkCanalNotificacion = 1;
        } else if (canal === "X-Data") {
          fkCanalNotificacion = 2;
        } else if (canal === "Manual") {
          fkCanalNotificacion = 3;
        }
        addCanal({
          variables: {
            updateChanelId: id,
            fkCanalNotificacion: fkCanalNotificacion,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [canal]);

  return (
    <>
      <Col xs={3} md={5} lg={7}>
        <Form.Select
          onChange={handleChange}
          value={canalDetector == null ? "" : canalDetector}
          data-testid="selectChannel"
          className="select-st"
        >
          <option defaultValue="0" hidden>
            Seleccionar canal
          </option>
          <option value="Previred">Previred</option>
          <option value="X-Data">X-Data</option>
          <option value="Manual">Manual</option>
        </Form.Select>
      </Col>
      <ToastMaterial
        message={dataUpdate?.updateChanel?.message}
        showToast={showToast}
        className={
          dataUpdate?.updateChanel?.code === "01" ? "success" : "error"
        }
        setShowToast={setShowToast}
      />
    </>
  );
};

export default CanalSelectDropdown;
