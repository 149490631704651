/* istanbul ignore file */
import React from "react";
import "../Material/material.scss";
import FormControl from "@mui/material/FormControl";
import { CustomSelect } from "../select/CustomSelect";
import PrimaryButton, { OutlinedButton } from "../Botones";
import { MediumButton } from "../Botones";
import { SecondaryButton } from "../Botones";
import { RechazarButton } from "../Botones";
import ThemeTextField from "../textField";
import TableCollapse from "../Material/Table/TableCollapse";
import ResultadoTablas from "../ResultadoTablas/ResultadoTablas";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ModalMaterial } from "./Modales/ModalMaterial";
import { IconButton } from "@mui/material";
import {
  ActualizarIcon,
  ExportIcon,
  PlusIcon,
} from "../../Images/iconosEsencial";
import { DragAndDrop } from "../DragDrop/DragAndDrop";
import ModalDrag from "../DragDrop/components/ModalDrag";
import Paginator from "../Paginator/Paginator";

const material = () => {
  return (
    <>
      {/* <div className="card-box">
        <h3 className="tituloPag">Remesas</h3>
        <label className="tituloSecPag">Generación de remesa</label>
        <p className="labelForm">label normal</p>
        <p className="labelFormColor">color</p>
      </div>
      <div className="card-box">
        <PrimaryButton variant="text">Botón primario</PrimaryButton>
        <MediumButton variant="text">Medium</MediumButton>
        <SecondaryButton variant="text">Botón secundario</SecondaryButton>
        <RechazarButton variant="text">Botón rechazar</RechazarButton>
        <OutlinedButton sx={{ marginBottom: "10px" }}>
          <ExportIcon />
          Exportar
        </OutlinedButton>
        <SecondaryButton sx={{ margin: "0 10px 10px" }} disabled>
          <ActualizarIcon />
          Actualizar listado
        </SecondaryButton>
        <SecondaryButton sx={{ margin: "0 10px 10px" }} disabled>
          <PlusIcon />
          Añadir empresa
        </SecondaryButton>
      </div>
      <div className="card-box">
        <FormControl>
          <CustomSelect
            data={["titulos 1", "titulos 2", "titulos 3"]}
            title={"Elegir reporte "}
          ></CustomSelect>
        </FormControl>
      </div>
      <div className="card-box">
        <ThemeTextField fullWidth placeholder="Escribe aquí" />
      </div>
      <div className="card-box">
        <TableCollapse />
      </div> */}
      <div className="card-box">
        <ModalMaterial></ModalMaterial>
        <Paginator
          component="div"
          count={10}
          rowsPerPage={10}
          page={0}
          onPageChange={null}
        ></Paginator>
      </div>
      {/* <div className="card-box">
        <ModalDrag textButton={"Abrir modal"} modalTitle={"Modal Title"} />
      </div> */}

      {/* <div className="card-box">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="headerTableIzquierda">
                  N° Remesa
                </StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>Fecha creación</StyledTableCell>
                <StyledTableCell>Fecha de carga</StyledTableCell>
                <StyledTableCell>Total funes</StyledTableCell>
                <StyledTableCell>Operador</StyledTableCell>
                <StyledTableCell>Región</StyledTableCell>
                <StyledTableCell className="headerTableAcciones">
                  Detalles
                </StyledTableCell>
                <StyledTableCell className="headerTableAcciones2">
                  Acciones
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell className="headerTableIzquierda">1</TableCell>
                <TableCell className="">1</TableCell>
                <TableCell className="">1</TableCell>
                <TableCell className="">1</TableCell>
                <TableCell className="">1</TableCell>
                <TableCell className="">1</TableCell>
                <TableCell className="">1</TableCell>
                <TableCell className="headerTableAcciones">1</TableCell>
                <TableCell className="headerTableAcciones2">1</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="card-box">
        <ResultadoTablas
          titulo={"Titulo"}
          subtitulo={"subtitulo"}
          icono={"icon-info"}
          boton={true}
          btnClick={null}
        ></ResultadoTablas>
      </div> */}
    </>
  );
};

export default material;
