/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
/* istanbul ignore file */
import React, { FC, useEffect, useState, useContext } from "react";
import {
  CircularProgress,
  Dialog,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import "./../Modales/Modales.css";
import PrimaryButton from "../../Botones";
import {
  ErrorModalIcon,
  ExitoModalIcon,
  InfoNotIcon,
} from "../../../Images/iconosEsencial";
import DataContext from "./../../Context/ContextSearch";

type ModalMaterialProps = {
  code: string;
  message: string;
  dataError: string[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalMaterial: FC<ModalMaterialProps> = ({
  code,
  message,
  dataError,
  setShowModal,
}) => {
  const { setUploadComplete } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    setOpen(false);
    setUploadComplete(true);
  };

  useEffect(() => {
    code !== null ? setOpen(true) : setOpen(false);
  }, []);

  return (
    <>
      {/*Modal Carga exitosa*/}
      {code === "01" && (
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            data-testid="dialogModal"
            sx={{ m: 1, textAlign: "center", padding: "5px 15px" }}
          ></Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            data-testid="dialogModal"
            sx={{ m: 1, textAlign: "center", padding: "0" }}
          >
            <ExitoModalIcon
              sx={{ width: "42px", height: "42px" }}
            ></ExitoModalIcon>
          </Grid>
          <DialogTitle className="tituloModal" sx={{ padding: "10px 0" }}>
            Carga exitosa
          </DialogTitle>
          <DialogContent sx={{ padding: "10px 0px!important" }}>
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <PrimaryButton onClick={handleClose}>Aceptar</PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      {/*Modal ERROR 1*/}
      {code === "03" && (
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <DialogContent sx={{ padding: "20px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              >
                <ErrorModalIcon
                  sx={{ width: "42px", height: "42px" }}
                ></ErrorModalIcon>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
                  No podemos cargar el documento
                </DialogTitle>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography className="contentDialog">
                    {" "}
                    El archivo que quieres cargar no contiene la información
                    mínima para entrar al sistema, te recomendamos revisar que
                    los campos requeridos estén completos y en el formato
                    adecuado.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                  <Typography className="contentDialog">
                    Corrige esta información directamente en el documento, y
                    vuelve a intentarlo.
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>

            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <PrimaryButton onClick={handleClose}>Entendido</PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}

      {/*Modal ERROR 2*/}
      {(code === "02" || code === "04") && (
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <DialogContent sx={{ padding: "20px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              >
                <ErrorModalIcon
                  sx={{ width: "42px", height: "42px" }}
                ></ErrorModalIcon>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <DialogTitle
                  className="tituloModal"
                  sx={{ paddingBottom: "10px" }}
                >
                  No podemos cargar el documento
                </DialogTitle>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div
                    className="alert alert-warning d-flex align-items-center"
                    role="alert"
                  >
                    <div className="divAlert">
                      <InfoNotIcon></InfoNotIcon>
                      <strong className="alertText">
                        <List>
                          <ListItem>{message}</ListItem>
                          {/* El archivo contiene casillas vacías */}

                          {dataError?.map((e) => (
                            <ListItem>● {e}</ListItem>
                          ))}
                        </List>
                      </strong>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                  <Typography className="contentDialogSmall">
                    Corrige esta información directamente en el documento, y
                    vuelve a intentarlo
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>

            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <PrimaryButton onClick={handleClose}>Entendido</PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}

      {/*Modal Loading 3*/}
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        // open={open}
        open={false}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogContent sx={{ padding: "20px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0" }}
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              data-testid="dialogModal"
              sx={{
                m: 1,
                textAlign: "center",
                padding: "0",
                height: "350px",
              }}
            >
              <CircularProgress className="spinnerGreen"></CircularProgress>
              <Typography className="contentDialogLoading">
                Cargando documento
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
