/* istanbul ignore file */
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import "../../components/toast/Loader.scss";
import NotifContext from "../Context/ContextNotif";
import { useLazyQuery } from "@apollo/client";
import { TIMEOUT_LAMBDA } from "../../graphql/queries";
import ToastMaterial from "./ToastMaterial";

const Loader = () => {
  const { loader, setLoader, filename } = useContext(NotifContext);
  const [showToast, setShowToast] = useState(false);
  const [getData, { data }] = useLazyQuery(TIMEOUT_LAMBDA);
  const [counter, setCounter] = useState(0);
  const [aux, setAux] = useState(false);

  const lambdaAsync = async () => {
    await getData({
      variables: {
        nombreArchivo: filename,
      },
    }).then(() => {
      setAux(true);
    });
  };

  const timeoutQuery = async (code) => {
    setCounter((prevCounter) => prevCounter + 1);
    if (code === "1") {
      setLoader(false);
      setAux(false);
      setCounter(0);
      setShowToast(true);
    } else if (code === "2") {
      if (counter < 10) {
        setTimeout(() => {
          lambdaAsync();
        }, 30000);
      } else {
        setLoader(false);
        setAux(false);
        setCounter(0);
        setShowToast(true);
      }
    }
  };

  useEffect(() => {
    if (aux) {
      setAux(false);
      timeoutQuery(data?.getTimeOutLamda.code);
    }
    // eslint-disable-next-line
  }, [aux]);

  useEffect(() => {
    if (loader) {
      lambdaAsync();
    }
    // eslint-disable-next-line
  }, [loader]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  if (loader !== true)
    return (
      <>
        {data?.getTimeOutLamda.code && (
          <ToastMaterial
            message={
              data?.getTimeOutLamda.code === "1"
                ? "Convenios actualizados"
                : "No se han actualizado convenios"
            }
            showToast={showToast}
            className={data?.getTimeOutLamda.code === "1" ? "success" : "error"}
            setShowToast={setShowToast}
          />
        )}
      </>
    );

  if (loader === true) {
    return (
      <Box sx={{ position: "sticky" }} className="loaderBox">
        {/* <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={30}
        thickness={4}
       
        value={100}
      /> */}
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: "#267494",
            animationDuration: "550ms",
            position: "absolute",
            left: 10,
            top: 9,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={20}
          thickness={4}
        />
        <p className="cargandoArchivo">Cargando archivo...</p>
      </Box>
    );
  }
};

export default Loader;
