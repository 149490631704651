/* eslint-disable jsx-a11y/alt-text */
import { FC, useState } from "react";
import "../Material/material.scss";
import "../ResultadoTablas/resultadosTablas.scss";
import { Grid } from "@mui/material";
import ModalDrag from "../DragDrop/components/ModalDrag";

type Props = {
  titulo: string;
  subtitulo: string;
  icono: string;
  boton: boolean;
};

const ResultadoTablas: FC<Props> = ({ titulo, subtitulo, icono, boton }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="box-resultados">
      <Grid
        sx={{ margin: "auto 0" }}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        data-testid="resultado-tablas"
      >
        <img className={icono}></img>
      </Grid>
      <Grid
        sx={{ margin: "auto 0" }}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <p className="TituloResultadoTabla">{titulo}</p>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <p className="subtituloResultadoTabla">{subtitulo}</p>
      </Grid>
      {boton && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <ModalDrag
            textButton={"Cargar funes"}
            modalTitle={"Gestión de Funes"}
            type={"fun"}
            rowRemesa={null}
            canalRemesa={null}
            disabled={false}
            setShowModal={setShowModal}
          />
        </Grid>
      )}
    </div>
  );
};

export default ResultadoTablas;
