/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState, useRef } from "react";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import { useLazyQuery } from "@apollo/client";
import { MOTIVO_RECHAZO } from "../../graphql/queries";
import "./HistoricoFunes.scss";
import "../Material/material.scss";
import FormControl from "@mui/material/FormControl";
import {} from "../select/CustomSelect";
import PrimaryButton from "../Botones";
import { SecondaryButton } from "../Botones";
import ThemeTextField from "../textField";
import { Autocomplete, Grid, MenuItem } from "@mui/material";
import { Breadcrumbs, Link, Typography, Select } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  CustomInputSelect,
  menuItemStyle,
  menuProps,
} from "../select/styledSelect";
import { BuscarIcon, CerrarIcon } from "../../Images/iconosEsencial";
import { useFormik } from "formik";
import {
  checkRut,
  prettifyRut,
  formatRut,
  removeSeparators,
} from "react-rut-formatter";
import * as yup from "yup";
import { toTitleCase } from "../../utils/StringUtils";

const validationSchema = yup.object({
  rutAfiliado: yup.string().nullable(),
  rutEmpresa: yup.string().nullable(),
});

const tipoNotif = [
  { id: 0, text: "Sin movimientos en el mes informado" },
  { id: 1, text: "Suscripción del Contrato" },
  { id: 2, text: "Término de Contrato" },
  { id: 3, text: "Situaciones especiales" },
  { id: 4, text: "Cambio de Empleador" },
  { id: 5, text: "Cambio de Anualidad" },
  { id: 6, text: "Variación de precio por cambio de tramo de edad" },
  { id: 7, text: "Modificación de Carga" },
  { id: 8, text: "Modificación de Cotización Pactada" },
  { id: 9, text: "Cambio de plan" },
];

const SearchHistorico = () => {
  const [operador, setOperador] = useState(null);
  const [estado, setEstado] = useState(null);
  const [rechazo, setRechazo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [rutAfiliado, setRutAfiliado] = useState(null);
  const [folioFun, setFolioFun] = useState(null);
  const [mes, setMes] = useState(null);
  const [anio, setAnio] = useState(null);
  const [rutEmpresa, setRutEmpresa] = useState(null);
  const { dataFiltro, setDataFiltro } = useContext(RemesaContext);
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [cleanDisabled, setCleanDisabled] = useState(true);
  const [validRutA, setValidRutA] = useState(false);
  const [validRutE, setValidRutE] = useState(false);
  const [emptyFields, setEmptyFields] = useState(true);
  const [tipoNot, setTipoNot] = useState<any>([]);
  const autoC = useRef(null);
  const [getMotivoRechazo, { data }] = useLazyQuery(MOTIVO_RECHAZO);
  const currentYear = new Date().getFullYear();

  const formik = useFormik({
    initialValues: {
      rutAfiliado: rutAfiliado,
      rutEmpresa: rutEmpresa,
    },
    validationSchema: validationSchema,
    validate(values) {
      const errors: { rutAfiliado?: string; rutEmpresa?: string } = {};
      if (values.rutAfiliado && !checkRut(values.rutAfiliado)) {
        errors.rutAfiliado = "RUT inválido";
      } else if (
        checkRut(values.rutAfiliado) &&
        !rutLength(values.rutAfiliado)
      ) {
        errors.rutAfiliado = "RUT inválido";
      }
      if (values.rutEmpresa && !checkRut(values.rutEmpresa)) {
        errors.rutEmpresa = "RUT inválido";
      } else if (checkRut(values.rutEmpresa) && !rutLength(values.rutEmpresa)) {
        errors.rutEmpresa = "RUT inválido";
      }
      return errors;
    },
    onSubmit: (values) => {
      formatRut(values.rutAfiliado);
      formatRut(values.rutEmpresa);
    },
  });

  const rutLength = (val: string) => {
    if (
      removeSeparators(val).length >= 8 &&
      removeSeparators(val).length <= 10
    ) {
      return true;
    } else {
      return false;
    }
  };

  const generateYears = () => {
    let years = [];
    let startYear = 2022;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  };

  const nombreCanal = (selectOperador: number) => {
    if (selectOperador === 1) {
      return "Previred";
    } else if (selectOperador === 2) {
      return "X-Data";
    } else if (selectOperador === 3) {
      return "Manual";
    }
  };

  const nombreMes = (selectMes: number) => {
    switch (selectMes) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
      default:
        return "";
    }
  };

  const handleRutA = (rutA) => {
    if (rutAfiliado === "" || rutAfiliado === null) {
      setValidRutA(false);
    }
    setRutAfiliado(rutA);
  };

  const handleRutE = (rutE) => {
    if (rutEmpresa === "" || rutEmpresa === null) {
      setValidRutE(false);
    }
    setRutEmpresa(rutE);
  };

  const handleTipoNot = (newValue) => {
    if (newValue !== null) {
      let idTipo = newValue
        .map((a) => a.id)
        .sort((a, b) => a - b)
        .join();
      setTipoNot(idTipo);
    }
  };

  const clearTipoNot = () => {
    if (autoC.current) {
      let clearTipo = autoC.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (clearTipo !== undefined) {
        clearTipo.click();
      }
    }
  };

  const limpiar = () => {
    setTipoNot("");
    setOperador(null);
    setEstado(null);
    setRechazo(null);
    setEmpresa(null);
    setRutAfiliado(null);
    setFolioFun(null);
    setMes(null);
    setAnio(null);
    setRutEmpresa(null);
    let filter = {
      tipo: null,
      operador: null,
      estado: null,
      rechazo: null,
      empresa: null,
      rutAfiliado: null,
      folioFun: null,
      mes: null,
      anio: null,
      rutEmpresa: null,
    };
    setDataFiltro({ ...dataFiltro, filter });
    formik.setTouched({}, false);
    formik.values.rutAfiliado = "";
    formik.values.rutEmpresa = "";
    clearTipoNot();
  };

  const removeFilter = (string) => {
    switch (string) {
      case "operador":
        setOperador(null);
        break;
      case "estado":
        setEstado(null);
        break;
      case "rutAfiliado":
        setRutAfiliado(null);
        break;
      case "folioFun":
        setFolioFun(null);
        break;
      case "mes":
        setMes(null);
        break;
      case "anio":
        setAnio(null);
        break;
      case "rutEmpresa":
        setRutEmpresa(null);
        break;
    }
  };

  const filterHistorico = () => {
    let filter = {
      tipo: tipoNot.length > 0 ? tipoNot : null,
      operador: operador,
      estado: estado !== null ? estado.charAt(0) : null,
      rechazo: rechazo,
      empresa: empresa,
      rutAfiliado: rutAfiliado,
      folioFun: folioFun,
      mes: mes,
      anio: anio,
      rutEmpresa: rutEmpresa,
    };
    setDataFiltro({ ...dataFiltro, filter });
  };

  const buscarRechazo = () => {
    getMotivoRechazo({
      variables: {
        canalNotificacion: operador || null,
      },
    });
  };

  useEffect(() => {
    buscarRechazo();
    // eslint-disable-next-line
  }, [operador]);

  useEffect(() => {
    let errorValidation = [];
    if (Boolean(formik.errors.rutAfiliado)) {
      errorValidation.push(formik.errors.rutAfiliado);
    }
    if (Boolean(formik.errors.rutEmpresa)) {
      errorValidation.push(formik.errors.rutEmpresa);
    }
    // eslint-disable-next-line
  }, [formik.errors]);

  useEffect(() => {
    if (rutAfiliado !== "" && rutAfiliado !== null) {
      if (checkRut(rutAfiliado) && rutLength(rutAfiliado)) {
        setValidRutA(true);
      } else {
        setValidRutA(false);
      }
    } else {
      setValidRutA(true);
    }
    if (rutEmpresa !== "" && rutEmpresa !== null) {
      if (checkRut(rutEmpresa) && rutLength(rutEmpresa)) {
        setValidRutE(true);
      } else {
        setValidRutE(false);
      }
    } else {
      setValidRutE(true);
    }
    // eslint-disable-next-line
  }, [rutAfiliado, rutEmpresa]);

  useEffect(() => {
    if (validRutA && validRutE) {
      if (rutAfiliado || rutEmpresa || !emptyFields) {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [validRutA, validRutE]);

  useEffect(() => {
    if (
      !folioFun &&
      !operador &&
      !estado &&
      !rechazo &&
      !mes &&
      !anio &&
      !rutAfiliado &&
      !rutEmpresa &&
      tipoNot.length === 0
    ) {
      setCleanDisabled(true);
    } else {
      setCleanDisabled(false);
    }
    if (
      folioFun ||
      operador ||
      estado ||
      rechazo ||
      mes ||
      anio ||
      tipoNot.length !== 0
    ) {
      setEmptyFields(false);
      if (
        (rutAfiliado === null || rutAfiliado === "" || checkRut(rutAfiliado)) &&
        (rutEmpresa === null || rutEmpresa === "" || checkRut(rutEmpresa))
      )
        setIsDisabled(false);
    } else if (!checkRut(rutAfiliado) && !checkRut(rutEmpresa)) {
      setIsDisabled(true);
    }
    if (
      !folioFun &&
      !operador &&
      !estado &&
      !rechazo &&
      !mes &&
      !anio &&
      tipoNot.length === 0
    ) {
      setEmptyFields(true);
    }
    // eslint-disable-next-line
  }, [
    folioFun,
    operador,
    estado,
    rechazo,
    rutAfiliado,
    rutEmpresa,
    mes,
    anio,
    tipoNot,
  ]);

  return (
    <>
      <h3 className="tituloPag">Historial de Funes</h3>
      <Grid
        sx={{ padding: "5px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Historial de funes
          </Typography>
        </Breadcrumbs>
      </Grid>
      <div className="card-box">
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter"
        >
          <Grid item xs={4}>
            <p className="labelForm">Folio FUN</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <ThemeTextField
                fullWidth
                placeholder="Ej: 123456"
                value={folioFun === null ? "" : folioFun}
                onChange={(e) =>
                  setFolioFun((v) =>
                    e.target.validity.valid ? e.target.value : v
                  )
                }
                inputProps={{
                  maxLength: 6,
                  pattern: "[0-9]*",
                }}
                data-testid="FolioFun"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p className="labelForm">Operador</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <Select
                sx={{ width: "100%" }}
                input={<CustomInputSelect />}
                MenuProps={menuProps}
                value={operador === null ? 0 : operador}
                onChange={(e) => setOperador(parseInt(e.target.value))}
                inputProps={{ "data-testid": "Operador" }}
              >
                <MenuItem value="0" sx={menuItemStyle} hidden>
                  Selecciona
                </MenuItem>
                <MenuItem value="1" sx={menuItemStyle}>
                  Previred
                </MenuItem>
                <MenuItem value="2" sx={menuItemStyle}>
                  X-Data
                </MenuItem>
                <MenuItem value="3" sx={menuItemStyle}>
                  Manual
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p className="labelForm">Estado</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <Select
                sx={{ width: "100%" }}
                input={<CustomInputSelect />}
                MenuProps={menuProps}
                value={estado === null ? 0 : estado}
                onChange={(e) => setEstado(e.target.value)}
                inputProps={{ "data-testid": "Estado" }}
              >
                <MenuItem value="0" sx={menuItemStyle} hidden>
                  Selecciona
                </MenuItem>
                <MenuItem value="Por enviar" sx={menuItemStyle}>
                  Por enviar
                </MenuItem>
                <MenuItem value="Enviado" sx={menuItemStyle}>
                  Enviado
                </MenuItem>
                <MenuItem value="Rechazado" sx={menuItemStyle}>
                  Rechazado
                </MenuItem>
                <MenuItem value="Aceptado" sx={menuItemStyle}>
                  Aceptado
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {show && (
            <>
              <Grid item xs={4}>
                <p className="labelForm">Tipo de Rechazo</p>
                <FormControl
                  sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                >
                  <Select
                    sx={{ width: "100%" }}
                    input={<CustomInputSelect />}
                    MenuProps={menuProps}
                    value={rechazo === null ? 0 : rechazo}
                    onChange={(e) => setRechazo(parseInt(e.target.value))}
                    inputProps={{ "data-testid": "Rechazo" }}
                  >
                    <MenuItem value="0" sx={menuItemStyle} hidden>
                      Selecciona
                    </MenuItem>
                    {data?.getMotivoRechazo.map((e) => (
                      <MenuItem
                        key={e.motivo_rechazo_id}
                        value={e.motivo_rechazo_id}
                        sx={menuItemStyle}
                      >
                        {e.motivo_rechazo_id +
                          " - " +
                          toTitleCase(e.descripcion)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formatted = prettifyRut(rutAfiliado);
                    setRutAfiliado(formatted);
                    formik.handleSubmit();
                  }}
                  onBlur={() => {
                    const formatted = prettifyRut(rutAfiliado);
                    setRutAfiliado(formatted);
                    formik.handleSubmit();
                  }}
                >
                  <p className="labelForm">Rut Afiliado</p>
                  <FormControl
                    sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                  >
                    <ThemeTextField
                      id="rutAfiliado"
                      name="rutAfiliado"
                      fullWidth
                      placeholder="Ej: 12345678-9"
                      error={
                        formik.touched.rutAfiliado &&
                        Boolean(formik.errors.rutAfiliado)
                      }
                      helperText={
                        formik.touched.rutAfiliado && formik.errors.rutAfiliado
                      }
                      value={rutAfiliado || ""}
                      onChange={(e) => {
                        handleRutA(e.target.value);
                        formik.handleChange(e);
                      }}
                      data-testid="RutAfiliado"
                    />
                  </FormControl>
                </form>
              </Grid>
              <Grid item xs={4}>
                <p className="labelForm">Tipo de notificación</p>
                <FormControl
                  sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                >
                  <Autocomplete
                    ref={autoC}
                    value={tipoNot.id}
                    onChange={(event, newValue) => {
                      handleTipoNot(newValue);
                    }}
                    multiple
                    limitTags={3}
                    clearText="Borrar"
                    noOptionsText="No hay opciones."
                    options={tipoNotif}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.id.toString()}
                    renderOption={(props, option) => (
                      <li {...props}>{option.id + " - " + option.text}</li>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <ThemeTextField
                        {...params}
                        label=""
                        placeholder="Selecciona"
                      />
                    )}
                    data-testid="autocomplete"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <p className="labelForm">Periodo Remuneración</p>
                <FormControl
                  sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                >
                  <Select
                    sx={{ width: "100%" }}
                    input={<CustomInputSelect />}
                    MenuProps={menuProps}
                    value={mes === null ? 0 : mes}
                    onChange={(e) => setMes(parseInt(e.target.value))}
                    inputProps={{ "data-testid": "Mes" }}
                  >
                    <MenuItem value="0" sx={menuItemStyle} hidden>
                      Mes
                    </MenuItem>
                    <MenuItem value="1" sx={menuItemStyle}>
                      Enero
                    </MenuItem>
                    <MenuItem value="2" sx={menuItemStyle}>
                      Febrero
                    </MenuItem>
                    <MenuItem value="3" sx={menuItemStyle}>
                      Marzo
                    </MenuItem>
                    <MenuItem value="4" sx={menuItemStyle}>
                      Abril
                    </MenuItem>
                    <MenuItem value="5" sx={menuItemStyle}>
                      Mayo
                    </MenuItem>
                    <MenuItem value="6" sx={menuItemStyle}>
                      Junio
                    </MenuItem>
                    <MenuItem value="7" sx={menuItemStyle}>
                      Julio
                    </MenuItem>
                    <MenuItem value="8" sx={menuItemStyle}>
                      Agosto
                    </MenuItem>
                    <MenuItem value="9" sx={menuItemStyle}>
                      Septiembre
                    </MenuItem>
                    <MenuItem value="10" sx={menuItemStyle}>
                      Octubre
                    </MenuItem>
                    <MenuItem value="11" sx={menuItemStyle}>
                      Noviembre
                    </MenuItem>
                    <MenuItem value="12" sx={menuItemStyle}>
                      Diciembre
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <p className="labelForm">ㅤㅤ</p>
                <FormControl
                  sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                >
                  <Select
                    sx={{ width: "100%" }}
                    input={<CustomInputSelect />}
                    MenuProps={menuProps}
                    value={anio === null ? 0 : anio}
                    onChange={(e) => setAnio(parseInt(e.target.value))}
                    inputProps={{ "data-testid": "Anio" }}
                  >
                    <MenuItem value="0" sx={menuItemStyle} hidden>
                      Año
                    </MenuItem>
                    {generateYears().map((e, index) => (
                      <MenuItem value={e} key={index} sx={menuItemStyle}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formatted = prettifyRut(rutEmpresa);
                    setRutEmpresa(formatted);
                    formik.handleSubmit();
                  }}
                  onBlur={() => {
                    const formatted = prettifyRut(rutEmpresa);
                    setRutEmpresa(formatted);
                    formik.handleSubmit();
                  }}
                >
                  <p className="labelForm">Rut Empresa</p>
                  <FormControl
                    sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                  >
                    <ThemeTextField
                      id="rutEmpresa"
                      name="rutEmpresa"
                      fullWidth
                      placeholder="Ej: 12345678-9"
                      error={
                        formik.touched.rutEmpresa &&
                        Boolean(formik.errors.rutEmpresa)
                      }
                      helperText={
                        formik.touched.rutEmpresa && formik.errors.rutEmpresa
                      }
                      value={rutEmpresa || ""}
                      onChange={(e) => {
                        handleRutE(e.target.value);
                        formik.handleChange(e);
                      }}
                      data-testid="RutEmpresa"
                    />
                  </FormControl>
                </form>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ width: "49%", float: "left" }}
            >
              {show ? (
                <SecondaryButton
                  sx={{ width: "auto", marginRight: "4px" }}
                  onClick={() => setShow(false)}
                  data-testid="lessFilters"
                >
                  <CerrarIcon />
                  Cerrar Búsqueda avanzada
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  sx={{ width: "206px", marginRight: "4px" }}
                  onClick={() => setShow(true)}
                  data-testid="moreFilters"
                >
                  <BuscarIcon />
                  Ver más filtros
                </SecondaryButton>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              sx={{ width: "49%", float: "right" }}
            >
              <SecondaryButton
                sx={{ marginRight: "4px" }}
                onClick={() => limpiar()}
                data-testid="cleanFilters"
                disabled={cleanDisabled}
              >
                Limpiar
              </SecondaryButton>
              <PrimaryButton
                sx={{ marginRight: "4px" }}
                onClick={() => filterHistorico()}
                data-testid="btnSearch"
                disabled={isDisabled}
              >
                Buscar
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div hidden>
        {operador !== 0 && (
          <span
            onClick={() => removeFilter("operador")}
            data-testid="removeOperador"
          >
            {nombreCanal(operador)}
          </span>
        )}
        {estado !== null && (
          <span
            onClick={() => removeFilter("estado")}
            data-testid="removeEstado"
          >
            {estado}
          </span>
        )}
        <span
          onClick={() => removeFilter("rutAfiliado")}
          data-testid="removeRutAfiliado"
        >
          {rutAfiliado}
        </span>
        <span
          onClick={() => removeFilter("folioFun")}
          data-testid="removeFolioFun"
        >
          {folioFun}
        </span>
        {mes !== 0 && (
          <span onClick={() => removeFilter("mes")} data-testid="removeMes">
            {nombreMes(mes)}
          </span>
        )}
        {anio !== 0 && (
          <span onClick={() => removeFilter("anio")} data-testid="removeAnio">
            {anio}
          </span>
        )}
        <span
          onClick={() => removeFilter("rutEmpresa")}
          data-testid="removeRutEmpresa"
        >
          {rutEmpresa}
        </span>
      </div>
    </>
  );
};

export default SearchHistorico;
