/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { FC, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { HISTORIAL_FUN } from "../../graphql/queries";
import { toTitleCase } from "../../utils/StringUtils";
import moment from "moment";
import {
  Box,
  CircularProgress,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";

type ModalHistorialProps = {
  folioFun: any;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalHistorial: FC<ModalHistorialProps> = ({
  folioFun,
  showModal,
  setShowModal,
}) => {
  const [getHistorialFun, { data, loading, error }] =
    useLazyQuery(HISTORIAL_FUN);
  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal && folioFun !== null) {
      getHistorialFun({
        variables: {
          folio: folioFun,
        },
      });
    }
    // eslint-disable-next-line
  }, [showModal]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={showModal}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <DialogContent sx={{ padding: "20px", minHeight: "150px" }}>
        <DialogContentText
          alignContent="center"
          sx={{ m: 1, textAlign: "center" }}
          component="div"
        >
          {loading ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              data-testid="dialogModal"
              sx={{
                minHeight: "150px",
              }}
            >
              <CircularProgress className="spinnerGreen"></CircularProgress>
              <Typography className="contentDialogLoading">
                Cargando historial FUN
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <DialogContent sx={{ padding: "0px 0px 20px 0px" }}>
                    <Grid container direction="row">
                      <Grid item xs={12} textAlign="left" pl="0">
                        <Typography
                          className="tituloModal"
                          sx={{ textAlign: "left" }}
                        >
                          Historial FUN
                        </Typography>
                        <Typography sx={{ padding: "0px 0px 0px 0px" }}>
                          Folio {folioFun}
                        </Typography>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {error ? (
                  <div className="remesa-box tabla sin-remesa">
                    Error cargando data.
                  </div>
                ) : (
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            ID Remesa
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Estado remesa
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Estado FUN
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Motivo rechazo
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Fecha última modificación
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.getDetalleHistorico.map((e) => (
                          <StyledTableRow key={e.idRemesa}>
                            <TableCell align="center">{e.idRemesa}</TableCell>
                            <TableCell align="center">
                              {e.estadoRemesa}
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {e.estadoFun === "Por Enviar" && (
                                  <p className="estado porenviar">
                                    {e.estadoFun}
                                  </p>
                                )}
                                {e.estadoFun === "Enviado" && (
                                  <p className="estado enviado">
                                    {e.estadoFun}
                                  </p>
                                )}
                                {e.estadoFun === "Rechazado" && (
                                  <>
                                    <p className="estado rechazado">
                                      {e.estadoFun}
                                    </p>
                                  </>
                                )}
                                {e.estadoFun === "Aceptado" && (
                                  <p className="estado aceptado">
                                    {e.estadoFun}
                                  </p>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {e.motivoRechazo
                                ? toTitleCase(e.motivoRechazo)
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {moment(
                                parseInt(e.FechaUltimaActualizacion)
                              ).format("DD/MM/yyyy")}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ModalHistorial;
