import { Grid, Typography } from "@mui/material";
import {
  IconCancelar,
  IconCancelarRed,
  IconCheckGreen,
} from "../../../Images/iconosEsencial";
import "./MultipleFileUpload.css";
import { ButtonIcon } from "../../Botones";
import { SwitchIcon } from "./SwitchIcon";

export function FileHeader(props) {
  function bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    // const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  const maxTextLength = (text) => {
    if (text !== undefined) {
      if (text.length > 35) {
        return text.substring(0, 32) + "...";
      } else {
        return text;
      }
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {props.error.length === 0 ? (
        <>
          <Grid item className="groupDrag2">
            <Typography component={"span"} className="nameFileAdjunto">
              <SwitchIcon type={props.object.file.type} estado={true} />
              {maxTextLength(props.object.file.name)}
              <Typography className="sizeFile">
                {bytesToSize(props.object.file.size)}
              </Typography>
            </Typography>

            <Typography className="tagAdjuntado">
              <IconCheckGreen />
              Adjuntado
            </Typography>
            <ButtonIcon sx={{float:"right", marginTop:"7px"}} onClick={() => props.onDelete(props.object)}>
              <IconCancelar />
              Quitar
            </ButtonIcon>
          </Grid>
        </>
      ) : (
        <>
          <Grid item className="groupDrag2">
            <Typography className="nameFileNoAdjunto">
              <SwitchIcon type={props.object.file.type} estado={false} />
              {maxTextLength(props.object.file.name)}
              <Typography className="sizeFile">
                {bytesToSize(props.object.file.size)}
              </Typography>
            </Typography>
            <Typography className="tagNoAdjuntado">
              <IconCancelarRed />
              No Adjuntado
            </Typography>
            <Typography className="errorDrag">
              Formato incorrecto, Inténtalo nuevamente.
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}
