import React from "react";
import "../../App.scss";

const SkeletonHistorial = () => {
  return (
    // <div className="skeleton hide-sk">
    <div className="skeleton" data-testid="skeletonLoader">
      {/* <div className="post-sk">
        <ul>
          <li>
            <div className="line-sk"></div>
            <div className="box-sk"></div>
          </li>
          <li>
            <div className="line-sk"></div>
            <div className="box-sk"></div>
          </li>
          <li>
            <div className="line-sk"></div>
            <div className="box-sk"></div>
          </li>
        </ul>
        <div className="btn-sk"></div>
      </div> */}
      <div className="post-sk">
        <div className="title-sk"></div>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-sk">
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SkeletonHistorial;
