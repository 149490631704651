import React from "react";
import "../../App.scss";

const SkeletonForm = () => {
  return (
    // <div className="skeleton hide-sk">
    <div className="skeleton" data-testid="skeletonLoader">
      <div className="post-sk">
        <div className="title-sk2"></div>
        <ul className="ul-label">
          <li>
            <div className="col-sk"></div>
          </li>

          <li></li>
        </ul>
        <ul className="ul-label">
          <li>
            <div className="col-sk"></div>
          </li>

          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-input">
          <li>
            <div className="col-sk"></div>
          </li>

          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-label">
          <li>
            <div className="col-sk"></div>
          </li>

          <li>
            <div className="col-sk"></div>
          </li>
        </ul>

        <ul className="ul-input">
          <li>
            <div className="col-sk"></div>
          </li>

          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-label">
          <li>
            <div className="col-sk"></div>
          </li>

          <li></li>
        </ul>
        <ul className="ul-label">
          <li>
            <div className="col-sk"></div>
          </li>

          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-input">
          <li>
            <div className="col-sk"></div>
          </li>

          <li>
            <div className="col-sk"></div>
          </li>
        </ul>
        <ul className="ul-input">
          <li></li>
          <li>
            <div className="btn-sk"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SkeletonForm;
