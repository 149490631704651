import React, { useContext, useState } from "react";
import "../Inicio/Inicio.scss";
import "./../Material/material.scss";
import { Grid, MenuItem, Select } from "@mui/material";

const Inicio = () => {
  return (
    <>
      <div className="card-box">
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter"
        >
          <Grid item xs={8}>
            <h3 className="bienvenida">Te damos la bienvenida</h3>
            <h3 className="alSistema">al sistema de Notificaciones</h3>
            <h3 className="aquiPodras">
              Aquí podrás gestionar, ver el historial de Funes, generar un
              reporte de Funes
            </h3>
            <h3 className="aquiPodras">
y generar Remesas.
            </h3>
          </Grid>
          <Grid item xs={4}>
            <img className="illus-inicio"></img>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Inicio;
