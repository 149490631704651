/* eslint-disable eqeqeq */
import { useApolloClient, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import "../ReporteFunes/ReporteFunes.scss";
import TableTooltip from "../TableTooltip";
import { REPORTE_FUNES } from "../../graphql/queries";
import DaterangeComponent from "../datepicker/DaterangeComponent";
import ResultadoTablas from "../ResultadoTablas/ResultadoTablas";
import { ExportToReporte } from "../../utils/ExportToExcel";
import "../Material/material.scss";
import {} from "../select/CustomSelect";
import PrimaryButton, { SecondaryButton } from "../Botones";
import { Grid } from "@mui/material";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DescargarIcon } from "../../Images/iconosEsencial";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonHistorial from "../Historial/SkeletonHistorial";
import { toTitleCase, maxTextLength } from "../../utils/StringUtils";
import TooltipTipoFun from "../Table/TooltipTipoFun";
import Paginator from "../Paginator/Paginator";

const ReporteFunes = () => {
  const client = useApolloClient();
  const [row, setRow] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [inprogress, setInprogress] = useState(false);
  const [emptyDate, setEmptyDate] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [aux, setAux] = useState(false);
  const [reporteHeader, setReporteHeader] = useState({
    start: "--/--/----",
    end: "--/--/----",
  });
  const [show, setShow] = useState(false);
  const today = new Date();

  const [getReporte, { data, loading }] = useLazyQuery(REPORTE_FUNES, {
    variables: {
      fechaInicial: startDate?.toISOString().slice(0, 10),
      fechaFinal: endDate?.toISOString().slice(0, 10),
      offset: currentPage * row - row,
      limit: row,
      countReporteFechaInicial2: null,
      countReporteFechaFinal2: null,
    },
  });

  function truncate(num, places) {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  const generarReporte = async () => {
    setInprogress(true);
    await getReporte({
      variables: {
        fechaInicial: startDate.toISOString().slice(0, 10),
        fechaFinal: endDate.toISOString().slice(0, 10),
        offset: currentPage * row - row,
        limit: row,
        countReporteFechaInicial2: startDate.toISOString().slice(0, 10),
        countReporteFechaFinal2: endDate.toISOString().slice(0, 10),
      },
    });
    setEmptyDate(false);
    setInprogress(false);
    setAux(true);
    setShow(false);
  };

  const handleQueryReporte = async () => {
    setReporteHeader({
      start: startDate
        .toISOString()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/"),
      end: endDate.toISOString().slice(0, 10).split("-").reverse().join("/"),
    });
    if (data?.getReporte?.length !== 0) {
      setDownloadEnabled(true);
    }
    setAux(false);
  };

  const downloadReporte = async () => {
    const { data: dataReporte } = await client.query({
      query: REPORTE_FUNES,
      variables: {
        fechaInicial: startDate.toISOString().slice(0, 10),
        fechaFinal: endDate.toISOString().slice(0, 10),
        offset: null,
        limit: null,
      },
    });
    const nombreReporte = `Reporte_Notificación_${today
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("")}`;
    ExportToReporte(dataReporte, nombreReporte);
  };

  const buscarDatos = () => {
    getReporte({
      variables: {
        fechaInicial: startDate ? startDate.toISOString().slice(0, 10) : null,
        fechaFinal: endDate ? endDate.toISOString().slice(0, 10) : null,
        offset: currentPage * row - row,
        limit: row,
        countReporteFechaInicial2: startDate
          ? startDate.toISOString().slice(0, 10)
          : null,
        countReporteFechaFinal2: endDate
          ? endDate.toISOString().slice(0, 10)
          : null,
      },
    });
  };

  useEffect(() => {
    if (aux) {
      handleQueryReporte();
    }
    // eslint-disable-next-line
  }, [aux]);

  useEffect(() => {
    setCurrentPage(1);
    buscarDatos();
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    buscarDatos();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (downloadEnabled) {
      setDownloadEnabled(false);
      setShow(true);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <>
      <h3 className="tituloPag">Reporte de Funes</h3>
      <Grid
        sx={{ padding: "5px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Reporte de funes
          </Typography>
        </Breadcrumbs>
      </Grid>
      <div className="card-box">
        <Grid item xs={12}>
          <label className="tituloSecPag2">Reporte de funes</label>
          <p className="labelForm2">
            Selecciona el rango de fechas y el formato para descargar el reporte
          </p>
        </Grid>
        <DaterangeComponent
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          {show && (
            <Grid item xs={11}>
              <div
                className="alert alert-warning d-flex align-items-center"
                role="alert"
              >
                <div className="divAlert">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M12.3077 3.29032C17.2136 3.29032 21.2411 7.16431 21.2411 12C21.2411 16.8101 17.2454 20.7097 12.3077 20.7097C7.37592 20.7097 3.37425 16.816 3.37425 12C3.37425 7.19343 7.36955 3.29032 12.3077 3.29032ZM12.3077 2C6.64334 2 2.05078 6.47915 2.05078 12C2.05078 17.5241 6.64334 22 12.3077 22C17.972 22 22.5645 17.5241 22.5645 12C22.5645 6.47915 17.972 2 12.3077 2ZM11.8325 6.83871H12.7828C13.065 6.83871 13.2905 7.06782 13.2787 7.34274L12.9892 14.1169C12.9781 14.3761 12.7594 14.5806 12.4933 14.5806H12.122C11.8559 14.5806 11.6372 14.3761 11.6261 14.1169L11.3366 7.34274C11.3249 7.06782 11.5503 6.83871 11.8325 6.83871ZM12.3077 15.3871C11.6681 15.3871 11.1496 15.8926 11.1496 16.5161C11.1496 17.1397 11.6681 17.6452 12.3077 17.6452C12.9472 17.6452 13.4657 17.1397 13.4657 16.5161C13.4657 15.8926 12.9472 15.3871 12.3077 15.3871Z"
                      fill="#FF8F00"
                    />
                  </svg>
                  <strong className="alertText">
                    El rango de fecha no corresponde a la información de la
                    grilla.
                  </strong>
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={1}>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
            >
              {inprogress ? (
                <PrimaryButton sx={{ marginRight: "13px" }} disabled>
                  <DescargarIcon />
                  Generando...
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  sx={{ margin: "7px 20px" }}
                  onClick={generarReporte}
                  disabled={
                    startDate === null ||
                    endDate === null ||
                    endDate < startDate ||
                    downloadEnabled
                  }
                >
                  <DescargarIcon />
                  Generar reporte
                </PrimaryButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="card-box">
        <>
          {loading ? (
            <SkeletonHistorial />
          ) : (
            <>
              {data?.getReporte === null ? (
                <div className="remesa-box tabla sin-remesa">
                  Error cargando data.
                </div>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {data?.getReporte?.length === 0 ? (
                        <p className="labelForm2">
                          Previsualización de descarga
                        </p>
                      ) : (
                        <>
                          <p className="labelForm2">
                            Reporte de Funes desde {reporteHeader.start} hasta{" "}
                            {reporteHeader.end}
                          </p>
                          <SecondaryButton
                            sx={{ margin: "0 20px 10px" }}
                            onClick={downloadReporte}
                            disabled={!downloadEnabled}
                          >
                            <DescargarIcon />
                            Descargar
                          </SecondaryButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {emptyDate ? (
                    <ResultadoTablas
                      titulo="No hay datos seleccionados"
                      subtitulo="Selecciona un rango de fecha para previsualizar los funes"
                      icono="icon-info"
                      boton={false}
                    />
                  ) : (
                    <>
                      {data?.getReporte?.length === 0 ? (
                        <ResultadoTablas
                          titulo="No hay resultados para tu búsqueda"
                          subtitulo="Seleccionar un nuevo rango de fechas"
                          icono="icon-info"
                          boton={false}
                        />
                      ) : (
                        <>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell className="headerTableIzquierdaSmall">
                                    {" "}
                                    Folio Fun
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className="WhiteNormal"
                                    sx={{ minWidth: "146px" }}
                                  >
                                    Tipo de Notificación <TooltipTipoFun />
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    N° de Remesa
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Estado
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Beneficiario
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Sexo
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Tipo Empleador
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Cotización pactada
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Empresa
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Región
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Canal
                                  </StyledTableCell>
                                  <StyledTableCell className="WhiteNormal">
                                    Fecha Notificación
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data?.getReporte?.map((e) => (
                                  <StyledTableRow key={e.Folio_FUN}>
                                    <TableCell className="headerTableIzquierdaSmall">
                                      {e.Folio_FUN}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.Tipo_Notificacion}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.numeroRemesa}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.Estado_Notificacion === null ? (
                                        ""
                                      ) : (
                                        <>
                                          {e.Estado_Notificacion ===
                                            "Por Enviar" && (
                                            <p className="estado porenviar">
                                              {e.Estado_Notificacion}
                                            </p>
                                          )}
                                          {e.Estado_Notificacion ===
                                            "Enviado" && (
                                            <p className="estado enviado">
                                              {e.Estado_Notificacion}
                                            </p>
                                          )}
                                          {e.Estado_Notificacion ===
                                            "Rechazado" && (
                                            <p className="estado rechazado">
                                              {e.Estado_Notificacion}
                                            </p>
                                          )}
                                          {e.Estado_Notificacion ===
                                            "Aceptado" && (
                                            <p className="estado ingresado">
                                              {e.Estado_Notificacion}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap celdaName">
                                      {e.Rut_Cotizante}
                                      <div className="Nametd">
                                        {e.Nombre_cotizante?.length > 40 ? (
                                          <TableTooltip
                                            rut={maxTextLength(
                                              toTitleCase(e.Nombre_cotizante),
                                              37
                                            )}
                                            tooltip={toTitleCase(
                                              e.Nombre_cotizante
                                            )}
                                          />
                                        ) : (
                                          toTitleCase(e.Nombre_cotizante)
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.Sexo}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.tipo_relacion}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {truncate(e.Cotizacion_Pactada, 3)}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap celdaName Nametd">
                                      {e.Rut_Empleador}
                                      <div className="Nametd">
                                        {e.Nombre_empleador?.length > 30 ? (
                                          <TableTooltip
                                            rut={maxTextLength(
                                              toTitleCase(e.Nombre_empleador),
                                              27
                                            )}
                                            tooltip={toTitleCase(
                                              e.Nombre_empleador
                                            )}
                                          />
                                        ) : (
                                          toTitleCase(e.Nombre_empleador)
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell className="WhiteNowrap celdaName Regiontd">
                                      <div className="Regiontd">
                                        <TableTooltip
                                          rut={e.Region_Empleador}
                                          tooltip={e.Comuna_Empleador}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.Nombre_Operador}
                                    </TableCell>
                                    <TableCell className="WhiteNowrap">
                                      {e.Fecha_Notificacion === null
                                        ? ""
                                        : moment(e.Fecha_Notificacion).format(
                                            "DD/MM/yyyy"
                                          )}
                                    </TableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Paginator
                            component="div"
                            rowsPerPageOptions={[10, 20, 50]}
                            count={parseInt(data?.countReporte) || 0}
                            rowsPerPage={row}
                            page={currentPage - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ mt: "32px", mb: "10px" }}
                            labelRowsPerPage={"Mostrar por página"}
                            labelDisplayedRows={(e) => {
                              return (
                                "" +
                                currentPage +
                                " de " +
                                Math.ceil((data?.countReporte || 0) / row)
                              );
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

export default ReporteFunes;
