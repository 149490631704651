import { useContext, useEffect, useState } from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import TableTooltip from "./../TableTooltip";
import { useLazyQuery, useMutation } from "@apollo/client";
import "../../styles/content.scss";
import "./Remesa.scss";
import "./ModalUpload.scss";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import { DETALLE_REMESA } from "../../graphql/queries";
import { DELETE_FUN_REMESA } from "../../graphql/mutations";
import SkeletonHistorial from "../Historial/SkeletonHistorial";
import "../Material/material.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TagEstado from "../TagEstado/TagEstado";
import Paginator from "../Paginator/Paginator";
import ModalDrag from "../DragDrop/components/ModalDrag";
import ToastMaterial from "../toast/ToastMaterial";

const Remesas = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRow] = useState(20);
  const history = useHistory();
  const { dataRemesa } = useContext(RemesaContext);
  const { fechaRemesa } = useContext(RemesaContext);
  const { remesaCerrada } = useContext(RemesaContext);
  const { uploadComplete, setUploadComplete } = useContext(RemesaContext);
  const [showModal, setShowModal] = useState(false);
  const [folio, setFolio] = useState(0);
  const [estado, setEstado] = useState("");
  const [motivo, setMotivo] = useState("");
  const [textarea, setTextarea] = useState("");
  const [tipoEliminacion, setTipoEliminacion] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [getData, { data, loading, error }] = useLazyQuery(DETALLE_REMESA, {
    variables: {
      offset: currentPage * row - row,
      limit: 20,
      idRemesa: null,
      countIdRemesa: null,
      remesaAsignada: 1,
      data: "Funes Sin Rendir",
      getTagsIdRemesa2: null,
    },
  });
  const [deleteFuneRemesa, { data: dataDelete }] = useMutation(
    DELETE_FUN_REMESA,
    {
      onCompleted: () => {
        setShowToast(true);
        getData({
          variables: {
            offset: currentPage * row - row,
            limit: 20,
            idRemesa: dataRemesa,
            countIdRemesa: dataRemesa,
            remesaAsignada: 1,
            data: "Funes Sin Rendir",
            getTagsIdRemesa2: dataRemesa,
          },
        });
      },
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [accion, setAccion] = useState({
    folio: null,
    estado: null,
  });

  const handleClickMenu = (event, folio: number, estado: string) => {
    setAnchorEl(event.currentTarget);
    setAccion({ ...accion, folio, estado });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const confirmDeleteFun = (folioFun: number, estadoFun: string) => {
    setShowModal(true);
    setFolio(folioFun);
    setEstado(estadoFun);
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  function handleCheck(e) {
    setMotivo(e.target.value);
    if (e.target.value === "1") {
      setTipoEliminacion("Empleador cambió de empresa notificadora");
    } else if (e.target.value === "2") {
      setTipoEliminacion("FUN es anulado");
    } else if (e.target.value === "3") {
      setTipoEliminacion("Otros");
    }
  }

  function handleChangeText(e) {
    setTextarea(e.target.value);
    if (e.target.value === "") {
      setTipoEliminacion("Otros");
    } else {
      setTipoEliminacion(e.target.value);
    }
  }

  function handleCloseModal() {
    setShowModal(false);
    setTextarea("");
    setMotivo("");
  }

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: 20,
        idRemesa: dataRemesa,
        countIdRemesa: dataRemesa,
        remesaAsignada: 1,
        data: "Funes Sin Rendir",
        getTagsIdRemesa2: dataRemesa,
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        idRemesa: dataRemesa,
        countIdRemesa: dataRemesa,
        remesaAsignada: 1,
        data: "Funes Sin Rendir",
        getTagsIdRemesa2: dataRemesa,
      },
    });
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        idRemesa: dataRemesa,
        countIdRemesa: dataRemesa,
        remesaAsignada: 1,
        data: "Funes Sin Rendir",
        getTagsIdRemesa2: dataRemesa,
      },
    });
    setUploadComplete(false);
    // eslint-disable-next-line
  }, [uploadComplete]);

  useEffect(() => {
    setCurrentPage(1);
    getData({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        idRemesa: dataRemesa,
        countIdRemesa: dataRemesa,
        remesaAsignada: 1,
        data: "Funes Sin Rendir",
        getTagsIdRemesa2: dataRemesa,
      },
    });
    // eslint-disable-next-line
  }, [row]);

  return (
    <Container className="content2" data-testid="container">
      <Grid container spacing={0} item xs={12}>
        <h3 className="tituloPag">Remesas</h3>
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <Breadcrumbs
            sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
            aria-label="breadcrumb"
            className="breadcrum"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="always"
              color="inherit"
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "400",
                margin: "0px,8px",
              }}
              onClick={() => {
                history.goBack();
              }}
              data-testid="buttonTobackPage"
            >
              Generación de Remesas
            </Link>
            <Typography
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "-1px",
                lineHeight: "24px",
              }}
            >
              Detalle de Remesas
            </Typography>
          </Breadcrumbs>
        </Grid>
        {loading ? (
          <Container className="content2" data-testid="loadingContainer">
            <SkeletonHistorial />
          </Container>
        ) : (
          <div className="card-box">
            {error || data?.getFunes === null ? (
              <div className="remesa-box tabla sin-remesa">
                Error cargando data.
              </div>
            ) : (
              <>
                <Grid
                  sx={{ padding: "5px", margin: "0", width: "100%" }}
                  container
                  spacing={2}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="estadosDivGroup2">
                      <label className="tituloSecPag2">
                        Detalle de remesa N°{dataRemesa + " "}
                      </label>
                      <div className="estadosDiv">
                        <TagEstado
                          data={data?.countFunes}
                          text={" en total: "}
                          estilo={"estado total"}
                        />
                        <TagEstado
                          data={data?.getTags[0]}
                          text={
                            (data?.getTags[0] === 1 ? "Fun" : "Funes") +
                            " sin rendir"
                          }
                          estilo={"estado enviado"}
                        />
                      </div>
                    </div>
                    {data?.getFunes[0]?.canal_notificacion === "Manual" && (
                      <ModalDrag
                        textButton={"Actualizar"}
                        modalTitle={"Actualización de remesa " + dataRemesa}
                        type={"remesa"}
                        rowRemesa={dataRemesa}
                        canalRemesa={"Manual"}
                        disabled={remesaCerrada}
                        setShowModal={setShowModal}
                      />
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <p className="labelForm2">
                      Canal: {" " + data?.getFunes[0]?.canal_notificacion}
                    </p>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <p className="labelFormColor">
                      Fecha de creación:{" " + fechaRemesa}
                    </p>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className="headerTableIzquierda">
                            Folio
                          </StyledTableCell>
                          <StyledTableCell>Estado</StyledTableCell>
                          <StyledTableCell>Fecha Firma Fun</StyledTableCell>
                          <StyledTableCell>Rut Afiliado</StyledTableCell>
                          <StyledTableCell>Rut Empresa</StyledTableCell>
                          <StyledTableCell>Razón Social</StyledTableCell>
                          {data?.getFunes[0]?.canal_notificacion ===
                            "Manual" && (
                            <StyledTableCell>Región</StyledTableCell>
                          )}
                          <StyledTableCell
                            align="center"
                            className="headerTableAcciones3"
                          >
                            Acciones
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.getFunes.map((e) => (
                          <StyledTableRow
                            key={e.folioFun}
                            data-testid="tableItem"
                          >
                            <TableCell className="headerTableIzquierda">
                              {e.folioFun}
                            </TableCell>
                            <TableCell>
                              <>
                                {e.estado_fun === "Por Enviar" && (
                                  <p className="estado porenviar">
                                    {e.estado_fun}
                                  </p>
                                )}
                                {e.estado_fun === "Enviado" && (
                                  <p className="estado enviado">
                                    {e.estado_fun}
                                  </p>
                                )}
                                {e.estado_fun === "Rechazado" && (
                                  <p className="estado rechazado">
                                    {e.estado_fun}
                                  </p>
                                )}
                                {e.estado_fun === "Aceptado" && (
                                  <p className="estado ingresado">
                                    {e.estado_fun}
                                  </p>
                                )}
                              </>
                            </TableCell>
                            <TableCell>
                              {e.fecha_contratacion
                                .split("-")
                                .reverse()
                                .join("/")}
                            </TableCell>
                            <TableCell>
                              <TableTooltip
                                rut={e.afiliado_rut}
                                tooltip={e.afiliado_nombre_completo}
                              />
                            </TableCell>
                            <TableCell>{e.empresa_rut}</TableCell>
                            <TableCell>{e.empresa_razon_social}</TableCell>
                            {e.canal_notificacion === "Manual" && (
                              <TableCell>{e.region}</TableCell>
                            )}
                            <TableCell
                              align="center"
                              className="headerTableAcciones3"
                            >
                              <MoreVertIcon
                                onClick={(event) =>
                                  handleClickMenu(
                                    event,
                                    e.folioFun,
                                    e.estado_fun
                                  )
                                }
                                sx={{ cursor: "pointer" }}
                                data-testid="buttonMore"
                              />
                              <Menu
                                className="menu-box"
                                sx={{
                                  boxShadow: "0px 2px 10px #C9CFE3!important",
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                              >
                                <MenuItem
                                  onClick={() =>
                                    confirmDeleteFun(
                                      accion.folio,
                                      accion.estado
                                    )
                                  }
                                  disabled={
                                    accion.estado !== "Enviado" &&
                                    accion.estado !== "Por Enviar"
                                  }
                                  data-testid="optionDelete"
                                >
                                  Eliminar folio
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Paginator
                      component="div"
                      rowsPerPageOptions={[10, 20, 50]}
                      count={parseInt(data?.countFunes) || 0}
                      rowsPerPage={row}
                      page={currentPage - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{ mt: "32px", mb: "10px" }}
                      labelRowsPerPage={"Mostrar por página"}
                      labelDisplayedRows={(e) => {
                        return (
                          "" +
                          currentPage +
                          " de " +
                          Math.ceil((data?.countFunes || 0) / row)
                        );
                      }}
                    />
                  </TableContainer>
                </Grid>
              </>
            )}
          </div>
        )}
      </Grid>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="modal-eliminar"
        data-testid="modalDelete"
      >
        {estado !== "Por Enviar" && (
          <Modal.Header closeButton data-testid="headerEnviado">
            <h4>Selecciona el motivo de eliminación</h4>
          </Modal.Header>
        )}
        <Modal.Body>
          {estado !== ("Por Enviar" || "") ? (
            <>
              <h5>Selecciona un motivo de eliminación</h5>
              <Form onChange={handleCheck}>
                <Form.Check
                  name="checkbox"
                  type="radio"
                  label="Empleador cambió de empresa notificadora"
                  value="1"
                  data-testid="checkbox1"
                />
                <Form.Check
                  name="checkbox"
                  type="radio"
                  label="FUN es anulado"
                  value="2"
                  data-testid="checkbox2"
                />
                <Form.Check
                  name="checkbox"
                  type="radio"
                  label="Otra causal:"
                  value="3"
                  data-testid="checkbox3"
                />
                <>
                  <Form.Control
                    as="textarea"
                    placeholder="Escribir alguna observación"
                    disabled={motivo === "1" || motivo === "2" || motivo === ""}
                    type="text"
                    maxLength={200}
                    value={textarea}
                    onChange={handleChangeText}
                    data-testid="textarea"
                  />
                  <div className="txt-motivo">
                    <span>Explica brevemente el motivo de eliminación</span>
                    <span>{textarea.length}/200</span>
                  </div>
                </>
              </Form>
            </>
          ) : (
            <>
              <Modal.Header
                className="alt-modal"
                closeButton
                data-testid="headerPorEnviar"
              ></Modal.Header>
              <div className="alt-eliminar">
                <h4>¿Seguro que quieres eliminar?</h4>
                <span>Vas a eliminar el fun N° {folio} de la remesa</span>
              </div>
              <div className="div-btn">
                <>
                  <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    className="btn-cancelar"
                    data-testid="buttonCancelDelete"
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-primario btn-eliminar"
                    onClick={() => {
                      deleteFuneRemesa({
                        variables: {
                          idRemesa: dataRemesa,
                          idFune: folio,
                          estado: estado,
                          tipoEliminacion: tipoEliminacion,
                        },
                      });
                      handleCloseModal();
                    }}
                    data-testid="buttonDelete"
                  >
                    Eliminar folio
                  </Button>
                </>
              </div>
            </>
          )}
        </Modal.Body>
        {estado !== "Por Enviar" && (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              className="btn-cancelar"
              data-testid="buttonCancelDelete2"
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              className="btn-primario"
              disabled={motivo === "" || (motivo === "3" && textarea === "")}
              onClick={() => {
                deleteFuneRemesa({
                  variables: {
                    idRemesa: dataRemesa,
                    idFune: folio,
                    estado: estado,
                    tipoEliminacion: tipoEliminacion,
                  },
                });
                handleCloseModal();
              }}
              data-testid="buttonDelete2"
            >
              Confirmar eliminación
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      {dataDelete?.deleteFuneRemesa?.code && (
        <ToastMaterial
          message={dataDelete?.deleteFuneRemesa?.message}
          showToast={showToast}
          className={
            dataDelete?.deleteFuneRemesa?.code === "01" ? "success" : "error"
          }
          setShowToast={setShowToast}
        />
      )}
    </Container>
  );
};

export default Remesas;
