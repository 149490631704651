import React from "react";
import "../../App.scss";

const SkeletonRemesa = () => {
  return (
    // <div className="skeleton hide-sk">
    <div className="skeleton">
      {/* <div className="post-sk reme-sk">
        <div className="title-sk"></div>
        <ul>
          <li>
            <div className="line-sk"></div>
            <div className="box-sk"></div>
          </li>
          <li>
            <div className="line-sk"></div>
            <div className="box-sk"></div>
          </li>
          <li>
            <div className="line-sk"></div>
            <div className="box-sk"></div>
          </li>
          <li>
            <div className="box-sk"></div>
          </li>
        </ul>
      </div> */}
      <div className="post-sk rem-sk">
        <div className="title-sk"></div>
        <ul>
          <li>
            <div className="ava-sk"></div>
            <div className="box-sk"></div>
            <div className="line-sk"></div>
            <div className="line-sk"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SkeletonRemesa;
