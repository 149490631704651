import React, { useEffect, useState } from "react";
import { TooltipInfo } from "../../Images/iconosEsencial";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";

const TooltipTipoFun = () => {
  let tipoFun = [
    {
      codigo: 0,
      descripcion: "0 - Sin Movimiento en el mes Informado.",
    },
    {
      codigo: 1,
      descripcion: "1 - Suscripción del Contrato.",
    },
    {
      codigo: 2,
      descripcion: "2 - Término de Contrato.",
    },
    {
      codigo: 3,
      descripcion: "3 - Situaciones Especiales.",
    },
    {
      codigo: 4,
      descripcion: "4 - Cambio de Empleador.",
    },
    {
      codigo: 5,
      descripcion: "5 - Cambio de Anualidad.",
    },
    {
      codigo: 6,
      descripcion: "6 - Variación de precio por cambio de tramo de edad.",
    },
    {
      codigo: 7,
      descripcion: "7 - Modificación de Carga.",
    },
    {
      codigo: 8,
      descripcion: "8 - Modificación de Cotización Pactada.",
    },
    {
      codigo: 9,
      descripcion: "9 - Cambio de Plan.",
    },
  ];
  const [estado, setEstado] = useState([]);
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#4D5D68",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4D5D68",
      fontSize: "14px",
      fontFamily: "Interstate",
      padding: "8px 12px 8px 12px",
      whiteSpace: "break-spaces",
    },
  }));

  const funcTipos = () => {
    let aux = [];
    let tipoNotificacion = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let i = 0; i < tipoNotificacion.length; i++) {
      for (let j = 0; j < tipoFun.length; j++) {
        if (tipoFun[j].codigo === tipoNotificacion[i]) {
          aux.push(tipoFun[j].descripcion + "\n");
        }
      }
    }
    setEstado(aux);
  };

  useEffect(() => {
    funcTipos();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {
        <BootstrapTooltip title={estado} arrow placement="right">
          <TooltipInfo className="iconTooltip" />
        </BootstrapTooltip>
      }
    </>
  );
};

export default TooltipTipoFun;
