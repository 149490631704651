import { useEffect, useState } from "react";
import { FUNES_RENOT } from "../../graphql/queries";
import { UPDATE_RENOT_MULTIPLE } from "../../graphql/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import SkeletonHistorial from "../Historial/SkeletonHistorial";
import SearchRenot from "./SearchRenot";
import SelectRenot from "./SelectRenot";
import { ModalRenot } from "./ModalRenot";
import ResultadoTablas from "../ResultadoTablas/ResultadoTablas";
import ToastMaterial from "../toast/ToastMaterial";
import TagEstado from "../TagEstado/TagEstado";
import Paginator from "../Paginator/Paginator";
import {
  Breadcrumbs,
  Grid,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Table,
  Checkbox,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Col, Container } from "react-bootstrap";
import { StyledTableCell, StyledTableRow } from "../Material/Table/table";
import "./../Table/Table.scss";
import "../Material/material.scss";
import TooltipTipoFun from "../Table/TooltipTipoFun";
import { maxTextLength, toTitleCase } from "../../utils/StringUtils";
import TableTooltip from "../TableTooltip";

const Renotificaciones = () => {
  const [row, setRow] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtro, setFiltro] = useState(null);
  const [dataFiltro, setDataFiltro] = useState({ filtro });
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [opcionRenot, setOpcionRenot] = useState(null);
  const [update, setUpdate] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [getRenot, { data, loading, error }] = useLazyQuery(FUNES_RENOT);
  const [updateRenotMulti, { data: dataMulti, loading: loadingMulti }] =
    useMutation(UPDATE_RENOT_MULTIPLE, {
      onCompleted: () => {
        setOpenModal(false);
        setShowToast(true);
        resetCheck();
        setUpdateTable(true);
      },
    });

  const handleCheck = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(
        isCheck.filter(function (i) {
          return i !== id;
        })
      );
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data?.getRenotificacion.map((e) => e.folioFun.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const resetCheck = () => {
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const filtroRenot = async () => {
    setCurrentPage(1);
    await getRenot({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        folio: dataFiltro?.filtro?.folio
          ? parseInt(dataFiltro?.filtro?.folio)
          : null,
        tipoNotificacion: dataFiltro?.filtro?.tipo
          ? dataFiltro?.filtro?.tipo
          : null,
        countRenotificacionFolio2: dataFiltro?.filtro?.folio
          ? parseInt(dataFiltro?.filtro?.folio)
          : null,
        countRenotificacionTipoNotificacion2: dataFiltro?.filtro?.tipo
          ? dataFiltro?.filtro?.tipo
          : null,
      },
    });
  };

  const buscarDatos = () => {
    getRenot({
      variables: {
        offset: currentPage * row - row,
        limit: row,
        folio: dataFiltro?.filtro?.folio
          ? parseInt(dataFiltro?.filtro?.folio)
          : null,
        tipoNotificacion: dataFiltro?.filtro?.tipo
          ? dataFiltro?.filtro?.tipo
          : null,
        countRenotificacionFolio2: dataFiltro?.filtro?.folio
          ? parseInt(dataFiltro?.filtro?.folio)
          : null,
        countRenotificacionTipoNotificacion2: dataFiltro?.filtro?.tipo
          ? dataFiltro?.filtro?.tipo
          : null,
      },
    });
  };

  const handleRenotMulti = (opcionRenot: number) => {
    let vec = [];
    for (let index = 0; index < isCheck.length; index++) {
      vec.push(parseInt(isCheck[index]));
    }
    updateRenotMulti({
      variables: {
        vectorFolio: vec,
        opcion: opcionRenot,
      },
    });
  };

  useEffect(() => {
    getRenot({
      variables: {
        offset: 0,
        limit: 20,
        folio: null,
        tipoNotificacion: null,
        countRenotificacionFolio2: null,
        countRenotificacionTipoNotificacion2: null,
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    buscarDatos();
    resetCheck();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    buscarDatos();
    resetCheck();
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    filtroRenot();
    resetCheck();
    // eslint-disable-next-line
  }, [dataFiltro]);

  useEffect(() => {
    if (updateTable) {
      setUpdateTable(false);
      buscarDatos();
    }
    // eslint-disable-next-line
  }, [updateTable]);

  useEffect(() => {
    if (update) {
      handleRenotMulti(opcionRenot);
      setUpdate(false);
    }
    // eslint-disable-next-line
  }, [update]);

  return (
    <div>
      <>
        <h3 className="tituloPag">Renotificación de funes</h3>
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <Breadcrumbs
            sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
            aria-label="breadcrumb"
            className="breadcrum"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="always"
              color="inherit"
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "400",
                margin: "0px,8px",
              }}
            >
              Inicio
            </Link>
            <Typography
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "-1px",
                lineHeight: "24px",
              }}
            >
              Renotificación de funes
            </Typography>
          </Breadcrumbs>
        </Grid>
        <SearchRenot dataFiltro={dataFiltro} setDataFiltro={setDataFiltro} />
        {loading ? (
          <SkeletonHistorial />
        ) : (
          <Container className="content2">
            <div className="containerTable">
              {error || data?.getRenotificacion === null ? (
                <div className="remesa-box tabla sin-remesa">
                  Error cargando data.
                </div>
              ) : (
                <>
                  {data?.getRenotificacion.length === 0 &&
                  dataFiltro?.filtro?.length === 0 ? (
                    <ResultadoTablas
                      titulo={
                        "No existen funes rechazados que se puedan renotificar"
                      }
                      subtitulo={null}
                      icono={"icon-info"}
                      boton={false}
                    />
                  ) : (
                    <TableContainer component={Paper} sx={{ minWidth: 650 }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginBottom: "10px" }}
                      >
                        <div className="estadosDivGroup">
                          <p className="labelForm2">Funes rechazados</p>
                          <ModalRenot
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            opcionRenot={opcionRenot}
                            setOpcionRenot={setOpcionRenot}
                            loading={loadingMulti}
                            setUpdate={setUpdate}
                          />
                          {data?.countRenotificacion !== 0 && (
                            <TagEstado
                              data={data?.countRenotificacion}
                              text={" en total"}
                              estilo={"estado total"}
                            />
                          )}
                        </div>
                      </Grid>
                      {data?.getRenotificacion.length === 0 &&
                      dataFiltro?.filtro?.length !== 0 ? (
                        <ResultadoTablas
                          titulo={"No encontramos coincidencias"}
                          subtitulo={
                            "No hay funes que gestionar con las características que seleccionaste."
                          }
                          icono={"icon-info"}
                          boton={false}
                        />
                      ) : (
                        <>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell sx={{ maxWidth: "10px" }}>
                                  <Checkbox
                                    onChange={handleSelectAll}
                                    checked={isCheckAll}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Folio
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Fecha firma Fun
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  RUT afiliado
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  RUT Empresa
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Tipo Notificación
                                  <TooltipTipoFun />
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  Estado de Funes
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data?.getRenotificacion.map((e) => (
                                <StyledTableRow key={e.folioFun}>
                                  <TableCell>
                                    <Checkbox
                                      id={e.folioFun.toString()}
                                      onChange={handleCheck}
                                      checked={isCheck.includes(
                                        e.folioFun.toString()
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    {e.folioFun}
                                  </TableCell>
                                  <TableCell align="left">
                                    {e.fecha_contratacion
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  </TableCell>
                                  <TableCell
                                    className="WhiteNowrap celdaName"
                                    align="left"
                                  >
                                    {e.afiliado_rut}
                                    <div className="Nametd">
                                      {e.afiliado_nombre_completo?.length >
                                      44 ? (
                                        <TableTooltip
                                          rut={maxTextLength(
                                            toTitleCase(
                                              e.afiliado_nombre_completo
                                            ),
                                            41
                                          )}
                                          tooltip={toTitleCase(
                                            e.afiliado_nombre_completo
                                          )}
                                        />
                                      ) : (
                                        toTitleCase(e.afiliado_nombre_completo)
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="WhiteNowrap celdaName Nametd"
                                    align="left"
                                  >
                                    {e.empresa_rut}
                                    <div className="Nametd">
                                      {e.empresa_razon_social?.length > 36 ? (
                                        <TableTooltip
                                          rut={maxTextLength(
                                            toTitleCase(e.empresa_razon_social),
                                            33
                                          )}
                                          tooltip={toTitleCase(
                                            e.empresa_razon_social
                                          )}
                                        />
                                      ) : (
                                        toTitleCase(e.empresa_razon_social)
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell align="left">
                                    {e.tipoNotificacion}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Col xs={3} md={5} lg={2}>
                                      <SelectRenot
                                        folio={e.folioFun}
                                        estado={e.estado}
                                      />
                                    </Col>
                                  </TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Paginator
                            data-testid="selectNumberTableRows"
                            component="div"
                            rowsPerPageOptions={[10, 20, 50]}
                            count={parseInt(data?.countRenotificacion) || 0}
                            rowsPerPage={row}
                            page={currentPage - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ mt: "32px", mb: "10px" }}
                            labelRowsPerPage={"Mostrar por página"}
                            labelDisplayedRows={(e) => {
                              return (
                                currentPage +
                                " de " +
                                Math.ceil(
                                  (data?.countRenotificacion || 0) / row
                                )
                              );
                            }}
                          />
                        </>
                      )}
                    </TableContainer>
                  )}
                </>
              )}
            </div>
            <ToastMaterial
              message={dataMulti?.updateRenotificarMulti?.message}
              showToast={showToast}
              className={
                dataMulti?.updateRenotificarMulti?.code === "01"
                  ? "success"
                  : "error"
              }
              setShowToast={setShowToast}
            />
            {isCheck.length > 1 && (
              <div className=" btn-float ">
                <ul>
                  <li>
                    <p data-testid="selectedItems">
                      Seleccionados: {isCheck.length}
                    </p>
                  </li>
                  <li>
                    <Button
                      className="btn-asignar icon-asignar"
                      data-testid="buttonEnable"
                      onClick={() => setOpenModal(true)}
                    >
                      Habilitar funes
                    </Button>
                  </li>
                </ul>
              </div>
            )}
          </Container>
        )}
      </>
    </div>
  );
};

export default Renotificaciones;
