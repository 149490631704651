/* istanbul ignore file */
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import ".././../Material/material.scss";

export default function LoadingButtons({ sx }) {
  return (
    <Stack direction="row" spacing={2}>
      <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        className="LoadingButton"
      >
        Habilitando Funes...
      </LoadingButton>
    </Stack>
  );
}
