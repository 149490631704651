import { gql } from "@apollo/client";

export const UPDATE_CANAL_NOTIFICACION = gql`
  mutation Mutation($updateChanelId: Int!, $fkCanalNotificacion: Int!) {
    updateChanel(
      id: $updateChanelId
      fkCanalNotificacion: $fkCanalNotificacion
    ) {
      code
      message
    }
  }
`;

export const UPDATE_CANAL_MULTIPLE = gql`
  mutation UpdateChanelMulti($vectorId: [Int], $fkCanalNotificacion: Int!) {
    updateChanelMulti(
      vectorId: $vectorId
      fkCanalNotificacion: $fkCanalNotificacion
    ) {
      code
      message
    }
  }
`;

export const UPDATE_ESTADO_REMESA = gql`
  mutation Mutation($estado: String!, $idRemesa: Int!, $fecha: String) {
    updateRemesa(estado: $estado, idRemesa: $idRemesa, fecha: $fecha) {
      code
      message
    }
  }
`;

export const ARCHIVO_CREATE_FUNES = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      code
      message
      dataError
    }
  }
`;

export const ARCHIVO_UPDATE_FUNES_XDATA = gql`
  mutation UpdateXData(
    $file: Upload!
    $rowRemesa: Int!
    $canalRemesa: String!
    $usuario: String!
    $type: String!
  ) {
    updateXData(
      file: $file
      rowRemesa: $rowRemesa
      canalRemesa: $canalRemesa
      usuario: $usuario
      type: $type
    ) {
      code
      message
      dataError
    }
  }
`;

export const CREATE_REMESA = gql`
  mutation Mutation($canal: Int!, $estado: String!, $region: Int!) {
    insertRemesa(canal: $canal, estado: $estado, region: $region) {
      message
      code
      remesa
    }
  }
`;

export const DELETE_FUN_REMESA = gql`
  mutation DeleteFuneRemesa(
    $idRemesa: Int!
    $idFune: Int!
    $estado: String!
    $tipoEliminacion: String!
  ) {
    deleteFuneRemesa(
      idRemesa: $idRemesa
      idFune: $idFune
      estado: $estado
      tipoEliminacion: $tipoEliminacion
    ) {
      code
      message
    }
  }
`;

export const UPDATE_EMPLEADOR = gql`
  mutation UpdateEmpleador(
    $rut: Int
    $calle: String
    $numero: String
    $depto: String
    $telefono: Int
    $correo: String
    $comuna: Int
    $operador: Int
  ) {
    updateEmpleador(
      rut: $rut
      calle: $calle
      numero: $numero
      depto: $depto
      telefono: $telefono
      correo: $correo
      comuna: $comuna
      operador: $operador
    ) {
      code
      message
    }
  }
`;

export const UPDATE_EMPLEADOR2 = gql`
  mutation UpdateEmpleador(
    $rut: Int
    $calle: String
    $numero: String
    $depto: String
    $telefono: Int
    $correo: String
    $comuna: Int
    $operador: Int
    $estado: Int
  ) {
    updateEmpleador(
      rut: $rut
      calle: $calle
      numero: $numero
      depto: $depto
      telefono: $telefono
      correo: $correo
      comuna: $comuna
      operador: $operador
    ) {
      code
      message
    }
    updateEmpleadorComuna(estado: $estado) {
      code
      message
    }
  }
`;

export const UPDATE_CONVENIO = gql`
  mutation UploadConvenio(
    $file: Upload!
    $usuario: String
    $archivo: String
    $extension: String
  ) {
    uploadConvenio(
      file: $file
      usuario: $usuario
      archivo: $archivo
      extension: $extension
    ) {
      code
      message
    }
  }
`;

export const UPDATE_RENOT = gql`
  mutation UpdateRenotificar($folio: Int!, $opcion: Int!) {
    updateRenotificar(folio: $folio, opcion: $opcion) {
      code
      message
    }
  }
`;

export const UPDATE_RENOT_MULTIPLE = gql`
  mutation UpdateRenotificarMulti($vectorFolio: [Int], $opcion: Int!) {
    updateRenotificarMulti(vectorFolio: $vectorFolio, opcion: $opcion) {
      code
      message
    }
  }
`;
