import { FC, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import { UPDATE_ESTADO_REMESA } from "../../graphql/mutations";
import ToastMaterial from "../toast/ToastMaterial";

registerLocale("es", es);

type FechaProps = {
  id: number;
  estado: string;
  fecha: string;
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DatepickerComponent: FC<FechaProps> = ({
  id,
  estado,
  fecha,
  showToast,
  setShowToast,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [addDate, { data }] = useMutation(UPDATE_ESTADO_REMESA);
  const today = new Date();
  const { estadoRemesaContext, setEstadoRemesaContext } =
    useContext(RemesaContext);

  function handleDateSelect(startDatea) {
    addDate({
      variables: {
        idRemesa: id,
        estado: estado,
        fecha: startDatea,
      },
    });
    setShowToast(true);
    setTimeout(() => {
      setEstadoRemesaContext("2");
    }, 3000);
  }

  useEffect(() => {
    setEstadoRemesaContext("0");
    // eslint-disable-next-line
  }, [estadoRemesaContext]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [startDate]);

  return (
    <>
      <DatePicker
        data-testid="datepicker"
        locale="es"
        placeholderText={moment(startDate).format("DD/MM/yyyy")}
        dateFormat="dd/MM/yyyy"
        className="calendario-remesa"
        onSelect={handleDateSelect}
        onChange={(date) => setStartDate(date)}
        minDate={new Date(moment(fecha).format("yyyy/MM/DD"))}
        maxDate={today}
      />
      {data?.updateRemesa?.code && (
        <ToastMaterial
          message={data?.updateRemesa?.message}
          showToast={showToast}
          className={data?.updateRemesa?.code === "01" ? "success" : "error"}
          setShowToast={setShowToast}
        />
      )}
    </>
  );
};

export default DatepickerComponent;
