/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React from "react";
import {
  CircularProgress,
  Dialog,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "./../Modales/Modales.css";
import Button from "@mui/material/Button";
import PrimaryButton from ".././../Botones";
import {
  ErrorModalIcon,
  ExitoModalIcon,
  InfoNotIcon,
} from "../../../Images/iconosEsencial";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
}));

const styledModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "930px",
  maxWidth: "940px",
  height: "530px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px #364855",
  fontFamily: "interstate",
};

export const ModalMaterial = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        data-testid="btnOpenModal"
        variant="
        text"
        color="default"
        onClick={() => setOpen(true)}
      >
        Abrir Modal Error
      </Button>

      {/*Modal ERROR 1*/}
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogContent sx={{ padding: "20px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0" }}
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0" }}
            >
              <ErrorModalIcon
                sx={{ width: "42px", height: "42px" }}
              ></ErrorModalIcon>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
                No podemos cargar el documento
              </DialogTitle>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography className="contentDialog">
                  {" "}
                  El archivo que quieres cargar no contiene la información
                  mínima para entrar al sistema, te recomendamos revisar que los
                  campos requeridos estén completos y en el formato adecuado.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                <Typography className="contentDialog">
                  Corrige esta información directamente en el documento, y
                  vuelve a intentarlo.
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>

          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PrimaryButton>Entendido</PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/*Modal ERROR 2*/}
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={false}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogContent sx={{ padding: "20px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0" }}
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0" }}
            >
              <ErrorModalIcon
                sx={{ width: "42px", height: "42px" }}
              ></ErrorModalIcon>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <DialogTitle
                className="tituloModal"
                sx={{ paddingBottom: "10px" }}
              >
                No podemos cargar el documento
              </DialogTitle>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={12}>
                <div
                  className="alert alert-warning d-flex align-items-center"
                  role="alert"
                >
                  <div className="divAlert">
                    <InfoNotIcon></InfoNotIcon>
                    <strong className="alertText">
                      <List>
                        El archivo contiene casillas vacías
                        <ListItem>● Fila 3, columna Nombre de empresa</ListItem>
                        <ListItem>● Fila 13, columna RUT de empresa</ListItem>
                      </List>
                    </strong>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                <Typography className="contentDialogSmall">
                  Corrige esta información directamente en el documento, y
                  vuelve a intentarlo
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>

          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PrimaryButton>Entendido</PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/*Modal Loading 3*/}
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={false}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogContent sx={{ padding: "20px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0" }}
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              data-testid="dialogModal"
              sx={{ m: 1, textAlign: "center", padding: "0", height: "350px" }}
            >
              <CircularProgress className="spinnerGreen"></CircularProgress>
              <Typography className="contentDialogLoading">
                Cargando documento
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/*Modal Carga exitosa*/}
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
          data-testid="dialogModal"
          sx={{ m: 1, textAlign: "center", padding: "5px 15px" }}
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          data-testid="dialogModal"
          sx={{ m: 1, textAlign: "center", padding: "0" }}
        >
          <ExitoModalIcon
            sx={{ width: "42px", height: "42px" }}
          ></ExitoModalIcon>
        </Grid>
        <DialogTitle className="tituloModal" sx={{ padding: "10px 0" }}>
          Carga exitosa
        </DialogTitle>
        <DialogContent sx={{ padding: "10px 0px!important" }}>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PrimaryButton>Aceptar</PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
