/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  MenuItem,
  Typography,
  FormControl,
  Select,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "./../Material/Modales/Modales.css";
import PrimaryButton, { SecondaryButton } from "../Botones";
import { CustomInputSelect, menuItemStyle } from "../select/styledSelect";
import LoadingButtons from "../Material/Botones/BotonLoader";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
}));

const styledModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "930px",
  maxWidth: "940px",
  height: "530px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px #364855",
  fontFamily: "interstate",
};

export const ModalRenot = ({
  openModal,
  setOpenModal,
  opcionRenot,
  setOpcionRenot,
  loading,
  setUpdate,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
    setOpcionRenot(null);
  };

  const handleConfirm = () => {
    if (opcionRenot === 1 || opcionRenot === 2) {
      setUpdate(true);
    }
  };

  useEffect(() => {
    if (openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  return (
    <div>
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogContent sx={{ padding: "20px", width: "604px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <DialogTitle className="tituloModal" sx={{ padding: "20px 0 0" }}>
                Habilitar Funes
              </DialogTitle>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={12} sx={{ padding: "20px 0" }}>
                <Typography className="contentDialog">
                  Haz clic en la lista y selecciona “Habilitar Funes” para
                  cambiar su estado
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ padding: "20px 0" }}>
              <FormControl sx={{ width: "99%", margin: "0 1% 0 0" }}>
                <Select
                  sx={{ width: "100%" }}
                  input={<CustomInputSelect />}
                  value={opcionRenot ? opcionRenot : 10}
                  onChange={(e) => setOpcionRenot(parseInt(e.target.value))}
                  placeholder="Selecciona"
                  disabled={loading}
                >
                  <MenuItem value="10" sx={menuItemStyle} hidden>
                    Selecciona
                  </MenuItem>
                  <MenuItem value="2" sx={menuItemStyle}>
                    Habilitar Funes
                  </MenuItem>
                  <MenuItem value="1" sx={menuItemStyle}>
                    Deshabilitar Funes
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <SecondaryButton
                sx={{ marginRight: "20px" }}
                onClick={handleClose}
              >
                Cancelar
              </SecondaryButton>
              {!loading ? (
                <PrimaryButton
                  sx={{ width: "211px" }}
                  disabled={!opcionRenot}
                  onClick={handleConfirm}
                >
                  Confirmar Selección
                </PrimaryButton>
              ) : (
                <LoadingButtons sx={null} />
              )}
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
