/* istanbul ignore file */
import { styled } from "@mui/material/styles";
import { TableCell, tableCellClasses, TableRow } from "@mui/material";

export const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    width: "300px",
    backgroundColor: "#F9FAFB",
    color: "#364855",
    fontFamily: "Interstate",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.0120588em",
    margin: "0px 4px",
    borderRadius: "0px",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    backgroundColor: "#FFFFFF",
  },
});

export const StyledTableRow = styled(TableRow)({
  backgroundColor: "#FFFFFF",
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    fontFamily: "Interstate",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: " 0.0120588em",
    color: "#203442",
    borderRadius: "0px",
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: "24px",
    paddingRight: "38px",
  },
  "&:hover": {
    backgroundColor: "#E6F6F5",
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: "transparent",
  },
});

export const StyledTableRowColl = styled(TableRow)({
  backgroundColor: "#E9EBEC",
  "&:nth-of-type(odd)": {
    backgroundColor: "#E9EBEC",
    fontFamily: "Interstate",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: " 0.0120588em",
    color: "#4C4C4C",
    borderRadius: "0px",
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: "24px",
    paddingRight: "38px",
  },
  "&:hover": {
    backgroundColor: "#E6F6F5",
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: "transparent",
  },
});

export const StyledTableCellFixed = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#67737C",
    fontFamily: "interstate",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.0120588em",
    margin: "0px 4px",
    borderRadius: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    backgroundColor: "#FFFFFF",
  },
});

export const StyledTableRowFixed = styled(TableRow)({
  backgroundColor: "#FFFFFF",
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    fontFamily: "interstate",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: " 0.0120588em",
    color: "#4C4C4C",
    borderRadius: "0px",
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: "24px",
    paddingRight: "38px",
  },
  "&:hover": {
    backgroundColor: "#E6F6F5",
  },
});

