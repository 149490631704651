import { useState, useEffect } from "react";
import "./EmpresasConvenio.scss";
import "./../Material/material.scss";
import "../Historial/HistoricoFunes.scss";
import { StyledTableCell, StyledTableRow } from "./../Material/Table/table";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonHistorial from "../Historial/SkeletonHistorial";
import Paginator from "../Paginator/Paginator";
import { HISTORIAL_CONVENIO } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";

const HistorialConvenios = () => {
  const [row, setRow] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateUpdate, setDateUpdate] = useState(null);
  const [getData, { data, loading, error }] = useLazyQuery(HISTORIAL_CONVENIO, {
    variables: {
      offset: currentPage * row - row,
      limit: row,
    },
  });

  const handleChangeRowsPerPage = (event: any) => {
    setRow(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage + 1);
  };

  const fileOperador = (filename) => {
    let filetype = filename.split(".").pop();
    if (filetype === "xlsx") {
      return "X-Data";
    } else if (filetype === "xml") {
      return "Previred";
    } else {
      return "---";
    }
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.getHistorial !== null && currentPage === 1) {
      let lastUpdate = data?.getHistorial[0].createdAt;
      if (lastUpdate !== dateUpdate) {
        setDateUpdate(moment(new Date(lastUpdate)).format("DD/MM/yyyy"));
      }
    }
    // eslint-disable-next-line
  }, [data]);

  if (loading) return <SkeletonHistorial />;

  return (
    <div className="containerTable">
      {error || data?.getHistorial === null ? (
        <div className="remesa-box tabla sin-remesa">Error cargando data.</div>
      ) : (
        <TableContainer component={Paper}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ width: "40%", float: "left", marginBottom: "15px" }}
            >
              <p className="labelFormTitle2">
                Historial de Rendición de Convenios
              </p>
              <Grid item xs={12}>
                <p className="labelForm3">Última actualización {dateUpdate}</p>
              </Grid>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  Fecha de rendición
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ minWidth: "400px" }}>
                  Nombre del archivo
                </StyledTableCell>
                <StyledTableCell align="left">
                  Tipo de convenio rendido
                </StyledTableCell>
                <StyledTableCell align="left">Usuario</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getHistorial.map((e) => (
                <StyledTableRow key={e.id}>
                  <TableCell>
                    {moment(new Date(e.createdAt)).format("DD/MM/yyyy")}
                  </TableCell>
                  <TableCell>{e.nombre}</TableCell>
                  <TableCell>{fileOperador(e.nombre)}</TableCell>
                  <TableCell>{e.responsable}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Paginator
            data-testid="selectNumberTableRows"
            component="div"
            rowsPerPageOptions={[10, 20, 50]}
            count={parseInt(data?.countHistorial) || 0}
            rowsPerPage={row}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: "32px", mb: "10px" }}
            labelRowsPerPage={"Mostrar por página"}
            labelDisplayedRows={(e) => {
              return (
                "" +
                currentPage +
                " de " +
                Math.ceil((data?.countHistorial || 0) / row)
              );
            }}
          />
        </TableContainer>
      )}
    </div>
  );
};

export default HistorialConvenios;
