/* istanbul ignore file */
import { Grid } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FileHeader } from "./components/FileHeader";
import "./components/MultipleFileUpload.css";
import { IconSubirGray } from "../../Images/iconosEsencial";

export function DragAndDrop(props) {
  const [errorDocs, setErrorDocs] = useState([]);
  let listArchivos = [];
  // if (props.files !== undefined && props.files !== listArchivos) {
  //   listArchivos = props.files;
  // }

  const onDrop = (accFiles, rejFiles) => {
    let errorArchivos = errorDocs;
    if (props.maxFiles === undefined || props.maxFiles > listArchivos.length) {
      accFiles.forEach((file) => {
        listArchivos[0] = { file, errors: [] };
      });
      rejFiles.forEach((r) => (errorArchivos[0] = { ...r }));
    }
    props.setFiles(listArchivos);
    if (rejFiles.length !== 0) {
      setErrorDocs(errorArchivos);
    } else {
      props.setFiles(listArchivos);
      setErrorDocs([]);
    }
  };

  function Delete(file) {
    listArchivos = props.files.filter((fw) => fw !== file);
    props.setFiles(listArchivos);
  }

  function Drop(item) {
    setErrorDocs([]);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: props.maxFiles,
    multiple: false,
    disabled: props.disabled,
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/xml",
    ],
  });

  useEffect(() => {
    if (props.clear) props.setClear(false);
    props.setFiles([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clear]);

  return (
    <Fragment>
      <Grid item>
        <div {...getRootProps({ className: "DragDropBackground" })}>
          <input {...getInputProps()} />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconSubirGray />
            <p className="seleccionaLabel" data-testid="dropTest">
              Selecciona
            </p>
            <p className="arrastraLabel">ó arrastra tu archivo aqui</p>
          </Grid>
        </div>
      </Grid>
      {props.files
        ? props.files.map((fileWrapper, index) => (
            <Grid item key={index}>
              <FileHeader
                onDelete={Delete}
                object={fileWrapper}
                error={fileWrapper.errors}
              />
            </Grid>
          ))
        : null}
      {errorDocs.length > 0
        ? errorDocs.map((fileWrapper, index) => (
            <Grid item key={index}>
              <FileHeader
                onDelete={Drop}
                object={fileWrapper}
                error={fileWrapper.errors}
              />
            </Grid>
          ))
        : null}
    </Fragment>
  );
}
