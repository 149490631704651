/* istanbul ignore file */
import { toXML } from "jstoxml";
import { saveAs } from "file-saver";
import { removeAccents } from "./StringUtils";

const config = {
  indent: "  ",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (previredData, fileName) => {
  const funesXml = previredData?.getPreviRed.map((e) => {
    let datos = {
      FUN: {
        folio: e.folio,
        seccionA: {
          tipoNotificacion:
            e.tipo_notificacion === null
              ? ""
              : e.tipo_notificacion.split(",").join(""),
        },
        seccionB: {
          datosCotizante: {
            rutCotizante: e.rut_afiliado,
            dvCotizante: e.digito_verificador_afiliado,
            apPaterno: removeAccents(e.apell_pat),
            apMaterno: removeAccents(e.apell_mat),
            nombres: removeAccents(e.nombre),
          },
        },
        seccionC: {
          rut: e.rut_org,
          dv: e.digito_verificador,
        },
        seccionD: {
          totalPagar: {
            cotTotalPagarA: e.codTotalPagarA,
            modCotTotalPagarA: e.modCotTotalPagarA,
            cotTotalPagarB: e.codTotalPagarB === null ? "" : e.codTotalPagarB,
            modCotTotalPagarB:
              e.modCotTotalPagarB === null ? "" : e.modCotTotalPagarB,
          },
          otros: {
            nroInscPens: "",
          },
        },
        imagen: {
          url: e.url === null ? "" : e.url,
          archivo: e.archivo === null ? "" : e.archivo,
        },
      },
    };
    return datos;
  });
  let contentXml = {
    cargaFUN: {
      FUNES: funesXml,
    },
  };
  toXML(contentXml, config);
  const blob = new Blob([toXML(contentXml, config)], {
    type: "application/xml",
  });
  saveAs(blob, fileName);
};
