/* eslint-disable react-hooks/rules-of-hooks */
/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/auth/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
const msalInstance = new PublicClientApplication(msalConfig);
const { REACT_APP_API_URL, REACT_APP_AZURE_SPA_API_SCOPE } = process.env;
const uploadLink = createUploadLink({
  uri: `${REACT_APP_API_URL}`,
  fetchOptions: {
    mode: "cors",
    AbortSignal: new AbortController().signal,
  },
});

async function getToken() {
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: [REACT_APP_AZURE_SPA_API_SCOPE],
    account: account,
  };
  const tokenResponse = await msalInstance.acquireTokenSilent(
    accessTokenRequest
  );
  return tokenResponse.accessToken;
}

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
        },
      },
    },
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ApolloProvider client={client}>
        <App />
        <link
          rel="stylesheet"
          href="https://use.typekit.net/muo3elz.css"
        ></link>
      </ApolloProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
