/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Verifica si un String es vacia ("") o undefined, ej:
 *
 * StringUtils.isEmpty(null)      = true
 * StringUtils.isEmpty("")        = true
 * StringUtils.isEmpty(" ")       = false
 * StringUtils.isEmpty("bob")     = false
 * StringUtils.isEmpty("  bob  ") = false
 *
 * @param str
 * @returns
 */
export function isEmpty(str: string = ""): boolean {
  return str === undefined || 0 === str.length;
}

/**
 * Verifica si un String es blanca, vacia ("") o undefined, ej:
 *
 * StringUtils.isBlank(null)      = true
 * StringUtils.isBlank("")        = true
 * StringUtils.isBlank(" ")       = true
 * StringUtils.isBlank("bob")     = false
 * StringUtils.isBlank("  bob  ") = false
 * @param str
 * @returns
 */
export function isBlank(str: string = ""): boolean {
  return str === undefined || /^\s*$/.test(str);
}

/**
 * Normaliza espacios en blanco, ej:
 *
 * 'hola    pepito' -> 'hola pepito'
 *
 * @param str
 * @returns
 */
export function normalizeWhiteSpaces(str: string = ""): string {
  return str.toString().trim().replace(/\s+/gi, " ");
}

/**
 * Transforma un texto que podria ser un boolean a un valor del tipo de dato boolean
 * @param value
 * @param defaultValue
 * @returns
 */
export function toBoolean(
  value: any = "",
  defaultValue: boolean = false
): boolean {
  if (typeof value == "boolean") {
    return value;
  }
  const tmp = value.toString().trim();
  if (tmp === "true") {
    return true;
  } else if (tmp === "false") {
    return false;
  }
  return defaultValue;
}

/**
 * Crea una mascara de un string, util para mostrar claves en los logs..
 * @param val
 * @returns
 */
export function mask(val: string = "") {
  if (val.length <= 3) return "*";
  return val.substring(0, val.length / 3).padEnd(val.length, "*");
}

export function toTitleCase(str) {
  return str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function hasWhiteSpace(s) {
  return s.includes(" ");
}

export const maxTextLength = (text, max) => {
  if (!hasWhiteSpace(text)) {
    if (max > 20) {
      return text.substring(0, 17) + "...";
    } else {
      return text;
    }
  } else if (text.length > max) {
    return text.substring(0, max) + "...";
  } else {
    return text;
  }
};

//Reemplaza acento grave y remueve apostrofes.
export function removeAccents(str) {
  return str
    .normalize("NFD")
    .replace(/\u0301|\u0303/g, "")
    .replace(/[']/g, " ");
}

export default {
  isEmpty,
  isBlank,
  normalizeWhiteSpaces,
  toBoolean,
  mask,
  toTitleCase,
  maxTextLength,
  removeAccents,
};
