import React, { useEffect, useState, useContext } from "react";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  EMPRESAS_COMUNA,
  EMPRESAS_COMUNA_PROVINCIA_REGION,
  EMPRESAS_DETALLE,
} from "../../graphql/queries";
import { UPDATE_EMPLEADOR } from "../../graphql/mutations";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
  Breadcrumbs,
  Box,
} from "@mui/material";
import ThemeTextField from "./../../components/textField";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PrimaryButton, { SecondaryButton } from "./../Botones";
import FormControl from "@mui/material/FormControl";
import {
  CustomInputSelect,
  menuItemStyle,
  menuProps,
} from "../select/styledSelect";
import SkeletonForm from "../Historial/SkeletonForm";
import "./EmpresasConvenio.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import ToastMaterial from "../toast/ToastMaterial";

const validationSchema = yup.object({
  numero: yup
    .string()
    .matches(/^[0-9]+$/, "Ingresa sólo números")
    .max(9, "Máximo 9 dígitos")
    .nullable(),
  email: yup.string().email("Correo no válido").nullable(),
  telefono: yup
    .string()
    .matches(/^[0-9]+$/, "Ingresa sólo números")
    .max(9, "Máximo 9 dígitos")
    .nullable(),
  calle: yup.string().max(100, "Máximo 100 caracteres").nullable(),
  depto: yup.string().max(20, "Máximo 20 caracteres").nullable(),
});

const EditarEmpresas = () => {
  const history = useHistory();
  const { rutEmpresa } = useContext(RemesaContext);
  const [comuna, setComuna] = useState(null);
  const [region, setRegion] = useState("");
  const [provincia, setProvincia] = useState("");
  const [comunaAux, setComunaAux] = useState(null);
  const [comunaId, setComunaId] = useState(null);
  const [comunaIdAux, setComunaIdAux] = useState(null);
  const [calle, setCalle] = useState(null);
  const [numero, setNumero] = useState(null);
  const [depto, setDepto] = useState(null);
  const [correo, setCorreo] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [operador, setOperador] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const [options, setOptions] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [aux, setAux] = useState(false);
  const loading = open && options.length === 0;
  const [errorComuna, setErrorComuna] = useState(false);
  const [checkInput, setCheckInput] = useState(false);
  const [selectOption, setSelectOption] = useState(false);
  const [inputUpdated, setInputUpdated] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const formik = useFormik({
    initialValues: {
      numero: numero,
      email: correo,
      telefono: telefono,
      calle: calle,
      depto: depto,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
    },
  });

  const [getDetalleEmpresa, { data: dataEmpresa, loading: loadingDetalle }] =
    useLazyQuery(EMPRESAS_DETALLE);
  const [getDataComuna, { data: dataComuna }] = useLazyQuery(EMPRESAS_COMUNA);
  const [getComunaId, { data: dataComunaId }] = useLazyQuery(
    EMPRESAS_COMUNA_PROVINCIA_REGION
  );
  const [updateEmpleador, { data: dataUpdate }] = useMutation(
    UPDATE_EMPLEADOR,
    {
      onCompleted: () => {
        setShowToast(true);
        setIsDisabled(true);
      },
    }
  );

  const handleOnChange = async (newValue, reason) => {
    if (reason === "clear") {
      setAux(true);
      setOptions([]);
      setIsDisabled(true);
    }
    if (reason === "selectOption") {
      setComunaId(newValue.comuna_id);
      setComuna(newValue.comuna);
      setIsDisabled(false);
      setSelectOption(true);
    }
  };

  const handleOnInputChange = async (newValue, reason) => {
    setInputValue(newValue);
    if (newValue !== comuna) {
      setInputUpdated(true);
      setIsDisabled(false);
    }
    setSelectOption(false);
    if (reason === "clear") {
      setAux(true);
      setOptions([]);
    }
    if (newValue.length >= 3) {
      await getDataComuna({
        variables: { comuna: newValue },
      });
      if (dataComuna !== undefined) {
        setOptions([...dataComuna.getComunas]);
      }
    }
  };

  const handleOperadorChange = (e) => {
    setOperador(e.target.value);
    setIsDisabled(false);
  };

  const updateEmpresa = () => {
    if (dataEmpresa?.getEmpleador?.rut !== undefined) {
      updateEmpleador({
        variables: {
          rut: dataEmpresa?.getEmpleador?.rut,
          calle: calle,
          numero: numero,
          depto: depto,
          telefono: parseInt(telefono),
          correo: correo,
          comuna:
            comunaId === null ? dataComuna?.getComunas[0]?.comuna_id : comunaId,
          operador:
            operador === null
              ? parseInt(dataEmpresa?.getEmpleador?.operador)
              : operador == 0
              ? null
              : parseInt(operador),
        },
      });
    }
  };

  const resetFields = () => {
    setCalle(dataEmpresa?.getEmpleador?.calle);
    setNumero(dataEmpresa?.getEmpleador?.numero);
    setDepto(dataEmpresa?.getEmpleador?.depto);
    dataEmpresa?.getEmpleador?.telefono === null
      ? setTelefono("")
      : dataEmpresa?.getEmpleador?.telefono?.length > 9
      ? setTelefono(dataEmpresa?.getEmpleador?.telefono.slice(-9))
      : setTelefono(dataEmpresa?.getEmpleador?.telefono);
    setCorreo(dataEmpresa?.getEmpleador?.correo);
    setComunaId(comunaIdAux);
    setComuna(comunaAux);
    setOperador(parseInt(dataEmpresa?.getEmpleador?.operador));
    setIsDisabled(true);
    setErrorComuna(false);
    setInputValue(comunaAux);
  };

  const handleBlur = () => {
    setCheckInput(true);
  };

  useEffect(() => {
    if (rutEmpresa !== null) {
      getDetalleEmpresa({
        variables: {
          rut: rutEmpresa,
        },
      });
    }
    setComunaAux(null);
    setComunaIdAux(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (checkInput === true) {
      if (selectOption === false && inputUpdated === true) {
        setErrorComuna(true);
      } else {
        setErrorComuna(false);
      }
    }
    setCheckInput(false);
    // eslint-disable-next-line
  }, [checkInput]);

  useEffect(() => {
    let errorValidation = [];
    if (Boolean(formik.errors.telefono)) {
      errorValidation.push(formik.errors.telefono);
    }
    if (Boolean(formik.errors.numero)) {
      errorValidation.push(formik.errors.numero);
    }
    if (Boolean(formik.errors.email)) {
      errorValidation.push(formik.errors.email);
    }
    if (Boolean(formik.errors.calle)) {
      errorValidation.push(formik.errors.calle);
    }
    if (Boolean(formik.errors.depto)) {
      errorValidation.push(formik.errors.depto);
    }
    if (errorValidation.length !== 0) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
    // eslint-disable-next-line
  }, [formik.errors]);

  useEffect(() => {
    if (dataEmpresa?.getEmpleador !== undefined) {
      setComuna(dataEmpresa?.getEmpleador?.comuna);
      setCalle(dataEmpresa?.getEmpleador?.calle);
      setNumero(dataEmpresa?.getEmpleador?.numero);
      setDepto(dataEmpresa?.getEmpleador?.depto);
      setCorreo(dataEmpresa?.getEmpleador?.correo);
      dataEmpresa?.getEmpleador?.telefono?.length > 9
        ? setTelefono(dataEmpresa?.getEmpleador?.telefono.slice(-9))
        : setTelefono(dataEmpresa?.getEmpleador?.telefono);
      getDataComuna({
        variables: {
          comuna: dataEmpresa?.getEmpleador?.comuna,
        },
      });
    }
    // eslint-disable-next-line
  }, [dataEmpresa]);

  useEffect(() => {
    if (dataEmpresa?.getEmpleador !== undefined) {
      getDataComuna({
        variables: {
          comuna: comuna,
        },
      });
      getComunaId({
        variables: { comunaId: comunaId },
      });
    }
    // eslint-disable-next-line
  }, [comuna]);

  useEffect(() => {
    if (comunaIdAux == (null || undefined)) {
      setComunaIdAux(dataComuna?.getComunas[0]?.comuna_id);
      setComunaAux(dataComuna?.getComunas[0]?.comuna);
    }
    // eslint-disable-next-line
  }, [dataComuna]);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        await getDataComuna();
      }
      if (dataComuna?.getComunas !== undefined) {
        if (!aux) {
          setOptions([...dataComuna.getComunas]);
        } else {
          setOptions([]);
          setAux(false);
        }
      }
    })();

    return () => {
      active = true;
    };
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setRegion(dataComunaId?.getComunaProvinciaRegion?.region);
    setProvincia(dataComunaId?.getComunaProvinciaRegion?.provincia);
    // eslint-disable-next-line
  }, [dataComunaId]);

  if (loadingDetalle)
    return (
      <>
        <h3 className="tituloPag">Empresas y convenios</h3>
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <Breadcrumbs
            sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
            aria-label="breadcrumb"
            className="breadcrum"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="always"
              color="inherit"
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "400",
                margin: "0px,8px",
              }}
            >
              Inicio
            </Link>
            <Link
              underline="always"
              color="inherit"
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "400",
                margin: "0px,8px",
              }}
              onClick={() => {
                history.goBack();
              }}
              data-testid="buttonTobackPage"
            >
              Empresas y convenios
            </Link>
            <Typography
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "-1px",
                lineHeight: "24px",
              }}
            >
              Edición de Empresas
            </Typography>
          </Breadcrumbs>
        </Grid>
        <SkeletonForm></SkeletonForm>
      </>
    );

  return (
    <>
      <h3 className="tituloPag">Empresas y convenios</h3>
      <Grid
        sx={{ padding: "5px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Link
            underline="always"
            color="inherit"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
            onClick={() => {
              history.goBack();
            }}
            data-testid="buttonTobackPage"
          >
            Empresas y convenios
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Edición de Empresas
          </Typography>
        </Breadcrumbs>
      </Grid>
      <div className="card-box">
        <Grid item xs={12}>
          <label className="tituloSecPag2">Información de empresa</label>
        </Grid>
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter"
        >
          <Grid item xs={12}>
            <p className="labelFormTitle">Identificación</p>
          </Grid>
          <Grid item xs={6}>
            <p className="labelForm">Razón social</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <ThemeTextField
                disabled
                fullWidth
                placeholder="Cencosud"
                value={dataEmpresa?.getEmpleador?.razonSocial}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <p className="labelForm">RUT</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <ThemeTextField
                disabled
                fullWidth
                placeholder="Ej: 12345678-9"
                value={
                  dataEmpresa?.getEmpleador?.rut +
                  "-" +
                  dataEmpresa?.getEmpleador?.dv
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter2"
        >
          <Grid item xs={12}>
            <p className="labelFormTitle">Convenios</p>
          </Grid>
          <Grid item xs={6}>
            <p className="labelForm">Operador</p>
            <FormControl sx={{ width: "98%" }}>
              <Select
                sx={{ width: "100%" }}
                input={<CustomInputSelect />}
                MenuProps={menuProps}
                value={operador || dataEmpresa?.getEmpleador?.operador || "0"}
                onChange={handleOperadorChange}
                inputProps={{ "data-testid": "selectOperador" }}
              >
                <MenuItem value="1" sx={menuItemStyle}>
                  Previred
                </MenuItem>
                <MenuItem value="2" sx={menuItemStyle}>
                  X-Data
                </MenuItem>
                <MenuItem value="3" sx={menuItemStyle}>
                  Manual
                </MenuItem>
                <MenuItem value="0" sx={menuItemStyle}>
                  Sin asignar
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <p className="labelForm">Comuna</p>
            <FormControl sx={{ width: "98%" }}>
              <Autocomplete
                blurOnSelect
                freeSolo
                onBlur={handleBlur}
                onChange={(event, option, reason) =>
                  handleOnChange(option, reason)
                }
                value={comuna || null}
                inputValue={inputValue || ""}
                onInputChange={(event, newValue, reason) =>
                  handleOnInputChange(newValue, reason)
                }
                options={options}
                loading={loading}
                loadingText={"Cargando..."}
                open={open}
                clearText="Borrar"
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) =>
                  option.comuna
                    ? option.comuna
                    : comuna !== undefined
                    ? comuna
                    : ""
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.comuna_id}
                    value={option.comuna_id}
                  >
                    {option.comuna}
                  </Box>
                )}
                renderInput={(params) => (
                  <ThemeTextField
                    {...params}
                    label=""
                    value={comuna}
                    error={errorComuna}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <p className="labelForm">Ciudad</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <ThemeTextField
                disabled
                fullWidth
                placeholder="Ej: Santiago"
                value={provincia || dataEmpresa?.getEmpleador?.ciudad || ""}
              ></ThemeTextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <p className="labelForm">Región</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <ThemeTextField
                disabled
                fullWidth
                placeholder="Ej: Metropolitana"
                value={region || dataEmpresa?.getEmpleador?.region || ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <form onSubmit={formik.handleSubmit} onBlur={formik.handleSubmit}>
              <p className="labelForm">Calle</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  id="calle"
                  name="calle"
                  fullWidth
                  placeholder="Ej: Avenida Costanera"
                  error={formik.touched.calle && Boolean(formik.errors.calle)}
                  helperText={formik.touched.calle && formik.errors.calle}
                  onChange={(e) => {
                    setCalle(e.target.value);
                    setIsDisabled(false);
                    formik.handleChange(e);
                  }}
                  value={calle || ""}
                  inputProps={{ "data-testid": "inputCalle" }}
                />
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={3}>
            <form onSubmit={formik.handleSubmit} onBlur={formik.handleSubmit}>
              <p className="labelForm">Número</p>
              <FormControl sx={{ width: "96%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  id="numero"
                  name="numero"
                  fullWidth
                  placeholder="Ej: 2345"
                  error={formik.touched.numero && Boolean(formik.errors.numero)}
                  helperText={formik.touched.numero && formik.errors.numero}
                  value={numero || ""}
                  onChange={(e) => {
                    setNumero(e.target.value);
                    setIsDisabled(false);
                    formik.handleChange(e);
                  }}
                  inputProps={{ "data-testid": "inputNumero" }}
                />
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={3}>
            <form onSubmit={formik.handleSubmit} onBlur={formik.handleSubmit}>
              <p className="labelForm">Departamento</p>
              <FormControl sx={{ width: "96%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  id="depto"
                  name="depto"
                  fullWidth
                  placeholder="Ej: 404"
                  error={formik.touched.depto && Boolean(formik.errors.depto)}
                  helperText={formik.touched.depto && formik.errors.depto}
                  value={depto || ""}
                  onChange={(e) => {
                    setDepto(e.target.value);
                    setIsDisabled(false);
                    formik.handleChange(e);
                  }}
                  inputProps={{ "data-testid": "inputDepto" }}
                />
              </FormControl>
            </form>
          </Grid>
        </Grid>

        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter"
        >
          <Grid item xs={12}>
            <p className="labelFormTitle">Contacto</p>
          </Grid>
          <Grid item xs={6}>
            <form onSubmit={formik.handleSubmit} onBlur={formik.handleSubmit}>
              <p className="labelForm">Correo electrónico</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  id="email"
                  name="email"
                  fullWidth
                  placeholder="Ej: hola@gmail.com"
                  value={correo || ""}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={(e) => {
                    setCorreo(e.target.value);
                    setIsDisabled(false);
                    formik.handleChange(e);
                  }}
                  inputProps={{ "data-testid": "inputEmail" }}
                />
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={6}>
            <form onSubmit={formik.handleSubmit} onBlur={formik.handleSubmit}>
              <p className="labelForm">Teléfono</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  id="telefono"
                  name="telefono"
                  fullWidth
                  placeholder="Ej: 123 456 789"
                  error={
                    formik.touched.telefono && Boolean(formik.errors.telefono)
                  }
                  helperText={formik.touched.telefono && formik.errors.telefono}
                  value={telefono || ""}
                  onChange={(e) => {
                    setTelefono(e.target.value);
                    setIsDisabled(false);
                    formik.handleChange(e);
                  }}
                  inputProps={{ "data-testid": "inputTelefono" }}
                />
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <SecondaryButton
              sx={{ margin: "10px 16px 0 0" }}
              onClick={resetFields}
              disabled={isDisabled || hasErrors}
              data-testid="btnReset"
            >
              Restablecer
            </SecondaryButton>
            <PrimaryButton
              sx={{ margin: "10px 16px 0 0" }}
              onClick={updateEmpresa}
              disabled={isDisabled || hasErrors || errorComuna}
              data-testid="btnSave"
            >
              Guardar
            </PrimaryButton>
          </Grid>
        </Grid>
      </div>
      {dataUpdate?.updateEmpleador?.code && (
        <ToastMaterial
          message={dataUpdate?.updateEmpleador?.message}
          showToast={showToast}
          className={
            dataUpdate?.updateEmpleador?.code === "01" ? "success" : "error"
          }
          setShowToast={setShowToast}
        />
      )}
    </>
  );
};

export default EditarEmpresas;
