/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState, useRef } from "react";
import DataContext from "./Context/ContextSearch";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "./Historial/HistoricoFunes.scss";
import "./Material/material.scss";
import FormControl from "@mui/material/FormControl";
import {} from "./select/CustomSelect";
import PrimaryButton from "./Botones";
import { SecondaryButton } from "./Botones";
import ThemeTextField from "./textField";
import { Autocomplete, Grid, MenuItem, Select } from "@mui/material";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  CustomInputSelect,
  menuItemStyle,
  menuProps,
} from "./select/styledSelect";
import { BuscarIcon, CerrarIcon } from "./.././Images/iconosEsencial";
import { useFormik } from "formik";
import {
  checkRut,
  prettifyRut,
  formatRut,
  removeSeparators,
} from "react-rut-formatter";
import * as yup from "yup";

registerLocale("es", es);

const validationSchema = yup.object({
  rutAfiliado: yup.string().nullable(),
  rutEmpresa: yup.string().nullable(),
});

const tipoNotif = [
  { id: 0, text: "Sin movimientos en el mes informado" },
  { id: 1, text: "Suscripción del Contrato" },
  { id: 2, text: "Término de Contrato" },
  { id: 3, text: "Situaciones especiales" },
  { id: 4, text: "Cambio de Empleador" },
  { id: 5, text: "Cambio de Anualidad" },
  { id: 6, text: "Variación de precio por cambio de tramo de edad" },
  { id: 7, text: "Modificación de Carga" },
  { id: 8, text: "Modificación de Cotización Pactada" },
  { id: 9, text: "Cambio de plan" },
];

const SearchFunes = () => {
  const { dataFiltro, setDataFiltro } = useContext(DataContext);
  const [show, setShow] = useState(false);
  const [folioFun, setFolioFun] = useState(null);
  const [operador, setOperador] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [rutAfiliado, setRutAfiliado] = useState(null);
  const [rutEmpresa, setRutEmpresa] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [cleanDisabled, setCleanDisabled] = useState(true);
  const [validRutA, setValidRutA] = useState(false);
  const [validRutE, setValidRutE] = useState(false);
  const [emptyFields, setEmptyFields] = useState(true);
  const [tipoNot, setTipoNot] = useState<any>([]);
  const autoC = useRef(null);

  const formik = useFormik({
    initialValues: {
      rutAfiliado: rutAfiliado,
      rutEmpresa: rutEmpresa,
    },
    validationSchema: validationSchema,
    validate(values) {
      const errors: { rutAfiliado?: string; rutEmpresa?: string } = {};
      if (values.rutAfiliado && !checkRut(values.rutAfiliado)) {
        errors.rutAfiliado = "RUT inválido";
      } else if (
        checkRut(values.rutAfiliado) &&
        !rutLength(values.rutAfiliado)
      ) {
        errors.rutAfiliado = "RUT inválido";
      }
      if (values.rutEmpresa && !checkRut(values.rutEmpresa)) {
        errors.rutEmpresa = "RUT inválido";
      } else if (checkRut(values.rutEmpresa) && !rutLength(values.rutEmpresa)) {
        errors.rutEmpresa = "RUT inválido";
      }
      return errors;
    },
    onSubmit: (values) => {
      formatRut(values.rutAfiliado);
      formatRut(values.rutEmpresa);
    },
  });

  const handleTipoNot = (newValue) => {
    if (newValue !== null) {
      let idTipo = newValue
        .map((a) => a.id)
        .sort((a, b) => a - b)
        .join();
      setTipoNot(idTipo);
    }
  };

  const clearTipoNot = () => {
    let clearTipo = autoC.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (clearTipo !== undefined) {
      clearTipo.click();
    }
  };

  const handleRutA = (rutA) => {
    if (rutAfiliado === "" || rutAfiliado === null) {
      setValidRutA(false);
    }
    setRutAfiliado(rutA);
  };

  const handleRutE = (rutE) => {
    if (rutEmpresa === "" || rutEmpresa === null) {
      setValidRutE(false);
    }
    setRutEmpresa(rutE);
  };

  const rutLength = (val: string) => {
    if (
      removeSeparators(val).length >= 8 &&
      removeSeparators(val).length <= 10
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filtroFunes = () => {
    let filtro = {
      folioFun: folioFun,
      operador: operador,
      tipo: tipoNot.length > 0 ? tipoNot : null,
      fecha:
        startDate !== null
          ? startDate.toISOString().slice(0, 10).split("-").join("-")
          : null,
      rutAfiliado: rutAfiliado,
      rutEmpresa: rutEmpresa,
    };
    setDataFiltro({ ...dataFiltro, filtro });
  };

  const limpiar = () => {
    setFolioFun(null);
    setOperador(null);
    setTipoNot("");
    setStartDate(null);
    setRutAfiliado(null);
    setRutEmpresa(null);
    let filtro = {
      folioFun: null,
      operador: null,
      tipo: null,
      fecha: null,
      rutAfiliado: null,
      rutEmpresa: null,
    };
    setDataFiltro({ ...dataFiltro, filtro });
    formik.setTouched({}, false);
    formik.values.rutAfiliado = "";
    formik.values.rutEmpresa = "";
    clearTipoNot();
  };

  useEffect(() => {
    let errorValidation = [];
    if (Boolean(formik.errors.rutAfiliado)) {
      errorValidation.push(formik.errors.rutAfiliado);
    }
    if (Boolean(formik.errors.rutEmpresa)) {
      errorValidation.push(formik.errors.rutEmpresa);
    }
    // eslint-disable-next-line
  }, [formik.errors]);

  useEffect(() => {
    if (rutAfiliado !== "" && rutAfiliado !== null) {
      if (checkRut(rutAfiliado) && rutLength(rutAfiliado)) {
        setValidRutA(true);
      } else {
        setValidRutA(false);
      }
    } else {
      setValidRutA(true);
    }
    if (rutEmpresa !== "" && rutEmpresa !== null) {
      if (checkRut(rutEmpresa) && rutLength(rutEmpresa)) {
        setValidRutE(true);
      } else {
        setValidRutE(false);
      }
    } else {
      setValidRutE(true);
    }
    // eslint-disable-next-line
  }, [rutAfiliado, rutEmpresa]);

  useEffect(() => {
    if (validRutA && validRutE) {
      if (rutAfiliado || rutEmpresa) {
        setIsDisabled(false);
      } else if (!emptyFields) {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [validRutA, validRutE]);

  useEffect(() => {
    if (
      !folioFun &&
      !operador &&
      !startDate &&
      !rutAfiliado &&
      !rutEmpresa &&
      tipoNot.length === 0
    ) {
      setCleanDisabled(true);
    } else {
      setCleanDisabled(false);
    }
    if (folioFun || operador || startDate || tipoNot.length !== 0) {
      setEmptyFields(false);
      if (
        (rutAfiliado === null || rutAfiliado === "" || checkRut(rutAfiliado)) &&
        (rutEmpresa === null || rutEmpresa === "" || checkRut(rutEmpresa))
      )
        setIsDisabled(false);
    } else if (!checkRut(rutAfiliado) && !checkRut(rutEmpresa)) {
      setIsDisabled(true);
    }
    if (!folioFun && !operador && !startDate && tipoNot.length === 0) {
      setEmptyFields(true);
    }
    // eslint-disable-next-line
  }, [folioFun, operador, startDate, rutAfiliado, rutEmpresa, tipoNot]);

  return (
    <>
      <h3 className="tituloPag">Gestión de Funes</h3>
      <Grid
        sx={{ padding: "5px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Gestión de funes
          </Typography>
        </Breadcrumbs>
      </Grid>
      <div className="card-box">
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
          data-testid="selectFilter"
        >
          <Grid item xs={12}>
            <p className="tituloSecPag1">Filtros de búsqueda</p>
          </Grid>
          <Grid item xs={4}>
            <p className="labelForm">Folio</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <ThemeTextField
                fullWidth
                placeholder="Ej: 000001001"
                value={folioFun === null ? "" : folioFun}
                onChange={(e) =>
                  setFolioFun((v) =>
                    e.target.validity.valid ? e.target.value : v
                  )
                }
                inputProps={{
                  maxLength: 9,
                  pattern: "[0-9]*",
                }}
                data-testid="FolioFun"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p className="labelForm">Operador</p>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <Select
                sx={{ width: "100%" }}
                input={<CustomInputSelect />}
                MenuProps={menuProps}
                value={operador === null ? 10 : operador}
                onChange={(e) => setOperador(e.target.value)}
                inputProps={{ "data-testid": "Operador" }}
              >
                <MenuItem value="10" sx={menuItemStyle} hidden>
                  Selecciona
                </MenuItem>
                <MenuItem value="0" sx={menuItemStyle}>
                  Sin asignar
                </MenuItem>
                <MenuItem value="1" sx={menuItemStyle}>
                  Previred
                </MenuItem>
                <MenuItem value="2" sx={menuItemStyle}>
                  X-Data
                </MenuItem>
                <MenuItem value="3" sx={menuItemStyle}>
                  Manual
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p className="labelForm">Tipo de notificación</p>
            <FormControl
              sx={{
                width: "96%!important",
                float: "right",
                margin: "0 15px 0 0",
              }}
            >
              <Autocomplete
                ref={autoC}
                value={tipoNot.id}
                onChange={(event, newValue) => {
                  handleTipoNot(newValue);
                }}
                multiple
                limitTags={3}
                clearText="Borrar"
                noOptionsText="No hay opciones."
                options={tipoNotif}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.id.toString()}
                renderOption={(props, option) => (
                  <li {...props}>{option.id + " - " + option.text}</li>
                )}
                filterSelectedOptions
                renderInput={(params) => (
                  <ThemeTextField
                    {...params}
                    label=""
                    placeholder="Selecciona"
                  />
                )}
                data-testid="autocomplete"
              />
            </FormControl>
          </Grid>
          {show === true && (
            <>
              <Grid item xs={4}>
                <p className="labelForm">Fecha</p>
                <FormControl
                  sx={{ width: "100%!important", margin: "0 0% 0 0" }}
                >
                  <DatePicker
                    name="buscarFecha"
                    locale="es"
                    placeholderText="DD/MM/AAAA"
                    dateFormat="dd/MM/yyyy"
                    className="calendario-remesa"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  ></DatePicker>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formatted = prettifyRut(rutAfiliado);
                    setRutAfiliado(formatted);
                    formik.handleSubmit();
                  }}
                  onBlur={() => {
                    const formatted = prettifyRut(rutAfiliado);
                    setRutAfiliado(formatted);
                    formik.handleSubmit();
                  }}
                >
                  <p className="labelForm">Rut Afiliado</p>
                  <FormControl
                    sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                  >
                    <ThemeTextField
                      id="rutAfiliado"
                      name="rutAfiliado"
                      fullWidth
                      placeholder="Ej: 12345678-9"
                      error={
                        formik.touched.rutAfiliado &&
                        Boolean(formik.errors.rutAfiliado)
                      }
                      helperText={
                        formik.touched.rutAfiliado && formik.errors.rutAfiliado
                      }
                      value={rutAfiliado || ""}
                      onChange={(e) => {
                        handleRutA(e.target.value);
                        formik.handleChange(e);
                      }}
                      data-testid="RutAfiliado"
                    />
                  </FormControl>
                </form>
              </Grid>
              <Grid item xs={4}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formatted = prettifyRut(rutEmpresa);
                    setRutEmpresa(formatted);
                    formik.handleSubmit();
                  }}
                  onBlur={() => {
                    const formatted = prettifyRut(rutEmpresa);
                    setRutEmpresa(formatted);
                    formik.handleSubmit();
                  }}
                >
                  <p className="labelForm">Rut Empresa</p>
                  <FormControl
                    sx={{
                      width: "96%!important",
                      float: "right",
                      margin: "0 15px 0 0",
                    }}
                  >
                    <ThemeTextField
                      id="rutEmpresa"
                      name="rutEmpresa"
                      fullWidth
                      placeholder="Ej: 12345678-9"
                      error={
                        formik.touched.rutEmpresa &&
                        Boolean(formik.errors.rutEmpresa)
                      }
                      helperText={
                        formik.touched.rutEmpresa && formik.errors.rutEmpresa
                      }
                      value={rutEmpresa || ""}
                      onChange={(e) => {
                        handleRutE(e.target.value);
                        formik.handleChange(e);
                      }}
                      data-testid="RutEmpresa"
                    />
                  </FormControl>
                </form>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ width: "49%", float: "left" }}
            >
              {show ? (
                <SecondaryButton
                  sx={{ width: "auto", marginRight: "4px" }}
                  onClick={() => setShow(false)}
                  data-testid="lessFilters"
                >
                  <CerrarIcon />
                  Cerrar Búsqueda avanzada
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  sx={{ width: "206px", marginRight: "4px" }}
                  onClick={() => setShow(true)}
                  data-testid="moreFilters"
                >
                  <BuscarIcon />
                  Ver más filtros
                </SecondaryButton>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              sx={{ width: "49%", float: "right", padding: "0px 12px 0" }}
            >
              <SecondaryButton
                sx={{ marginRight: "15px" }}
                onClick={() => limpiar()}
                data-testid="cleanFilters"
                disabled={cleanDisabled}
              >
                Limpiar
              </SecondaryButton>
              <PrimaryButton
                sx={{ marginRight: "4px" }}
                onClick={() => filtroFunes()}
                data-testid="btnSearch"
                disabled={isDisabled}
              >
                Buscar
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SearchFunes;
