import { FC, useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Modal } from "react-bootstrap";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import { UPDATE_ESTADO_REMESA } from "../../graphql/mutations";
import "./Remesa.scss";
import ToastMaterial from "../toast/ToastMaterial";
type CanalProps = {
  estadoRemesa: string;
  id: number;
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EstadoDropdown: FC<CanalProps> = ({
  id,
  estadoRemesa,
  showToast,
  setShowToast,
}) => {
  const [estado, setEstado] = useState(estadoRemesa);
  const { estadoRemesaContext, setEstadoRemesaContext } =
    useContext(RemesaContext);
  const [updateRemesa, { data }] = useMutation(UPDATE_ESTADO_REMESA);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEstado = (e) => {
    if (e.target.value === "Por Enviar") {
      handleShow();
    } else if (e.target.value === "Cerrada") {
      setEstado(e.target.value);
      updateRemesa({
        variables: {
          estado: "Cerrada",
          idRemesa: id,
          fecha: null,
        },
      });
      setShowToast(true);
      setEstadoRemesaContext("3");
    }
  };

  function handleChange(e) {
    setEstado(e.target.value);
    updateRemesa({
      variables: {
        estado: estado,
        idRemesa: id,
        fecha: null,
      },
    });
    setShowToast(true);
    handleClose();
    setTimeout(() => {
      setEstadoRemesaContext("1");
    }, 3000);
  }

  useEffect(() => {
    // eslint-disable-next-line
  }, [estado]);

  useEffect(() => {
    setEstado(estadoRemesa);
    // eslint-disable-next-line
  }, [estadoRemesa]);

  useEffect(() => {
    setEstadoRemesaContext("0");
    // eslint-disable-next-line
  }, [estadoRemesaContext]);

  return (
    <>
      <Form.Select
        onChange={handleEstado}
        value={estado}
        className="input-estado"
        data-testid="dropdownChangeEstado"
      >
        <option defaultValue="Enviada" disabled>
          Enviada
        </option>
        <option
          value="Por Enviar"
          disabled={estado === "Cerrada" || estado === "En Proceso"}
        >
          Por Enviar
        </option>
        <option
          value="En Proceso"
          disabled={estado === "Enviada" || estado === "Cerrada"}
        >
          En Proceso
        </option>
        <option value="Cerrada">Cerrada</option>
      </Form.Select>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        data-testid="modalConfirmation"
        className="modal-confirmacion"
      >
        <Modal.Body>
          <p>
            Al seleccionar el estado <span>"Por Enviar"</span>, la fecha de
            envío quedará en blanco
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancelar" onClick={handleClose}>
            Cancelar
          </Button>
          <Button className="btn-primario" onClick={handleChange}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      {data?.updateRemesa?.code && (
        <ToastMaterial
          message={data?.updateRemesa?.message}
          showToast={showToast}
          className={data?.updateRemesa?.code === "01" ? "success" : "error"}
          setShowToast={setShowToast}
          data-testid="toastUpdateEstado"
        />
      )}
    </>
  );
};

export default EstadoDropdown;
