import React, { useContext, useEffect, useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { Spinner } from "react-bootstrap";
import "../../styles/content.scss";
import "./Remesa.scss";
import RemesaContext from "./../Context/ContextDetalleRemesa";
import "../Material/material.scss";
import FormControl from "@mui/material/FormControl";
import PrimaryButton from "../Botones";
import { Grid, MenuItem, Select } from "@mui/material";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ExportToExcel, { ExportToExcelManual } from "../../utils/ExportToExcel";
import ExportToXml from "../../utils/ExportToXml";
import moment from "moment";
import { CREATE_REMESA } from "../../graphql/mutations";
import {
  DOWNLOAD_REMESA_XDATA,
  DOWNLOAD_REMESA_MANUAL,
  DOWNLOAD_REMESA_PREVIRED,
} from "../../graphql/queries";
import {
  CustomInputSelect,
  menuItemStyle,
  menuProps,
} from "../select/styledSelect";
import ToastMaterial from "../toast/ToastMaterial";

const HeaderRemesa = () => {
  const client = useApolloClient();
  const [canal, setCanal] = useState(undefined);
  const [estado, setEstado] = useState("Por Enviar");
  const [region, setRegion] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [errorRemesa, setErrorRemesa] = useState(false);
  const { setRemesa } = useContext(RemesaContext);
  const [addRemesa, { data, loading }] = useMutation(CREATE_REMESA, {
    onError: () => {
      setErrorRemesa(true);
    },
    errorPolicy: "all",
  });
  const today = new Date();

  function handleCanalChange(e) {
    if (e.target.value !== "3" && region !== 0) {
      setRegion(0);
    } else if (e.target.value === "3" && region === 0) {
      setRegion(-1);
    }
    setCanal(parseInt(e.target.value));
  }

  function handleEstadoChange(e) {
    setEstado(e.target.value);
  }

  const handleRegionChange = (e) => {
    setRegion(parseInt(e.target.value));
  };

  async function handleClick(e) {
    if (region !== -1) {
      const { data: dataAdd } = await addRemesa({
        variables: {
          canal: canal,
          estado: estado,
          region: region,
        },
      });
      if (dataAdd.insertRemesa.code === "01") {
        if (canal === 1) {
          let remesa: number = dataAdd.insertRemesa.remesa;
          let fechaRemesa = moment(today).format("yyyyMM");
          let nombreXml = "CFI0108" + fechaRemesa + "001";
          functionXml(remesa, nombreXml);
        }
        if (canal === 2) {
          let remesa: number = dataAdd.insertRemesa.remesa;
          let fechaRemesa = moment(today).format("yyyyMMDD");
          let nombreExcel = fechaRemesa + "-XData-" + remesa;
          functionExcel(remesa, nombreExcel);
        }
        if (canal === 3) {
          let folioRemesa: number = dataAdd.insertRemesa.remesa;
          let fechaRemesa = moment(today).format("yyyyMMDD");
          let nombreExcel = fechaRemesa + "-Manual-" + folioRemesa;
          functionExcelManual(folioRemesa, nombreExcel);
        }
        setRemesa(true);
      }
    }
  }

  const functionExcel = async (idRemesa: number, nombre: string) => {
    const { data: dataXData } = await client.query({
      query: DOWNLOAD_REMESA_XDATA,
      variables: {
        idRemesa: idRemesa,
      },
    });
    const excelData = dataXData;
    ExportToExcel(excelData, nombre);
  };

  const functionExcelManual = async (idRemesa: number, nombre: string) => {
    const { data: dataManual } = await client.query({
      query: DOWNLOAD_REMESA_MANUAL,
      variables: {
        idRemesa: idRemesa,
      },
    });
    const excelData = dataManual;
    ExportToExcelManual(excelData, nombre);
  };

  const functionXml = async (idRemesa: number, nombre: string) => {
    const { data: dataXml } = await client.query({
      query: DOWNLOAD_REMESA_PREVIRED,
      variables: {
        idRemesa: idRemesa,
      },
    });
    const previredData = dataXml;
    ExportToXml(previredData, nombre);
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.insertRemesa.code !== undefined) {
      setShowToast(true);
    }
    // eslint-disable-next-line
  }, [data?.insertRemesa?.code]);

  useEffect(() => {
    if (errorRemesa) {
      setShowToast(true);
      setTimeout(() => {
        setErrorRemesa(false);
      }, 4000);
    }
    // eslint-disable-next-line
  }, [errorRemesa]);

  return (
    <div>
      <Grid container spacing={0} item xs={12}>
        <h3 className="tituloPag">Remesas</h3>

        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <Breadcrumbs
            sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
            aria-label="breadcrumb"
            className="breadcrum"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="always"
              color="inherit"
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "400",
                margin: "0px,8px",
              }}
            >
              Inicio
            </Link>
            <Typography
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "-1px",
                lineHeight: "24px",
              }}
            >
              Generación de Remesas
            </Typography>
          </Breadcrumbs>
        </Grid>
        <div className="card-box">
          <Grid
            sx={{ padding: "5px", margin: "0", width: "100%" }}
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <label className="tituloSecPag">Generación de remesa</label>
              <p className="labelForm">
                Para crear la remesa, selecciona a continuación el canal y
                estado de los funes a agrupar.
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className="labelForm">Canal</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <Select
                  sx={{ width: "100%" }}
                  input={<CustomInputSelect />}
                  MenuProps={menuProps}
                  value={canal ? canal : "0"}
                  onChange={handleCanalChange}
                  inputProps={{ "data-testid": "selectCanal" }}
                >
                  <MenuItem value="0" sx={menuItemStyle} hidden>
                    Seleccionar canal
                  </MenuItem>
                  <MenuItem value="1" sx={menuItemStyle}>
                    Previred
                  </MenuItem>
                  <MenuItem value="2" sx={menuItemStyle}>
                    X-Data
                  </MenuItem>
                  <MenuItem value="3" sx={menuItemStyle}>
                    Manual
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <p className="labelForm">Estado</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <Select
                  sx={{ width: "100%" }}
                  input={<CustomInputSelect />}
                  MenuProps={menuProps}
                  value={estado}
                  onChange={handleEstadoChange}
                  inputProps={{ "data-testid": "selectEstado" }}
                >
                  <MenuItem value="Por Enviar" sx={menuItemStyle}>
                    Por Enviar
                  </MenuItem>
                  <MenuItem value="Rechazado" sx={menuItemStyle} disabled>
                    Rechazado
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} hidden={canal !== 3}>
              <p className="labelForm">Región</p>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <Select
                  sx={{ width: "100%" }}
                  input={<CustomInputSelect />}
                  MenuProps={menuProps}
                  value={region}
                  onChange={handleRegionChange}
                  inputProps={{ "data-testid": "selectRegion" }}
                >
                  <MenuItem value="-1" sx={menuItemStyle} hidden>
                    Selecciona
                  </MenuItem>
                  <MenuItem value="0" sx={menuItemStyle} hidden>
                    Selecciona
                  </MenuItem>
                  <MenuItem value="13" sx={menuItemStyle}>
                    Metropolitana
                  </MenuItem>
                  <MenuItem value="14" sx={menuItemStyle}>
                    Otras regiones
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={{ marginTop: "34px" }}>
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                {loading === true ? (
                  <PrimaryButton disabled data-testid="btnSubmitHeaderRemesa">
                    <Spinner
                      as="span"
                      size="sm"
                      animation="border"
                      data-testid="loaderSpinner"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    sx={{ marginRight: "4px" }}
                    onClick={handleClick}
                    disabled={!canal || region == -1}
                    data-testid="btnSubmitHeaderRemesa"
                  >
                    Generar remesa
                  </PrimaryButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          {errorRemesa && (
            <ToastMaterial
              message={"No pudimos generar la remesa, vuelve a intentarlo"}
              showToast={showToast}
              className={"error"}
              setShowToast={setShowToast}
            />
          )}
          {data?.insertRemesa?.code && (
            <ToastMaterial
              message={data?.insertRemesa?.message}
              showToast={showToast}
              className={
                data?.insertRemesa?.code === "01" ? "success" : "error"
              }
              setShowToast={setShowToast}
              data-testid="toastUpdateEstado"
            />
          )}
        </div>
      </Grid>
    </div>
  );
};

export default HeaderRemesa;
