/* istanbul ignore file */
import * as React from "react";
import { useEffect, useState } from "react";
import { callMsGraph } from "../auth/graph";
import { loginRequest } from "../auth/authConfig";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import "./header2.css";
import { Grid, Stack } from "@mui/material";
import { AppBar } from "@mui/material";
import Logo from "../../Images/logotipo.svg";
import icon_avatar from "../../Images/icon_avatar.svg";
import icon_notify from "../../Images/icon_notify.svg";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const { REACT_APP_PERFIL_ESENCIAL } = process.env;

const AppBarAlemana = () => {
  const { instance, accounts } = useMsal();
  const [, setGraphData] = useState(null);

  const isAuthenticated = useIsAuthenticated();
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "./";
  };
  async function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    const new_roles = accounts[0];
    if (accounts.length > 0) {
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((response) =>
            setGraphData(response)
          );
         

          if (new_roles) {
            if(new_roles.idTokenClaims.hasOwnProperty('roles')){
              if (new_roles?.idTokenClaims["roles"][0] !== REACT_APP_PERFIL_ESENCIAL) {
                handleLogout();
              }
            }else{
              handleLogout();
            }
          }
        });
    }
  }

  const handleLogin = (loginType) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });                                                              
  };

  useEffect(() => {
    accounts.length && RequestProfileData();      
    // eslint-disable-next-line
  }, [accounts]);

  return (
    <div>
      <Box mb="0px" sx={{ boxShadow: "none", flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ boxShadow: "none", bgcolor: "transparent" }}
        >
          <Toolbar className="toolbar" sx={{ boxShadow: "none" }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={Logo} alt="" className="logo" />
            </Typography>
            <Stack direction="row" spacing={3}>
              {isAuthenticated && (
                <img src={icon_avatar} alt="" className="icon_avatar" />
              )}
              {isAuthenticated && (
                <Grid>
                  <Typography color="secondary" className="name">
                    {accounts[0] && accounts[0].name}
                  </Typography>
                  <Typography color="secondary" className="job">
                    Ejecutivo Esencial
                  </Typography>
                </Grid>
              )}
              <Box width="1px">
                <Divider orientation="vertical" />
              </Box>
              <img src={icon_notify} alt="" className="icon_notify" />
              <Box width="1px">
                <Divider orientation="vertical" />
              </Box>
              {isAuthenticated && (
                <Typography
                  color="secondary"
                  className="cerrarsesion"
                  onClick={() => handleLogout()}
                >
                  Cerrar sesión{" "}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.16541 6.66663H12.0387C12.3824 6.66663 12.6636 6.94781 12.6636 7.29147V7.70804C12.6636 8.0517 12.3824 8.33289 12.0387 8.33289H5.16541C4.70718 8.33289 4.33228 8.70779 4.33228 9.16602V24.1624C4.33228 24.6206 4.70718 24.9955 5.16541 24.9955H12.0387C12.3824 24.9955 12.6636 25.2767 12.6636 25.6203V26.0369C12.6636 26.3806 12.3824 26.6617 12.0387 26.6617H5.16541C3.78553 26.6617 2.66602 25.5422 2.66602 24.1624V9.16602C2.66602 7.78614 3.78553 6.66663 5.16541 6.66663ZM19.6931 7.682L19.3234 8.0517C19.0787 8.29644 19.0787 8.69217 19.3234 8.93691L26.1967 15.779H11.6222C11.2785 15.779 10.9973 16.0602 10.9973 16.4038V16.9245C10.9973 17.2682 11.2785 17.5494 11.6222 17.5494H26.1967L19.3286 24.3915C19.0839 24.6362 19.0839 25.0319 19.3286 25.2767L19.6983 25.6464C19.943 25.8911 20.3388 25.8911 20.5835 25.6464L29.1491 17.1068C29.3939 16.8621 29.3939 16.4663 29.1491 16.2216L20.5783 7.682C20.3336 7.43727 19.9378 7.43727 19.6931 7.682Z"
                      fill=""
                    />
                  </svg>
                </Typography>
              )}
              {!isAuthenticated && (
                <Typography
                  color="secondary"
                  className="iniciarsesion"
                  onClick={() => handleLogin("popup")}
                >
                  Iniciar sesión
                </Typography>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};
export default AppBarAlemana;
