import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const TableTooltip = ({ rut, tooltip }) => {
  return (
    <div>
      <OverlayTrigger
        overlay={(props) => <Tooltip {...props}>{tooltip}</Tooltip>}
        placement="bottom"
      >
        {(props) => <span {...props}>{rut}</span>}
      </OverlayTrigger>
    </div>
  );
};

export default TableTooltip;
