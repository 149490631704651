import React, { useState } from "react";

const Context = React.createContext<{
  dataFiltro?: any;
  setDataFiltro?: React.Dispatch<any>;
  uploadComplete?: any;
  setUploadComplete?: React.Dispatch<any>;
}>({});

export function DataContextProvider({ children }) {
  const [dataFiltro, setDataFiltro] = useState([]);
  const [uploadComplete, setUploadComplete] = useState([false]);
  return (
    <Context.Provider
      value={{ dataFiltro, setDataFiltro, uploadComplete, setUploadComplete }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
